import { useInterval } from '@mantine/hooks'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { times } from 'lodash-es'
import { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { mod } from 'react-swipeable-views-core'
import { bindKeyboard, virtualize } from 'react-swipeable-views-utils'
import { randomInteger } from '@/core/utils'

const EnhancedSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

export const SwipeableContent = <T,>({
  content,
  render,
  className,
  dotPosition = -25
}: {
  content: T[]
  render: (it: T, idx: number) => React.ReactNode
  className?: string
  dotPosition?: number
}) => {
  const [tab, setTab] = useState(0)

  const slideRenderer = ({ key, index }: { key: any; index: number }) => {
    const current = mod(index, content.length)
    return render(content[current], key) as any
  }

  const interval = useInterval(
    () => setTab(t => t + 1),
    randomInteger(5000, 8000)
  )
  useEffect(
    () => {
      interval.start()
      return interval.stop
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /* interval */
    ]
  )

  return (
    <Box position='relative'>
      <EnhancedSwipeableViews
        index={tab}
        slideRenderer={slideRenderer}
        onChangeIndex={setTab}
        onTouchStart={() => interval.stop()}
        className={className}
        style={{ WebkitOverflowScrolling: 'touch' }}
      />
      <Box
        className='swipe-indicator'
        position='absolute'
        right={'50%'}
        sx={{ transform: 'translate(50%,0)' }}
        bottom={dotPosition}
      >
        <Stack direction='row' spacing={3}>
          {times(content.length, idx => (
            <Box
              key={idx}
              onClick={() => {
                setTab(idx)
                interval.stop()
              }}
              sx={{
                cursor: 'pointer',
                borderRadius: 10,
                width: 10,
                height: 10,
                backgroundColor:
                  idx === mod(tab, content.length)
                    ? 'text.primary'
                    : 'text.secondary'
              }}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}
