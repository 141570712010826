import { orderBy } from 'lodash-es'

export const snakeAssignTeamsToGroups = (
  teams: { id?: number; seed?: number }[],
  numGroups: number
) => {
  // Divide the sorted teams into groups
  const groups: { id?: number; seed?: number }[][] = []
  for (let i = 0; i < numGroups; i++) {
    groups.push([])
  }

  let direction = 1
  let currentGroupIndex = 0
  const sorted = orderBy(teams, ['seed'], ['asc'])
  for (let i = 0; i < sorted.length; i++) {
    const team = sorted[i]
    const currentGroup = groups[currentGroupIndex]

    // Assign team to the current group
    currentGroup.push(team)

    // Move to the next group based on direction
    currentGroupIndex += direction

    // Adjust direction and current group index if necessary
    if (currentGroupIndex === numGroups || currentGroupIndex === -1) {
      direction *= -1 // Change direction
      currentGroupIndex += direction // Adjust current group index
    }
  }

  return groups
}

export const getBracketCount = (
  teams: any[] | undefined,
  playerLimit?: number | undefined
): number => {
  if (!teams) return 1
  return Math.max(Math.ceil(teams?.length / (playerLimit || 256)), 1)
}

export const getPlayerLimit = (
  teams: any[] | undefined,
  bracketCount?: number
): number => {
  if (!teams) return 256
  return Math.max(Math.ceil(teams?.length / (bracketCount || 1)), 2)
}
