import {
  CompetitionControllerApi,
  CreateCompetitionRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionControllerApi'
import { CompetitionEntryControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionEntryControllerApi'
import { CompetitionProfileControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionProfileControllerApi'
import { CompetitionSectionControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionSectionControllerApi'
import { CauseResource } from '@rallycry/api-suite-typescript/dist/models/CauseResource'
import { CompetitionProfileResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionProfileResource'
import { CompetitionProfileUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionProfileUpdateCommand'
import { CompetitionSectionCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionSectionCommand'
import { CompetitionSectionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionSectionResource'
import { CompetitionSettingsCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionSettingsCommand'
import { CompetitionSettingsResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionSettingsResource'
import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { CompetitionUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionUpdateCommand'
import { GameResource } from '@rallycry/api-suite-typescript/dist/models/GameResource'
import { OrganizationResource } from '@rallycry/api-suite-typescript/dist/models/OrganizationResource'
import { SignUpMode } from '@rallycry/api-suite-typescript/dist/models/SignUpMode'
import { useMemo } from 'react'
import {
  ChatProvider,
  CompetitionResource
} from '@rallycry/api-suite-typescript'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useUserMeta } from '../user/useUserMeta'
import { useCompetitionMeta } from './useCompetitionMeta'
import { expander } from '@/core/expand'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useCompetition = (options?: EntityOptions) => {
  const parsedId = useParsedParam('competitionId')
  const { read: meta } = useCompetitionMeta(options)
  const { read: userMeta } = useUserMeta()
  const { ctrl: profileCtrl } = useController(CompetitionProfileControllerApi)
  const { ctrl: sectionCtrl } = useController(CompetitionSectionControllerApi)
  const { ctrl } = useController(CompetitionControllerApi)

  const competitionId = Number(options?.idOrKey) || parsedId
  const entity = useReadEntity({
    key: 'useCompetition',
    expand:
      '_links,_restrictToCommunities{additionalOrganizations},organization,profile,section,settings,game,channel',
    ...options,
    idOrKey: competitionId,
    metas: [userMeta, meta],
    read: (req, { root, entries, entrant, access, events }) =>
      ctrl({
        metas: { root, entries, entrant, access, events }
      }).readCompetition(req) as Promise<
        Omit<CompetitionResource, '_issues' | '_viewInvolvement'>
      >,
    create: (req: CreateCompetitionRequest) => ctrl().createCompetition(req),
    update: (id, cmd: CompetitionUpdateCommand, expand) =>
      ctrl().updateCompetition({ id, CompetitionUpdateCommand: cmd, expand }),
    remove: id => ctrl().removeCompetition({ id })
  })

  const competition = entity.read.data

  const updateProfile = async (cmd: CompetitionProfileUpdateCommand) => {
    await profileCtrl().updateCompetitionProfile({
      competitionId,
      CompetitionProfileUpdateCommand: cmd
    })
    await meta.mutate()
    await entity.read.mutate()
  }

  const updateSection = async (cmd: CompetitionSectionCommand) => {
    await sectionCtrl().updateCompetitionSection({
      competitionId,
      CompetitionSectionCommand: cmd
    })
    await meta.mutate()
    await entity.read.mutate()
  }

  const updateSettings = async (cmd: CompetitionSettingsCommand) => {
    await ctrl().updateCompetitionSettings({
      id: competitionId,
      CompetitionSettingsCommand: cmd
    })
    await meta.mutate()
    await entity.read.mutate()
  }

  const { ctrl: entryCtrl } = useController(CompetitionEntryControllerApi)
  const finalizeRanks = async (placements: number[]) => {
    await entity.update(competition?.id!, { state: CompetitionState.CONCLUDED })
    placements.forEach((p, idx) =>
      entryCtrl().updateCompetitionEntry({
        id: p,
        CompetitionEntryUpdateCommand: { rank: idx + 1 }
      })
    )
  }

  const organization = useMemo(
    () => expander<OrganizationResource>(competition, 'organization'),
    [competition]
  )
  const profile = useMemo(
    () =>
      expander<CompetitionProfileResource>(competition, 'competitionProfile'),
    [competition]
  )
  const section = useMemo(
    () =>
      expander<CompetitionSectionResource>(competition, 'competitionSection'),
    [competition]
  )
  const settings = useMemo(
    () =>
      expander<CompetitionSettingsResource>(competition, 'competitionSettings'),
    [competition]
  )
  const game = useMemo(
    () => expander<GameResource>(competition, 'game'),
    [competition]
  )
  const cause = useMemo(
    () =>
      expander<CauseResource>(
        { ...settings, _expanded: competition?._expanded },
        'supportingCause'
      ),
    [competition, settings]
  )

  return {
    ...entity,
    competition: competition,
    competitionId,
    organization,
    profile,
    section,
    settings,
    game,
    cause,
    isDiscordChat: settings?.chatProvider === ChatProvider.DISCORD,
    isCompetitionSuperAdmin: !!competition?._links?.superAdministrate,
    isCompetitionAdmin: !!competition?._links?.administrate,
    isCompetitionModerator: !!competition?._links?.moderate,
    canDelete: !!competition?._links?._delete,
    isSoloCompetition:
      Number(settings?.teamSizeMin) + Number(settings?.teamSizeMax) === 0,
    isFreeAgentMode: settings?.signUpMode === SignUpMode.ENTRYOPTIONAL,
    isSingleRestrictedCommunity:
      competition?._restrictToCommunities?.length === 1,
    isCommunityOptional: !!settings?.entryRepresentingOptional,
    updateProfile,
    updateSection,
    updateSettings,
    finalizeRanks
  }
}
