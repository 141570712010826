import { UserAccountControllerApi } from '@rallycry/api-suite-typescript/dist/apis/UserAccountControllerApi'
import { UserControllerApi } from '@rallycry/api-suite-typescript/dist/apis/UserControllerApi'
import { UserAccountUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/UserAccountUpdateCommand'
import { UserChildCreateCommand } from '@rallycry/api-suite-typescript/dist/models/UserChildCreateCommand'
import { useOrganization } from '../organization/useOrganization'
import { useCommunity } from './useCommunity'
import { useController } from '@/core/hooks/useSWRApi'

export const useCommunityPlaceholders = () => {
  const { ctrl } = useController(UserControllerApi)
  const { ctrl: accountCtrl } = useController(UserAccountControllerApi)
  const { organization } = useOrganization()

  const createPlaceholder = async (
    communityId: number,
    data: UserChildCreateCommand
  ) =>
    ctrl().createCommunityPlaceholder({
      CommunityPlaceholderCreateCommand: {
        account: { ...data.account, organization: organization?.id! },
        community: communityId
      }
    })

  const updatePlaceholder = (id: number, cmd: UserAccountUpdateCommand) =>
    accountCtrl().updateUserAccountById({ id, UserAccountUpdateCommand: cmd })

  return { createPlaceholder, updatePlaceholder }
}
