import Autocomplete from '@mui/material/Autocomplete'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { CompetitionDocument } from '@rallycry/api-suite-typescript/dist/models/CompetitionDocument'
import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { some } from 'lodash-es'
import { useState } from 'react'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { LabeledFieldHeader } from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { useNavigation } from '@/core/hooks/useNavigation'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { tryExtractJsonError } from '@/core/utils'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionActions } from '@/entity/competition/useCompetitionActions'
import { useCompetitionDocument } from '@/entity/competition/useCompetitionDocument'

export const CloneTeams = ({ selected }: { selected: number[] }) => {
  const [state, setState] = useState({
    processing: false,
    successCount: 0,
    errorCount: 0,
    issues: [] as any[]
  })

  // state for the autocomplete current selection
  const [selectedCompetition, setSelectedCompetition] =
    useState<CompetitionDocument | null>(null)

  // state for the autocomplete text field
  const [inputValue, setInputValue] = useState<string | undefined>(undefined)

  const {
    competitions,
    query: { isValidating, size, setSize }
  } = useCompetitionDocument({
    pageSize: 20,
    request: {
      q: inputValue ? inputValue : undefined,
      stateNot: [CompetitionState.CONCLUDED]
    }
  })

  const { getPath, navTo } = useNavigation()
  const { competition } = useCompetition()
  const { copyCompetitionTeam } = useCompetitionActions()

  const handleClone = async () => {
    setState(s => ({
      ...s,
      successCount: 0,
      errorCount: 0,
      processing: true,
      issues: []
    }))

    await Promise.all(
      selected.map(async it => {
        try {
          await copyCompetitionTeam(it, {
            toCompetition: selectedCompetition?.id!
          })
          setState(s => ({ ...s, successCount: s.successCount + 1 }))
        } catch (error) {
          const err = await tryExtractJsonError(error)
          err.issues?.forEach((issue: any) => (issue.id = it))
          setState(s => ({
            ...s,
            issues: [...s.issues, ...(err.issues || [])],
            errorCount: s.errorCount + 1
          }))
        }
      })
    )
  }

  const progress =
    ((state.successCount + state.errorCount) / selected.length) * 100

  const title = selected.length > 1 ? 'Clone Teams' : 'Clone Team'

  return state.processing ? (
    <>
      <Stack direction='column' spacing={3}>
        <LinearProgress variant='determinate' value={progress} />
        <Typography variant='subtitle2'>
          Success: {state.successCount}
        </Typography>
        <Typography variant='subtitle2'>Error: {state.errorCount}</Typography>

        {state.issues.map(it => (
          <Typography key={it.id} variant='subtitle2'>
            <NavigationLink
              to={getPath({
                root: RootRoute.Competition,
                rootId: competition?.id,
                subRoute: CompetitionRoute.Team,
                subId: it.id
              })}
              underline='hover'
              target='_new'
            >
              Team {it.id}
            </NavigationLink>{' '}
            - {it.message}
          </Typography>
        ))}
      </Stack>
      <ModalConfiguration title={title}>
        <RcButton
          disabled={progress < 100}
          fullWidth
          variant='contained'
          onClick={() =>
            navTo({
              root: RootRoute.Competition,
              rootId: selectedCompetition?.id,
              subRoute: CompetitionRoute.Team
            })
          }
        >
          {progress < 100
            ? `Cloning to ${selectedCompetition?.name}...`
            : `View ${selectedCompetition?.name}`}
        </RcButton>
      </ModalConfiguration>
    </>
  ) : (
    <>
      <LabeledFieldHeader label='Target Competition' />
      <Autocomplete
        sx={{ minWidth: '100%' }}
        value={selectedCompetition}
        onChange={(_, newValues) => {
          setSelectedCompetition(newValues)
        }}
        inputValue={inputValue}
        onInputChange={(_, val) => setInputValue(val)}
        getOptionLabel={option => option.name!}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={isValidating && !some(competitions)}
        options={competitions}
        clearOnBlur
        renderInput={(params: any) => (
          <TextField {...params} variant='outlined' />
        )}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget
            if (
              listboxNode.scrollTop + listboxNode.clientHeight ===
              listboxNode.scrollHeight
            ) {
              // https://github.com/mui-org/material-ui/issues/29508
              // scroll to top on page change, no workaround available yet.
              setSize(size + 1)
            }
          }
        }}
      />
      <ModalConfiguration title={title}>
        <RcButton
          fullWidth
          variant='contained'
          onClick={handleClone}
          disabled={!some(selected) || !selectedCompetition?.id}
        >
          Clone {selected.length} Selected
        </RcButton>
      </ModalConfiguration>
    </>
  )
}
