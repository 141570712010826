import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'
import { RcTrans } from '@/components/atoms/RcTrans'
import { MarkdownInput } from '@/components/molecules/input/MarkdownInput'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { useCompetitionRules } from '@/entity/competition/useCompetitionRules'

interface Props {
  rule: 'general' | 'critical' | 'match' | 'registration' | 'format'
  onComplete: () => void
}

export const RulesForm = ({ rule, onComplete }: Props) => {
  const { rules, update: updateRule } = useCompetitionRules()
  const [value, setValue] = useState<string | undefined>()

  useEffect(() => {
    if (!rules) return
    const r = rules[rule]
    setValue(r)
  }, [rules, rule])

  const onSubmit = async () => {
    await updateRule(0, { [rule]: value })
    onComplete()
  }

  return (
    <Box position='relative'>
      <MarkdownInput value={value} onChange={setValue} />
      <Stack
        direction='row'
        spacing={3}
        alignItems='center'
        justifyContent='flex-end'
      >
        <RcButton onClick={onComplete} variant='outlined'>
          <RcTrans i18nKey='shared.cancel' />
        </RcButton>
        <RcButton onClick={onSubmit} variant='contained'>
          <RcTrans i18nKey='shared.save' />
        </RcButton>
      </Stack>
    </Box>
  )
}
