import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { CompetitionEntryDocument } from '@rallycry/api-suite-typescript/dist/models'
import { snakeAssignTeamsToGroups } from '@/core/assignmentLogic'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTrans } from '@/components/atoms/RcTrans'
import { TeamAvatar } from '@/components/organisms/avatar/TeamAvatar'

export const PreviewBracketAssignments = ({
  teams,
  bracketCount,
  bracketName
}: {
  teams?: CompetitionEntryDocument[]
  bracketCount?: number
  bracketName?: string
}) => {
  const grouped = snakeAssignTeamsToGroups(teams || [], bracketCount || 1)

  return (
    <Stack spacing={5}>
      {grouped.map((group, index) => (
        <Stack key={index} spacing={2}>
          <Typography variant='subtitle1'>
            {bracketName || 'Bracket'}{' '}
            {String.fromCharCode(97 + index).toUpperCase()}
          </Typography>
          <Card key={index} elevation={3} sx={{ py: 2 }}>
            <Stack spacing={1}>
              {group.map((team, idx) => (
                <Grid
                  key={team.id}
                  container
                  direction='row'
                  alignItems='center'
                  spacing={1}
                >
                  <Grid item xs={1}>
                    <Typography variant='body2' textAlign='right' pr={1}>
                      {idx + 1}
                    </Typography>
                  </Grid>

                  <Grid item xs>
                    <TeamAvatar
                      team={teams?.find(it => it.id === team.id)}
                      size='xs'
                    />
                  </Grid>
                </Grid>
              ))}
            </Stack>
          </Card>
        </Stack>
      ))}
      <ModalConfiguration
        title={<RcTrans i18nKey='competition:bracket.preview-assignments' />}
      />
    </Stack>
  )
}
