import {
  UserControllerApi,
  ViewUserTopCommunitiesRequest
} from '@rallycry/api-suite-typescript/dist/apis/UserControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserTopCommunities = (
  options?: EntityOptions<ViewUserTopCommunitiesRequest>
) => {
  const userId = useParsedParam('userId')
  const { ctrl } = useController(UserControllerApi)
  const id = userId || options?.idOrKey

  const entity = useQueryEntity({
    key: 'useUserTopCommunities',
    expand: 'content{community{organization,additionalOrganizations}}',
    ...options,
    request:
      id || options?.request ? { userId: id, ...options?.request } : undefined,
    read: req => ctrl().viewUserTopCommunities(req)
  })

  return { ...entity, topCommunities: entity.flat }
}
