import { BracketSubmissionDocument } from '@rallycry/api-suite-typescript/dist/models/BracketSubmissionDocument'
import { CompetitionBracketSettingsResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketSettingsResource'
import { CompetitionEntryResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryResource'
import { orderBy } from 'lodash-es'
import { useMemo } from 'react'
import { SubmissionsDisplayTableRow } from './SubmissionsDisplayTableRow'

export const sortValidSubmission = (
  submissions: BracketSubmissionDocument[],
  bracketSettings: CompetitionBracketSettingsResource
) => {
  const sort = bracketSettings?.submissionOrderAscending ? 'asc' : 'desc'
  const byPoints = orderBy(submissions, it => it.points, sort)
  const mapped = byPoints.map((it, idx) => ({
    ...it,
    isCutoff: !bracketSettings || bracketSettings?.submissionTopIncluded! <= idx
  }))
  const byOrdinal = orderBy(mapped, it => it.ordinal)
  return byOrdinal
}

export const SubmissionsDisplayTable = ({
  submissions,
  team,
  bracketSettings
}: {
  submissions?: BracketSubmissionDocument[]
  team?: CompetitionEntryResource
  bracketSettings?: CompetitionBracketSettingsResource
}) => {
  const sorted = sortValidSubmission(submissions || [], bracketSettings || {})

  return (
    <>
      {sorted?.map((it, idx) => (
        <SubmissionsDisplayTableRow
          key={it?.id || idx}
          submission={it}
          isCutoff={it.isCutoff}
          team={team}
        />
      ))}
    </>
  )
}
