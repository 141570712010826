import dynamic from 'next/dynamic'
import { FormResponseEditProps } from '../FormResponseQuestionEdit'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'

const RcDateTimePicker = dynamic(
  async () =>
    (await import('@/components/molecules/input/RcDateTimePicker'))
      .RcDateTimePicker,
  { ssr: false, loading: () => <></> }
)

export const FormResponseEditDateTime = ({
  responseId,
  question,
  showTimeSelect
}: FormResponseEditProps & { showTimeSelect?: boolean }) => {
  const { answers, createFormAnswer, updateFormAnswer } = useFormResponse({
    idOrKey: responseId
  })

  const answer = answers?.find(it => it.question?.id === question?.id)
  const value = answer?.value

  return (
    <RcDateTimePicker
      // disable form answer while fake id -1 during creation
      disabled={answer?.id === -1}
      UTC={!showTimeSelect}
      showTimeSelect={showTimeSelect}
      selected={value ? new Date(value) : ('' as any)}
      onChange={(val: Date) =>
        answer
          ? updateFormAnswer(answer?.id!, {
              value: val.toISOString()
            })
          : createFormAnswer({
              question: question?.id!,
              value: val.toISOString()
            })
      }
    />
  )
}
