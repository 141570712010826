import {
  UserContextControllerApi,
  UserContextDocument
} from '@rallycry/social-api-typescript'
import { doc, onSnapshot } from 'firebase/firestore'
import useSWRSubscription, { SWRSubscriptionOptions } from 'swr/subscription'
import { RcTrans } from '../../components/atoms/RcTrans'
import { useGateway } from '../site/useGateway'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useSocialController } from '@/core/hooks/useSWRApi'
import { usePage } from '@/components/providers/site/PageProvider'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

export const useSocialUserContext = (
  options?: EntityOptions & { shouldSubscribe?: boolean }
) => {
  const { open } = usePage()
  const { isComplete, account } = useGateway()
  const { firestore, user } = useFirebase()
  const { ctrl } = useSocialController(UserContextControllerApi)
  const { isProfile, impersonation } = useImpersonation()
  const docKey = `Social_User_Context/${user?.uid}/contexts/${
    isProfile ? impersonation?.id : account?.id
  }`

  const {
    read: { data: schema }
  } = useReadEntity({
    key: 'useSocialUserContextSchema',
    persist: true,
    paused: !isComplete,
    ...options,
    idOrKey: 1,
    fallbackData: null,
    read: async () => ctrl().getUserContextSchema()
  })

  const data = useSWRSubscription(
    [docKey, schema, isComplete],
    (
      [docKey, schema, isComplete],
      { next }: SWRSubscriptionOptions<UserContextDocument, Error>
    ) => {
      if (!isComplete || !schema) {
        next(null, undefined)
        return () => {}
      }
      const ref = doc(firestore, docKey)
      const unsub = onSnapshot(
        ref,
        res => {
          const data = res.data() as UserContextDocument
          next(null, data)
        },
        err => next(err)
      )
      return () => unsub()
    },
    { suspense: false }
  )

  const pinChat = async (chat: string) => {
    await ctrl().pinMyChat({ pinChatCommand: { chat } })
    open(undefined, <RcTrans i18nKey='shared.chat-focus-added' />, 3000)
  }
  const unpinChat = async (chat: string) => {
    await ctrl().unpinMyChat({ pinChatCommand: { chat } })
    open(undefined, <RcTrans i18nKey='shared.chat-focus-removed' />, 3000)
  }

  return { ...data.data, pinChat, unpinChat }
}
