import Stack from '@mui/material/Stack'
import { ComponentDynamicIFrameEmbed } from '../../../../../models/types'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'

export const IFrameEmbed = ({
  title,
  subtitle,
  url,
  isYouTube
}: ComponentDynamicIFrameEmbed & { isYouTube?: boolean }) => {
  if (!url) return null
  const adjustedUrl = isYouTube ? url.replace('watch?v=', 'embed/') : url
  return (
    <Stack direction='column' spacing={4} mb={4}>
      <SectionHeader title={title} subtitle={subtitle} />
      <iframe
        allow={
          isYouTube
            ? 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            : ''
        }
        allowFullScreen={isYouTube}
        height='100%'
        src={adjustedUrl}
        title={adjustedUrl}
        style={{ border: 0, minHeight: 500 }}
        width='100%'
      />
    </Stack>
  )
}
