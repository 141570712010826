import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { AccessGroupResource } from '@rallycry/api-suite-typescript/dist/models/AccessGroupResource'
import { useDebounce } from 'use-debounce'
import { ModalConfiguration } from '../modal/ModalConfiguration'
import { ExpansionType, expand } from '@/core/expand'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useUserAccessGroups } from '@/entity/user/useUserAccessGroups'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { useAccessGroups } from '@/entity/acl/useAccessGroups'
import { EntityAutocomplete } from '@/components/molecules/input/EntityAutocomplete'
import { RcButton } from '@/components/molecules/interactive/RcButton'

export const ManageUserAccessGroups = ({ userId }: { userId: number }) => {
  const [selected, setSelected] = useState<AccessGroupResource>()
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery] = useDebounce(query, 300)

  const {
    query: { isValidating, setSize, size },
    groups: allGroups
  } = useAccessGroups({
    request: {
      nameStartsWith: debouncedQuery
    }
  })

  const { groups, addAccessGroupUser, removeAccessGroupUser } =
    useUserAccessGroups({
      request: { userId }
    })

  return (
    <>
      <ModalConfiguration
        title={<RcTrans i18nKey='profile:access-groups-title' />}
      />
      <Stack direction='row' spacing={2}>
        <Box flexGrow={1}>
          <EntityAutocomplete
            isValidating={isValidating}
            options={allGroups}
            selected={[selected]}
            placeholder={'Search for a group'}
            onSearch={q => setQuery(q)}
            onLoadOptions={() => setSize(size + 1)}
            onChange={async updated =>
              setSelected(updated as AccessGroupResource)
            }
          />
        </Box>
        <RcButton
          onClick={() => addAccessGroupUser(selected?.id!)}
          disabled={!selected?.id}
        >
          <RcTrans i18nKey='shared.add' />
        </RcButton>
      </Stack>
      <Table>
        <TableBody>
          {groups?.map(it => {
            const group = expand<AccessGroupResource>(
              it,
              it._expanded,
              ExpansionType.AccessGroup
            )
            return (
              <TableRow key={it.accessGroup?.id}>
                <TableCell>{group?.name || it.accessGroup?.id}</TableCell>
                <TableCell align='right'>
                  <ConfirmingButton
                    message={
                      <RcTrans i18nKey='error.confirmation.user-access' />
                    }
                    buttonName={<RcTrans i18nKey='shared.delete' />}
                    icon={['fal', 'trash']}
                    onClick={() => removeAccessGroupUser(group?.id!)}
                    size='small'
                    color='error'
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}
