import {
  CompetitionEntryInboxControllerApi,
  ReadCompetitionEntryInboxMessagesRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionEntryInboxControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionTeamInbox = (
  options?: EntityOptions<ReadCompetitionEntryInboxMessagesRequest>
) => {
  const entryId = useParsedParam('teamId')
  const { ctrl } = useController(CompetitionEntryInboxControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionTeamInbox',
    expand: '_links',
    ...options,
    request: { entryId, ...options?.request },
    read: req =>
      ctrl({
        expectedStatuses: [403],
        fallbackResponse: { content: [] }
      }).readCompetitionEntryInboxMessages(req)
  })

  return { ...entity, inbox: entity.flat }
}
