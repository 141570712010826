/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { RcTrans } from '@/components/atoms/RcTrans'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { EmailParticipantForm } from '@/components/organisms/user/EmailParticipantForm'
import { useCompetitionActions } from '@/entity/competition/useCompetitionActions'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'

export const ContactParticipantsModal: React.FC<{
  label?: React.ReactNode
  participants: number[]
  button?: boolean
  open?: boolean
  onClose?: () => any
}> = ({ label, participants, button, open, onClose }) => {
  // t('competition:contact.modal.title', { count })
  // t('competition:contact.modal.subtitle', { count })
  return (
    <ModalTrigger
      open={open}
      onClose={onClose}
      activation={handleOpen => (
        <RcIconButton
          icon={['fal', 'envelope']}
          onClick={handleOpen}
          disabled={participants.length === 0}
        />
      )}
    >
      {({ handleClose }) => (
        <ContactParticipantsFlow
          participants={participants}
          onComplete={handleClose}
        />
      )}
    </ModalTrigger>
  )
}

export const ContactParticipantsFlow = ({
  participants,
  onComplete
}: {
  participants: number[]
  onComplete: () => void
}) => {
  const { emailParticipants } = useCompetitionActions()
  return (
    <EmailParticipantForm
      title={
        <RcTrans
          i18nKey='competition:contact.modal.title'
          tOptions={{
            count: participants?.length
          }}
        />
      }
      subtitle={
        <RcTrans
          i18nKey='competition:contact.modal.subtitle'
          tOptions={{
            count: participants?.length
          }}
        />
      }
      participants={participants}
      onEmail={emailParticipants}
      onComplete={onComplete}
    />
  )
}
