'use client'

import { BracketMatchChannelResource } from '@rallycry/api-suite-typescript'
import { BracketMatchChannelControllerApi } from '@rallycry/api-suite-typescript/dist/apis'
import { ChatAccessLevel, MemberKind } from '@rallycry/social-api-typescript'
import { ChatControllerApi } from '@rallycry/social-api-typescript/dist/apis'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useController, useSocialController } from '@/core/hooks/useSWRApi'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

export const useMatchChatTicket = () => {
  const { ctrl: matchCtrl } = useController(BracketMatchChannelControllerApi)
  const { ctrl } = useSocialController(ChatControllerApi)

  const { user } = useFirebase()
  const { account } = useUserAccount()

  const handleAddToChat = async (channel: BracketMatchChannelResource) => {
    const ticket = await matchCtrl().obtainBracketMatchChannelTicket({
      id: channel?.id!
    })
    const chatId = channel?.externalId
    await ctrl({ ticket: ticket.ticket }).grantChatMember({
      id: chatId!,
      chatGrantMemberCommand: {
        accessLevel: ChatAccessLevel.MODERATOR,
        id: account?.id?.toString()!,
        externalId: user?.uid!,
        image: account?.hideImage
          ? undefined
          : account?.imageOverride || account?._expanded?.user?.[0]?.image,
        kind: MemberKind.FIREBASEUSER,
        name: account?.name
      }
    })
  }
  return { handleAddToChat }
}
