import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ActionLink } from '../../Landing/components/ActionLink'
import { getTextColor } from '../DocumentPage'
import { ComponentDynamicSideBySide } from '../../../../../models/types'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { MarkdownDisplay } from '@/components/molecules/input/MarkdownDisplay'
import { ImageDisplay } from '@/components/atoms/ImageDisplay'
import { ImageSize, useCMSFile } from '@/core/hooks/useCMSFile'
import { useIsMainLayout } from '@/core/hooks/useLayoutQueries'
import { useIsMobile } from '@/core/hooks/useMediaQueries'

export const SideBySide = ({
  isFlipped,
  title,
  supertitle,
  subtitle,
  highlights,
  description,
  images,
  titleImage,
  mediaAction,
  actions,
  layout,
  vertical, // deprecated
  background,
  fullWidthPage
}: ComponentDynamicSideBySide & {
  isFlipped: boolean
  fullWidthPage: boolean
}) => {
  const isMobile = useIsMobile()
  const { getImagePath } = useCMSFile()
  const isMainLayout = useIsMainLayout()
  const titlePath = getImagePath(titleImage?.data, ImageSize.Large)
  const imagePath = getImagePath(images?.data?.[0], ImageSize.Medium)

  const direction =
    layout === ('image-left' as any)
      ? 'row-reverse'
      : layout === ('image-right' as any)
      ? 'row'
      : isFlipped
      ? 'row-reverse'
      : 'row'

  const image = imagePath ? (
    mediaAction?.path ? (
      <Link href={mediaAction?.path} target='_blank' rel='noopener'>
        <ImageDisplay rounded width='100%' height='auto' path={imagePath} />
      </Link>
    ) : (
      <ImageDisplay rounded width='100%' height='auto' path={imagePath} />
    )
  ) : null

  const adjustedVertical = vertical || layout === ('image-below' as any)

  return (
    <Box color={getTextColor(background?.mode)} py={vertical ? 5 : undefined}>
      <Stack
        spacing={isMobile ? 5 : 20}
        direction={isMobile || adjustedVertical ? 'column' : direction}
        justifyContent='space-between'
        alignItems='center'
      >
        {/* Column 1 */}
        <Stack
          direction='column'
          spacing={2}
          alignItems={adjustedVertical ? 'center' : 'flex-start'}
          textAlign={adjustedVertical ? 'center' : 'left'}
          width={image && !adjustedVertical && !isMobile ? '50%' : '100%'}
        >
          {titlePath ? (
            <Box pb={4}>
              <ImageDisplay path={titlePath} width='auto' height='auto' />
            </Box>
          ) : null}
          {title ? (
            <Box>
              {supertitle ? (
                <Typography variant='subtitle1'>{supertitle}</Typography>
              ) : null}
              <Typography variant='h1'>{title}</Typography>
            </Box>
          ) : null}
          {subtitle ? (
            <Typography className='alt' variant='subtitle1'>
              {subtitle}
            </Typography>
          ) : null}
          {highlights ? (
            <Grid container direction='row'>
              {highlights?.map(it => (
                <Grid key={it?.id} item xs={6}>
                  <Typography variant='subtitle1' color='text.secondary'>
                    {it?.title}
                  </Typography>
                  <Typography variant='h2' color='primary.main'>
                    {it?.content}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ) : null}
          {description ? (
            <MarkdownDisplay
              paragraph
              markdown={description}
              variant={fullWidthPage ? 'body0' : 'body1'}
              typographyProps={{
                textAlign: adjustedVertical ? 'center' : 'left'
              }}
            />
          ) : null}
          {image && adjustedVertical ? (
            <Box width='100%' pb={5}>
              {image}
            </Box>
          ) : null}
          <Stack
            direction='row'
            spacing={3}
            justifyContent={adjustedVertical ? 'center' : 'left'}
          >
            {actions?.map(it => (
              <ActionLink
                key={it?.path}
                action={it!}
                ButtonProps={{
                  size: isMainLayout || isMobile ? 'medium' : 'large',
                  sx: { height: '100%', width: '100%', wordBreak: 'keep-all' }
                }}
              />
            ))}
          </Stack>
        </Stack>
        {/* Column 2 */}
        {image && !adjustedVertical ? (
          <Box width={isMobile ? '100%' : '50%'}>{image}</Box>
        ) : null}
      </Stack>
    </Box>
  )
}
