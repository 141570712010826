import { useEffect, useState } from 'react'

export const useKeyPressed = (key: string) => {
  const [isKeyDown, setIsKeyDown] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === key) setIsKeyDown(true)
    }
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === key) setIsKeyDown(false)
    }
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [key])

  return isKeyDown
}
