import Typography from '@mui/material/Typography'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTrans } from '@/components/atoms/RcTrans'
import { DiscoveryRoute, RootRoute } from '@/core/route-keys'
import { useNavigation } from '@/core/hooks/useNavigation'

export const FindCommunity = () => {
  const { navTo } = useNavigation()
  return (
    <>
      <Typography variant='body0'>
        <RcTrans i18nKey='competition:find-a-community-prompt' />
      </Typography>
      <ModalConfiguration>
        <RcButton
          fullWidth
          onClick={() =>
            navTo({
              root: RootRoute.Discovery,
              subRoute: DiscoveryRoute.Communities
            })
          }
        >
          <RcTrans i18nKey='competition:find-a-community-action' />
        </RcButton>
      </ModalConfiguration>
    </>
  )
}
