import { useState, useEffect, useCallback, MutableRefObject } from 'react'

let draggingCount = 0
type Params = {
  fileDropRef: MutableRefObject<HTMLElement | null>
  fileInputRef?: any
  disabled?: boolean
  handleChanges: (arg0: FileList) => Promise<any>
}

export default function useDragging({
  fileDropRef,
  fileInputRef,
  disabled,
  handleChanges
}: Params): boolean {
  const [dragging, setDragging] = useState(false)

  const handleClick = useCallback(() => {
    if (disabled) return
    fileInputRef?.current?.click?.()
  }, [fileInputRef, disabled])

  const handleDragIn = useCallback((ev: DragEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    draggingCount++
    if (ev.dataTransfer?.items && ev.dataTransfer.items.length !== 0) {
      setDragging(true)
    }
  }, [])

  const handleDragOut = useCallback((ev: DragEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    draggingCount--
    if (draggingCount > 0) return
    setDragging(false)
  }, [])

  const handleDrag = useCallback((ev: DragEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
  }, [])

  const handleDrop = useCallback(
    async (ev: DragEvent) => {
      ev.preventDefault()
      ev.stopPropagation()
      setDragging(false)
      draggingCount = 0

      const eventFiles = ev.dataTransfer?.files
      if (eventFiles && eventFiles.length > 0) {
        const files = eventFiles
        await handleChanges(files)
      }
    },
    [handleChanges]
  )

  useEffect(() => {
    const ele = fileDropRef?.current
    if (!ele) return
    ele.addEventListener('click', handleClick)
    ele.addEventListener('dragenter', handleDragIn)
    ele.addEventListener('dragleave', handleDragOut)
    ele.addEventListener('dragover', handleDrag)
    ele.addEventListener('drop', handleDrop)
    return () => {
      ele.removeEventListener('click', handleClick)
      ele.removeEventListener('dragenter', handleDragIn)
      ele.removeEventListener('dragleave', handleDragOut)
      ele.removeEventListener('dragover', handleDrag)
      ele.removeEventListener('drop', handleDrop)
    }
  }, [
    handleClick,
    handleDragIn,
    handleDragOut,
    handleDrag,
    handleDrop,
    fileDropRef
  ])

  return dragging
}
