import { FormResponseEditProps } from '../FormResponseQuestionEdit'
import { RcSlider } from '@/components/molecules/input/RcSlider'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'

export const FormResponseEditScale = ({
  responseId,
  question
}: FormResponseEditProps) => {
  const { answers, createFormAnswer, updateFormAnswer } = useFormResponse({
    idOrKey: responseId
  })

  const answer = answers?.find(it => it.question?.id === question?.id)
  const value = answer?.value

  return (
    <RcSlider
      // disable form answer while fake id -1 during creation
      disabled={answer?.id === -1}
      getLabel={val => `${val}`}
      min={1}
      max={5}
      value={Number(value || 1)}
      updateFn={(val: any) => {
        return answer
          ? updateFormAnswer(answer?.id!, {
              value: val
            })
          : createFormAnswer({
              question: question.id!,
              value: val
            })
      }}
    />
  )
}
