import {
  UserControllerApi,
  ViewUserTopGamesRequest
} from '@rallycry/api-suite-typescript/dist/apis/UserControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserTopGames = (
  options?: EntityOptions<ViewUserTopGamesRequest>
) => {
  const userId = useParsedParam('userId')
  const { ctrl } = useController(UserControllerApi)
  const id = userId || options?.idOrKey

  const entity = useQueryEntity({
    key: 'useUserTopGames',
    expand: 'content{game}',
    ...options,
    request:
      id || options?.request ? { user: id, ...options?.request } : undefined,
    read: req => ctrl().viewUserTopGames(req)
  })

  return { ...entity, topGames: entity.flat }
}
