import Button from '@mui/material/Button'
import { BracketAssignmentResource } from '@rallycry/api-suite-typescript/dist/models/BracketAssignmentResource'
import { MatchGameResultCommand } from '@rallycry/api-suite-typescript/dist/models/MatchGameResultCommand'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'

const isWinner = (
  it: MatchGameResultCommand,
  assignment?: BracketAssignmentResource
) => it.winner === assignment?.id

export const MatchScoreCheck = ({
  score,
  assignment
}: {
  score: MatchGameResultCommand
  assignment?: BracketAssignmentResource
}) => (
  <RcButton
    color='primary'
    variant={isWinner(score, assignment) ? 'contained' : 'outlined'}
    sx={{ cursor: 'default' }}
    fullWidth
  >
    {isWinner(score, assignment) ? (
      <RcIcon icon={['fal', 'check']} size='xs' mr={2} />
    ) : null}{' '}
    <RcTrans i18nKey='competition:match.win' />
  </RcButton>
)
