import { getAuth, signInAnonymously } from 'firebase/auth'
import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { FormResponseEditProps } from '../FormResponseQuestionEdit'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

const RcFileInput = dynamic(
  async () =>
    (await import('@/components/molecules/input/RcFileInput')).RcFileInput,
  { ssr: false, loading: () => <></> }
)

export const FormResponseFileUpload = ({
  responseId,
  question
}: FormResponseEditProps) => {
  const { firebase } = useFirebase()
  const { answers, createFormAnswer, updateFormAnswer } = useFormResponse({
    idOrKey: responseId
  })

  const answer = answers?.find(it => it.question?.id === question?.id)

  const { user } = useFirebase()

  useEffect(() => {
    if (!user) {
      signInAnonymously(getAuth(firebase))
    }
  }, [user, firebase])

  const onSubmit = async (url: string) => {
    answer
      ? await updateFormAnswer(answer?.id!, { value: url })
      : await createFormAnswer({
          question: question.id!,
          value: url
        })
  }

  const onClear = async () => {
    await updateFormAnswer(answer?.id!, { value: '' })
  }

  return (
    <RcFileInput
      source={answer?.value}
      onComplete={onSubmit}
      onClear={onClear}
      autoSubmit
      allowAnyFile
      saveAs={`response-${responseId}-${question?.id}`}
    />
  )
}
