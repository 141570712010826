import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'

export const Trophy = ({
  placement,
  size
}: {
  placement: number
  size: SizeProp
}) => {
  if (!placement) return null

  const text =
    placement === 1 ? (
      <RcTrans i18nKey='placement.gold' />
    ) : placement === 2 ? (
      <RcTrans i18nKey='placement.silver' />
    ) : placement === 3 ? (
      <RcTrans i18nKey='placement.bronze' />
    ) : (
      `${placement}th`
    )

  const color =
    placement === 1
      ? 'gold'
      : placement === 2
      ? 'silver'
      : placement === 3 || placement === 4
      ? '#CD7F32'
      : 'inherit'

  return (
    <Tooltip title={text}>
      <Box>
        <RcIcon icon={['fal', 'trophy']} size={size} colorHex={color} />
      </Box>
    </Tooltip>
  )
}
