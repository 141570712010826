import { RcTrans } from '@/components/atoms/RcTrans'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import {
  Calendar,
  CalendarSidebarProps
} from '@/components/organisms/calendar/Calendar'
import { CompetitionEventWithVotes } from '@/components/pages/Competition/components/match/scheduler/scheduler-utils'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useTime } from '@/core/hooks/useTime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWindowEvent } from '@mantine/hooks'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  CompetitionEventCreateCommand,
  CompetitionEventResource,
  CompetitionEventUpdateCommand,
  EventKind
} from '@rallycry/api-suite-typescript'
import { groupBy, isEmpty, orderBy } from 'lodash-es'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { RcButton } from '../interactive/RcButton'
import { useIsMdUp } from '@/core/hooks/useMediaQueries'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcIconButton } from '../interactive/RcIconButton'

export const RcScheduler = ({
  title,
  subtitle,
  label,
  submit,
  kind,
  existing,
  backgroundEvents,
  onComplete
}: {
  title: string
  subtitle: string
  label: string
  submit: string
  kind: EventKind
  existing: CompetitionEventResource[]
  backgroundEvents?: CompetitionEventWithVotes[]
  onComplete: (results: {
    all: CompetitionEventResource[]
    created: CompetitionEventResource[]
    updated: CompetitionEventResource[]
    removed: CompetitionEventResource[]
  }) => Promise<any>
}) => {
  const [height, setHeight] = useState(600)
  const [events, setEvents] = useState<CompetitionEventResource[]>(existing)
  const { t } = useRcTranslation()

  useWindowEvent('resize', () => {
    setHeight(window.innerHeight - 300)
  })

  const handleCreate = () => {
    const created = events
      .filter(ev => !existing.find(it => it.id === ev.id))
      .map(({ id, ...it }) => it)
    const removed = existing.filter(ev => !events.find(it => it.id === ev.id))
    const updated = events.filter(ev =>
      existing.find(
        it =>
          it.id === ev.id &&
          !removed.includes(it) &&
          (it.startDate !== ev.startDate || it.endDate !== ev.endDate)
      )
    )
    onComplete({ created, updated, removed, all: events })
  }

  return (
    <Calendar
      SideBarComponent={props => (
        <SchedulerSidebar
          {...props}
          label={label}
          onRemove={id => setEvents(e => e.filter(it => it.id !== id))}
          submit={
            <RcButton fullWidth size='medium' onClick={handleCreate}>
              {submit}
            </RcButton>
          }
        />
      )}
      height={height}
      title={<SectionHeader title={title} subtitle={subtitle} pb={4} />}
      editable
      views={['week']}
      events={events}
      backgroundEvents={backgroundEvents}
      simpleMode
      padding={5}
      create={async (cmd: CompetitionEventCreateCommand) => {
        setEvents(e => [
          ...e,
          {
            ...cmd,
            kind,
            name: kind === EventKind.LADDER ? t('BracketKind.LADDER') : '',
            id: uuidv4() as any
          }
        ])
      }}
      update={async (id: number, cmd: CompetitionEventUpdateCommand) => {
        setEvents(e => e.map(it => (it.id === id ? { ...it, ...cmd } : it)))
      }}
      remove={async (id: number) => {
        setEvents(e => e.filter(it => it.id !== id))
      }}
    />
  )
}

export const SchedulerSidebar = ({
  events,
  height,
  label,
  onRemove,
  submit
}: CalendarSidebarProps & {
  label: string
  onRemove: (id: number) => void
  submit: React.ReactNode
}) => {
  const { displayTime } = useTime()
  const sorted = orderBy(events, ev => ev.startDate)
  const grouped = groupBy(sorted, ev =>
    moment(ev.startDate).format('ddd MMM DD')
  )
  const isMdUp = useIsMdUp()

  if (!isMdUp)
    return (
      <ModalConfiguration direction='row'>
        <Box pb={2} pr={2}>
          {submit}
        </Box>
      </ModalConfiguration>
    )
  return (
    <Card
      elevation={3}
      sx={{
        p: 6,
        height: '100%',
        position: 'relative'
      }}
    >
      <Box sx={{ mt: 2, pr: 1, height: height + 40, overflowY: 'auto' }}>
        <Stack direction='column' spacing={2}>
          {isEmpty(grouped) ? null : (
            <Typography variant='subtitle1' color='text.secondary'>
              {label}
            </Typography>
          )}
          {Object.keys(grouped).map(day => (
            <Card key={day} elevation={1} sx={{ padding: 4, borderRadius: 2 }}>
              <Typography variant='subtitle1' color='text.secondary'>
                {day}
              </Typography>
              {grouped[day].map(ev => (
                <Stack
                  key={ev.id}
                  direction='row'
                  justifyContent='space-between'
                >
                  <Typography
                    variant='subtitle1'
                    sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'clock']}
                      size='xs'
                      style={{ marginRight: 5 }}
                    />
                    {displayTime(ev.startDate)} - {displayTime(ev.endDate)}
                  </Typography>
                  <RcIconButton
                    color='error'
                    icon={['fal', 'trash']}
                    size='extraSmall'
                    onClick={() => onRemove(ev.id!)}
                  />
                </Stack>
              ))}
            </Card>
          ))}
        </Stack>
        {isEmpty(grouped) ? (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Typography color='text.secondary' textAlign='center'>
              <RcTrans i18nKey='shared.no-events' />
            </Typography>
          </Box>
        ) : null}
      </Box>
      {submit}
    </Card>
  )
}
