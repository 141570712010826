import { UserAccountResource } from '@rallycry/api-suite-typescript/dist/models/UserAccountResource'
import { ChildDetailsForm } from './DependentDetailsForm'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useUserFamilyMembers } from '@/entity/user/useUserFamilyMembers'

export const CreateEditDependentFlow = ({
  onComplete,
  existing
}: {
  onComplete: (id: UserAccountResource) => Promise<void>
  existing?: UserAccountResource
}) => {
  const { create, update } = useUserFamilyMembers()
  return (
    <ChildDetailsForm
      title={
        <RcTrans i18nKey='home:create-child-flow.create-child-form-title' />
      }
      onComplete={onComplete}
      existing={existing}
      onCreate={(_, cmd) =>
        existing?.id ? update(existing?.id, cmd.account) : create(cmd)
      }
    />
  )
}
