import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { JoinRestriction } from '@rallycry/api-suite-typescript/dist/models/JoinRestriction'
import { Form, Formik } from 'formik'
import { Select, TextField } from 'formik-mui'
import { first, some } from 'lodash-es'
import * as Yup from 'yup'
import { JoinFlowContext } from './join-flow-machine'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { RcToggle } from '@/components/molecules/input/RcToggle'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { PageForm } from '@/components/organisms/page/PageForm'
import { TranslationNamespace } from '@/core/translation'
import { handleIssues } from '@/core/utils'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcLoader } from '@/components/atoms/RcLoader'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

interface SetTeamProps {
  context: JoinFlowContext
  processing: boolean
  onCreated: (values: JoinFlowContext) => void
  onBack?: () => void
}

export const SetTeam: React.FC<SetTeamProps> = ({
  context,
  processing,
  onCreated,
  onBack
}) => {
  const { featEntryCoaches, featHideCommunity } = useFeatures()
  const { settings } = useCompetition()
  const { t } = useRcTranslation([TranslationNamespace.Competition])

  const submit = async (values: JoinFlowContext) => onCreated(values)

  const showTeamName = !context.isSoloCompetition
  const showTeamSettings = !context.isFreeAgent && !context.isSoloCompetition

  const communityOptional =
    context.teamRepresentingOptional || context.isFreeAgent || featHideCommunity

  const validation = Yup.object().shape({
    alternateName: showTeamName
      ? Yup.string().required(t('competition:team.name-required') as any)
      : Yup.string(),
    representing: communityOptional ? Yup.number() : Yup.number().required()
  })

  // just show a loader if an autojoin type
  if (processing || context.selectedTeam) {
    return <RcLoader height={300} />
  }

  const [fa1, fa2] = settings?.participantInquiry?.split('/') || [
    t('competition:team.free-agent-title'),
    t('competition:team.free-agent-description')
  ]

  const { initialErrors, initialTouched, unhandled } = handleIssues(
    context.error,
    ['alternateName', 'representing']
  )

  const isTeamSelected = context.orgCommunities?.find(
    it => it.id === context.representing && it.kind === CommunityKind.TEAM
  )

  return (
    <Formik
      initialValues={{
        ...context,
        representing: context.representing || first(context.orgCommunities)?.id
      }}
      validationSchema={validation}
      initialErrors={initialErrors}
      initialTouched={initialTouched}
      enableReinitialize
      onSubmit={submit}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form id='create-team-form'>
          <Grid container direction='column' spacing={10}>
            {showTeamName ? (
              <Grid item>
                <LabeledField
                  component={TextField}
                  label={
                    context.isFreeAgent ? (
                      fa1
                    ) : (
                      <RcTrans i18nKey='competition:team.name-label' />
                    )
                  }
                  description={
                    context.isFreeAgent ? (
                      fa2
                    ) : (
                      <RcTrans i18nKey='competition:team.name-context' />
                    )
                  }
                  name='alternateName'
                  type='text'
                  variant='outlined'
                  multiline={context.isFreeAgent}
                  max={150}
                  fullWidth
                />
              </Grid>
            ) : null}

            {some(context.orgCommunities) &&
            !context.isFreeAgent &&
            !isTeamSelected &&
            !featHideCommunity ? (
              <Grid item>
                <LabeledField
                  component={Select}
                  name='representing'
                  fullWidth
                  label={
                    <RcTrans i18nKey='competition:team.representing-label' />
                  }
                  description={
                    <RcTrans i18nKey='competition:team.representing-description' />
                  }
                  variant='outlined'
                >
                  {communityOptional ? (
                    <MenuItem value={''}>
                      <RcTrans i18nKey='shared.none' />
                    </MenuItem>
                  ) : null}
                  {context.orgCommunities
                    ?.filter(it => it.kind === CommunityKind.BASIC)
                    .map(it => (
                      <MenuItem key={it.id} value={it.id}>
                        <AvatarText src={it.image} variant='rounded'>
                          {it.name}
                        </AvatarText>
                      </MenuItem>
                    ))}
                </LabeledField>
              </Grid>
            ) : null}

            {showTeamSettings && featEntryCoaches ? (
              <Grid item>
                <LabeledField
                  component={Select}
                  name='nonParticipantLeader'
                  disabled={context.nonParticipantLeaderLocked}
                  fullWidth
                  label={
                    <RcTrans i18nKey='competition:team.non-participating-leader-title' />
                  }
                  description={
                    context.nonParticipantLeaderLocked ? (
                      <RcTrans i18nKey='competition:team.non-participating-leader-locked-description' />
                    ) : (
                      <RcTrans i18nKey='competition:team.non-participating-leader-description' />
                    )
                  }
                  variant='outlined'
                >
                  <MenuItem value={'false'}>
                    <RcTrans i18nKey='competition:team.participating-leader' />
                  </MenuItem>
                  <MenuItem value={'true'}>
                    <RcTrans i18nKey='competition:team.non-participating-leader' />
                  </MenuItem>
                </LabeledField>
              </Grid>
            ) : null}
          </Grid>

          <ModalConfiguration
            title={
              context.isFreeAgent ? (
                <RcTrans i18nKey='competition:join-flow.free-agent.title' />
              ) : (
                <RcTrans i18nKey='competition:join-flow.create-team-title' />
              )
            }
            topAction={onBack}
            error={first(unhandled)?.message}
          >
            <RcButton
              variant='contained'
              type='submit'
              disabled={isSubmitting}
              form='create-team-form'
              fullWidth
            >
              {context.isFreeAgent ? (
                <RcTrans i18nKey='competition:team.create-participant' />
              ) : (
                <RcTrans i18nKey='competition:join-flow.create-team.create-action' />
              )}
            </RcButton>
          </ModalConfiguration>
        </Form>
      )}
    </Formik>
  )
}
