export const REACTIONS = [
  {
    id: 'party-parrot',
    display: 'https://cultofthepartyparrot.com/parrots/hd/parrot.gif'
  },
  {
    id: 'party-parrot-reverse',
    display: 'https://cultofthepartyparrot.com/parrots/hd/reverseparrot.gif'
  },
  {
    id: 'party-wizard',
    display: 'https://cultofthepartyparrot.com/guests/hd/party-wizard.gif'
  }
]
