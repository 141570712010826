import { RcTrans } from '@/components/atoms/RcTrans'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { useIsLgDown } from '@/core/hooks/useMediaQueries'
import { useNavigation } from '@/core/hooks/useNavigation'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  CompetitionBracketResource,
  EmbeddedEvent,
  MatchState
} from '@rallycry/api-suite-typescript'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import { CompetitionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionResource'
import { IdentifiableDisplay } from '@rallycry/api-suite-typescript/dist/models/IdentifiableDisplay'
import { NamedIdentifiable } from '@rallycry/api-suite-typescript/dist/models/NamedIdentifiable'
import { PublicMatch } from '@rallycry/social-api-typescript'
import { MatchRoundInfo } from './MatchRoundInfo'
import { MatchTeamDisplay, TeamText } from './MatchTeamDisplay'

interface MatchDetailDisplayProps {
  bracket?: CompetitionBracketResource
  competition?: CompetitionResource
  event?: Omit<EmbeddedEvent, 'timeZone'>
  extraContext?: boolean
  fullScreen?: boolean
  match?: BracketMatchResource
  ladderMatch?: PublicMatch
  team1?: NamedIdentifiable & { alternateName?: string }
  team2?: NamedIdentifiable & { alternateName?: string }
  community1?: IdentifiableDisplay
  community2?: IdentifiableDisplay
  score1?: number | string
  score2?: number | string
  isMatchComplete?: boolean
  smallSize?: boolean
}

export const MatchDetailDisplay = ({
  bracket,
  competition,
  event,
  extraContext,
  fullScreen,
  match,
  ladderMatch,
  team1,
  team2,
  community1,
  community2,
  score1 = 0,
  score2 = 0,
  isMatchComplete,
  smallSize
}: MatchDetailDisplayProps) => {
  const { getPath, navTo } = useNavigation()
  const isLgDown = useIsLgDown()

  const handleClick = () => {
    navTo({
      root: RootRoute.Competition,
      rootId: competition?.id,
      subRoute: CompetitionRoute.Match,
      subId: match?.id
    })
  }

  const team1Wins =
    (!!match?.winner?.id &&
      match?.winner?.id === match?.assignments?.[0]?.id) ||
    score1 > score2
  const team2Wins =
    (!!match?.winner?.id &&
      match?.winner?.id === match?.assignments?.[1]?.id) ||
    score2 > score1

  if (match?.forfeit) {
    if (team2Wins) score1 = 'F'
    if (team1Wins) score2 = 'F'
  }

  const matchChip = <MatchStatusChip match={match} />
  if (extraContext && isLgDown) {
    return (
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        onClick={handleClick}
        sx={
          isLgDown
            ? { height: '100%', p: 2, cursor: 'pointer' }
            : {
                height: '100%',
                py: 1,
                marginX: -1,
                cursor: 'pointer'
              }
        }
      >
        <Grid item xs>
          <AvatarText src={competition?.image} variant='rounded' size='xl'>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Typography variant='h3' color='text.primary'>
                  {competition?.name}
                </Typography>
              </Grid>
              <Grid item>{matchChip}</Grid>
            </Grid>
          </AvatarText>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Box
        onClick={fullScreen ? undefined : handleClick}
        sx={{
          cursor: fullScreen ? 'default' : 'pointer'
        }}
      >
        <Stack direction='row' justifyContent='space-between'>
          <MatchTeamDisplay
            competition={competition}
            extraContext={extraContext}
            team={team1}
            community={community1}
            score={score1}
            isWinner={team1Wins}
            isMatchComplete={isMatchComplete}
            rating={ladderMatch?.entrants?.[0]?.rating}
            ratingChange={ladderMatch?.entrants?.[0]?.ratingChange}
            smallSize={smallSize}
          />
          {smallSize ? null : (
            <Stack
              display='flex'
              direction='column'
              zIndex={1}
              alignItems='center'
              justifyContent='center'
              flexGrow={1}
              height={{ xs: 70, sm: 70 }}
            >
              {extraContext && !smallSize ? (
                <PreventParentClick>
                  <NavigationLink
                    color='text.secondary'
                    variant='body2'
                    to={getPath({
                      root: RootRoute.Competition,
                      rootId: competition?.id
                    })}
                  >
                    <Typography
                      variant='h4'
                      mt={4}
                      maxWidth={180}
                      sx={{
                        textWrap: 'pretty',
                        textAlign: 'center'
                      }}
                    >
                      {competition?.name}
                    </Typography>
                  </NavigationLink>
                </PreventParentClick>
              ) : (
                <MatchRoundInfo
                  vertical
                  bracket={bracket}
                  match={match}
                  competition={competition}
                  event={event}
                />
              )}
            </Stack>
          )}
          <MatchTeamDisplay
            isMatchComplete={isMatchComplete}
            competition={competition}
            extraContext={extraContext}
            team={team2}
            community={community2}
            score={score2}
            isWinner={team2Wins}
            rightAlign
            rating={ladderMatch?.entrants?.[1]?.rating}
            ratingChange={ladderMatch?.entrants?.[1]?.ratingChange}
            smallSize={smallSize}
          />
        </Stack>
        {smallSize ? (
          <Stack direction='row' alignItems='stretch'>
            <Box width='50%'>
              <TeamText
                competition={competition}
                team={team1}
                community={community1}
                score={score1}
                isWinner={team1Wins}
                isMatchComplete={isMatchComplete}
                rating={ladderMatch?.entrants?.[0]?.rating}
                ratingChange={ladderMatch?.entrants?.[0]?.ratingChange}
              />
            </Box>
            <Box
              width='50%'
              sx={{
                textAlign: 'right'
              }}
            >
              <TeamText
                competition={competition}
                team={team2}
                community={community2}
                score={score2}
                isWinner={team2Wins}
                rightAlign
                isMatchComplete={isMatchComplete}
                rating={ladderMatch?.entrants?.[1]?.rating}
                ratingChange={ladderMatch?.entrants?.[1]?.ratingChange}
              />
            </Box>
          </Stack>
        ) : null}
      </Box>
    )
  }
}

export const MatchStatusChip = ({
  match
}: {
  match?: Omit<BracketMatchResource, 'state'> & {
    state?: MatchState | 'Escalated'
  }
}) => {
  const toolTipString = () => {
    if (match?.state === 'Escalated') {
      return match.dateConflicted?.toDateString()
    }
    if (match?.state === MatchState.COMPLETE) {
      return match.dateResolved?.toDateString()
    }
  }

  return (
    <Tooltip title={toolTipString()}>
      <Chip
        size='small'
        color={
          match?.state === 'Escalated'
            ? 'error'
            : match?.state === MatchState.PENDING
              ? 'warning'
              : match?.state === MatchState.COMPLETE
                ? 'success'
                : 'primary'
        }
        label={
          <>
            {match?.state === 'Escalated' ? (
              <RcTrans i18nKey='competition:MatchState.Escalated' />
            ) : match?.state === MatchState.PENDING ? (
              <RcTrans i18nKey='competition:MatchState.Pending' />
            ) : match?.state === MatchState.COMPLETE ? (
              <RcTrans i18nKey='competition:MatchState.Complete' />
            ) : (
              <RcTrans i18nKey='competition:MatchState.Open' />
            )}
          </>
        }
      />
    </Tooltip>
  )
}
