import { MatchState } from '@rallycry/api-suite-typescript/dist/models/MatchState'
import { orderBy } from 'lodash-es'
import { useEffect } from 'react'
import { ComponentDynamicMatchTicker } from '../../../../../models/types'
import { MatchTickerDetail } from './MatchTickerDetail'
import { useBracketMatchesByRound } from '@/entity/bracket-match/useBracketMatchesByRound'
import { CardsSection } from '@/components/organisms/card/CardsSection'

export const MatchTicker = ({
  bracketId,
  matches: cmsMatches,
  skipLink,
  padding,
  updateInterval = 0
}: Omit<ComponentDynamicMatchTicker, 'id'> & {
  updateInterval?: number
  padding?: number
  skipLink?: boolean
}) => {
  const {
    matches: bracketMatches,
    query: { mutate }
  } = useBracketMatchesByRound({
    idOrKey: bracketId || 0,
    paused: !bracketId,
    includeGames: true,
    request: { state: [MatchState.OPEN, MatchState.COMPLETE] }
  })

  useEffect(() => {
    if (updateInterval <= 0) return
    const interval = setInterval(mutate, updateInterval)
    return () => clearInterval(interval)
  }, [updateInterval, mutate])

  const translated = bracketMatches?.map(
    bracketMatch =>
      ({
        bracketMatch
      }) as any
  )

  return (
    <CardsSection
      cardItems={orderBy(
        [...(translated || []), ...(cmsMatches || [])],
        'matchTime'
      )}
      render={item => (
        <MatchTickerDetail
          key={item?.id}
          skipLink={skipLink}
          padding={padding}
          {...item!}
        />
      )}
      align='flex-start'
      interval={3}
      size={250}
      spacing={0}
    />
  )
}
