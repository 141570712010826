import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { BracketSubmissionCreateCommand } from '@rallycry/api-suite-typescript/dist/models/BracketSubmissionCreateCommand'
import { BracketSubmissionResource } from '@rallycry/api-suite-typescript/dist/models/BracketSubmissionResource'
import { MathSign } from '@rallycry/api-suite-typescript/dist/models/MathSign'
import { SubmissionScoreResource } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreResource'
import { Form, Formik, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'
import dynamic from 'next/dynamic'
import { RcErrorDisplay } from '@/components/atoms/RcErrorDisplay'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcTextInput } from '@/components/molecules/input/RcTextInput'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { SubmissionScoreDetails } from '@/components/pages/Administration/components/scores/SubmissionScoreDetails'
import { useBracket } from '@/entity/bracket/useBracket'
import { useBracketScoreSettings } from '@/entity/leaderboard/useBracketScoreSettings'
import { useBracketSubmissionDocument } from '@/entity/leaderboard/useBracketSubmissionDocument'
import { useApiError } from '@/core/hooks/useApiError'

const RcFileInput = dynamic(
  async () =>
    (await import('@/components/molecules/input/RcFileInput')).RcFileInput,
  { ssr: false, loading: () => <></> }
)

const getScoreYup = (score?: SubmissionScoreResource) => {
  const isAny = score?.mathSign === MathSign.ANY
  const allowZero =
    isAny ||
    score?.mathSign === MathSign.POSITIVEORZERO ||
    score?.mathSign === MathSign.NEGATIVEORZERO
  const isPositive =
    score?.mathSign === MathSign.POSITIVE ||
    score?.mathSign == MathSign.POSITIVEORZERO

  let result = Yup.number()

  const actualMin = allowZero ? 0 : 1
  const minSetting = score?.min
  const maxSetting = score?.max

  if (minSetting || (isPositive && !isAny)) {
    result = result.min(minSetting || actualMin)
  }

  if (maxSetting || (!isPositive && !isAny)) {
    result = result.max(maxSetting || -actualMin)
  }

  if (!score?.optional) {
    result = result.required()
  }

  return result.notRequired()
}

export const LeaderboardScoreForm = ({
  assignmentId,
  ordinal,
  initial,
  onComplete
}: {
  assignmentId: number
  ordinal: number
  initial?: BracketSubmissionResource
  onComplete: () => void
}) => {
  const { bracket, bracketSettings } = useBracket()
  const {
    settings: { scoreA, scoreB, scoreC, scoreD, scoreE }
  } = useBracketScoreSettings()

  const { create: submitLeaderboardScore, update } =
    useBracketSubmissionDocument({
      request: { assignment: assignmentId }
    })
  const { handle } = useApiError()

  const validation = Yup.object<BracketSubmissionCreateCommand>({
    description: bracketSettings?.submissionInquiry
      ? Yup.string().required()
      : Yup.string(),
    ordinal: Yup.number(),
    scoreInputA: getScoreYup(scoreA),
    scoreInputB: getScoreYup(scoreB),
    scoreInputC: getScoreYup(scoreC),
    scoreInputD: getScoreYup(scoreD),
    scoreInputE: getScoreYup(scoreE)
  })

  const submit = async (
    values: BracketSubmissionCreateCommand,
    helpers: FormikHelpers<BracketSubmissionCreateCommand>
  ) => {
    try {
      initial
        ? await update(initial?.id!, { ...values, points: undefined })
        : await submitLeaderboardScore({
            ...values,
            assignmentId: assignmentId,
            ordinal
          })
      onComplete()
    } catch (error) {
      await handle(error, { values, helpers })
    }
  }

  return (
    <Formik
      initialValues={
        initial
          ? { ...initial, ordinal: initial.ordinal || ordinal }
          : ({
              ordinal,
              description: '',
              scoreInputA: '' as any,
              scoreInputB: '' as any,
              scoreInputC: '' as any,
              scoreInputD: '' as any,
              scoreInputE: '' as any
            } as BracketSubmissionCreateCommand)
      }
      validationSchema={validation}
      onSubmit={submit}
    >
      {({ values, setFieldValue, isSubmitting, dirty }) => (
        <Form id='leaderboard-score-form'>
          <Grid container direction='row' spacing={3}>
            {[
              { field: 'scoreInputA', score: scoreA },
              { field: 'scoreInputB', score: scoreB },
              { field: 'scoreInputC', score: scoreC },
              { field: 'scoreInputD', score: scoreD },
              { field: 'scoreInputE', score: scoreE }
            ]
              .filter(it => !!it.score)
              .map(it => (
                <Grid key={it?.score?.id} item xs={12}>
                  <LabeledField
                    component={TextField}
                    name={it.field}
                    type='number'
                    variant='outlined'
                    label={it?.score?.name || it.field}
                    info={
                      <>
                        <Typography>{it?.score?.description}</Typography>
                        <SubmissionScoreDetails
                          score={it.score!}
                          bonuses={
                            bracket?._expanded?.competitionSubmissionScoreBonus
                          }
                        />
                      </>
                    }
                  />
                </Grid>
              ))}

            {bracketSettings?.submissionInquiry ? (
              <Grid item xs={12}>
                <RcTextInput
                  property='description'
                  source={{ description: values.description || '' }}
                  update={async value => {
                    setFieldValue('description', value.description)
                  }}
                  label={bracketSettings?.submissionInquiry}
                  startIcon={['fal', 'link']}
                  url
                  defaultValue=''
                />
                <RcFileInput
                  source={values.description}
                  saveAs={`assignment-${assignmentId}-${values?.ordinal}`}
                  onComplete={async (url: string) => {
                    setFieldValue('description', url)
                  }}
                  onClear={async () => setFieldValue('description', '')}
                />
                <RcErrorDisplay name='description' />
              </Grid>
            ) : null}
          </Grid>

          <ModalConfiguration
            title={
              <RcTrans i18nKey='competition:leaderboard.submit-score-title' />
            }
            subtitle={
              <RcTrans
                i18nKey='competition:leaderboard.submission-details'
                tOptions={{
                  count: bracketSettings?.submissionMax,
                  top: bracketSettings?.submissionTopIncluded
                }}
              />
            }
          >
            <RcButton
              variant='contained'
              fullWidth
              type='submit'
              disabled={isSubmitting || !dirty}
              form='leaderboard-score-form'
            >
              <RcTrans i18nKey='shared.submit' />
            </RcButton>
          </ModalConfiguration>
        </Form>
      )}
    </Formik>
  )
}
