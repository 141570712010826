import Grid from '@mui/material/Grid'

import { MatchResultsMachine } from './match-results-machine'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTrans } from '@/components/atoms/RcTrans'

export const MatchResultFlowActions = ({
  machine,
  title,
  subtitle,
  topContent
}: {
  machine: MatchResultsMachine
  title: React.ReactNode
  subtitle?: React.ReactNode
  topContent?: React.ReactNode
}) => {
  const [state, send] = machine
  const { bracketAssignment1, bracketAssignment2 } = state.context

  // collection of all possible button actions for state machine and when/how to render them
  const actions = [
    {
      xs: 4,
      guard: () => state.can('SET_WINNER'),
      action: () =>
        send('SET_WINNER', {
          winner: bracketAssignment1?.id
        }),
      text: () => <RcTrans i18nKey='competition:match.results-left-wins' />
    },
    {
      xs: 4,
      guard: () => state.can('SET_WINNER'),
      action: () => send('SET_TIE'),
      text: () => <RcTrans i18nKey='competition:match.results-tie' />
    },
    {
      xs: 4,
      guard: () => state.can('SET_WINNER'),
      action: () =>
        send('SET_WINNER', {
          winner: bracketAssignment2?.id
        }),
      text: () => <RcTrans i18nKey='competition:match.results-right-wins' />
    },
    {
      guard: () => state.can('CONFLICT'),
      action: () => send('CONFLICT'),
      text: () => (
        <RcTrans i18nKey='competition:match.results-start-conflict' />
      ),
      isError: true
    },
    {
      guard: () => state.can('DECLINE'),
      action: () => send('DECLINE'),
      text: () => <RcTrans i18nKey='competition:match.results-request-admin' />,
      isError: true
    },
    {
      guard: () => state.can('ACCEPT'),
      action: () => send('ACCEPT'),
      text: () => <RcTrans i18nKey='competition:match.results-accept' />
    },
    {
      guard: () => state.can('RESET_MATCH'),
      action: () => send('RESET_MATCH'),
      text: () => <RcTrans i18nKey='competition:match.reset-match' />,
      isError: true
    },
    {
      guard: () => state.matches('sourcedGames'),
      action: () => send('SKIP_SOURCED_GAMES'),
      text: () => (
        <RcTrans i18nKey='competition:match.results-manually-submit' />
      )
    },
    {
      guard: () => state.can('BACK_TO_SOURCED_GAMES'),
      action: () => send('BACK_TO_SOURCED_GAMES'),
      text: () => <RcTrans i18nKey='competition:match.back-to-sourced-games' />
    },
    {
      guard: () =>
        state.matches('reportScores') &&
        state.context.isParticipant &&
        !state.context.isBothTeams,
      action: () => send('REPORT_SCORES'),
      text: () => (
        <RcTrans i18nKey='competition:match.results-complete-submit' />
      ),
      disabled: () => !state.can('REPORT_SCORES')
    },
    {
      guard: () =>
        state.matches('reportScores') &&
        (state.context.isAdmin || state.context.isBothTeams),
      action: () => send('FORCE_SCORES'),
      text: () =>
        state.context.isBothTeams ? (
          <RcTrans i18nKey='competition:match.results-complete-submit' />
        ) : (
          <RcTrans i18nKey='competition:match.results-force-submit' />
        ),
      disabled: () => !state.can('FORCE_SCORES')
    },
    {
      guard: () =>
        state.can('ADMIN_REPORT_GAMES') && !state.matches('reportScores'),
      action: () => send('ADMIN_REPORT_GAMES'),
      text: () => (
        <RcTrans i18nKey='competition:match.enter-admin-game-results' />
      ),
      disabled: () => !state.can('ADMIN_REPORT_GAMES')
    },
    {
      guard: () => state.matches('sourcedGames'),
      action: () => send('REPORT_SOURCED_GAMES'),
      text: () => (
        <RcTrans i18nKey='competition:match.results-complete-submit' />
      ),
      disabled: () => !state.can('REPORT_SOURCED_GAMES')
    }
  ]

  return (
    <ModalConfiguration
      title={title}
      subtitle={subtitle}
      topContent={topContent}
    >
      <Grid container direction='row' spacing={3}>
        {actions
          .filter(it => it.guard())
          .map((it, idx) => (
            <Grid item xs={it.xs || 12} key={idx}>
              <RcButton
                onClick={() => {
                  it.action()
                }}
                variant='contained'
                fullWidth
                disabled={it.disabled?.()}
              >
                {it.text()}
              </RcButton>
            </Grid>
          ))}
      </Grid>
    </ModalConfiguration>
  )
}
