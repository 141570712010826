import MenuItem from '@mui/material/MenuItem'
import { orderBy, shuffle } from 'lodash-es'
import { FormResponseEditProps } from '../FormResponseQuestionEdit'
import { RcSelect } from '@/components/molecules/input/RcSelect'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'

export const FormResponseEditSelect = ({
  responseId,
  question
}: FormResponseEditProps) => {
  const { response, answers, createFormAnswer, updateFormAnswer } =
    useFormResponse({ idOrKey: responseId })

  const answer = answers?.find(it => it.question?.id === question?.id)
  const value =
    answer?.option?.id ||
    // backwards compat for selects done the old/wrong way
    (answer?.value ? Number(answer?.value) : undefined)

  const formOptions = response?._expanded?.formOption?.filter(
    it => question.options?.map(o => o.id)?.includes(it.id)
  )

  const orderedOptions = question.shuffle
    ? orderBy(shuffle(formOptions), ['isOther'], ['desc'])
    : orderBy(formOptions, ['ordinal', 'isOther'], ['asc', 'desc'])

  return (
    <RcSelect // disable form answer while fake id -1 during creation
      disabled={answer?.id === -1}
      allowNone={!question.required}
      property='value'
      source={{
        value: value
      }}
      update={val =>
        answer
          ? updateFormAnswer(answer?.id!, { option: val.value })
          : createFormAnswer({
              question: question.id!,
              option: val.value
            })
      }
    >
      {orderedOptions.map(it => (
        <MenuItem key={it.id} value={it.id}>
          {it.name}
        </MenuItem>
      ))}
    </RcSelect>
  )
}
