'use client'

import Stack from '@mui/material/Stack'
import { Box } from '@mui/system'
import { last } from 'lodash-es'
import { useRouter } from 'next/navigation'
import { Fragment, useEffect, useMemo, useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { TwitchEmbed } from 'react-twitch-embed'
import { HeroBox } from '../Landing/components/HeroBox'
import { HeroBoxes } from '../Landing/components/HeroBoxes'
import { NotFound } from '../Site/NotFound'
import { Avatars } from './dynamic/Avatars'
import { CompetitionBracket } from './dynamic/CompetitionBracket'
import { CompetitionFinder } from './dynamic/CompetitionFinder'
import { FrequentlyAskedQuestions } from './dynamic/FrequentlyAskedQuestions'
import { Gallery } from './dynamic/Gallery'
import { IFrameEmbed } from './dynamic/IFrameEmbed'
import { ManualLeaderboard } from './dynamic/ManualLeaderboard'
import { ManyMiniBoxes } from './dynamic/ManyMiniBoxes'
import { MatchTicker } from './dynamic/MatchTicker'
import { MediaEmbed } from './dynamic/MediaEmbed'
import { Quotes } from './dynamic/Quotes'
import { Redirect } from './dynamic/Redirect'
import { SideBySide } from './dynamic/SideBySide'
import { Tweets } from './dynamic/Tweets'
import { TwitchChannel } from './dynamic/TwitchChannel'
import { ImageSize, useCMSFile } from '@/core/hooks/useCMSFile'
import {
  DEFAULT_STRAPI_RC_RESOURCE,
  useFeatures
} from '@/components/providers/site/FeatureProvider'
import { ErrorFallback } from '@/components/organisms/site/ErrorFallback'
import { PageItem } from '@/components/organisms/page/PageItem'
import { PageHeader } from '@/components/organisms/page/PageHeader'
import { Page } from '@/components/organisms/page/Page'
import { RecentNews } from '@/components/organisms/news/RecentNews'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { MarkdownDisplay } from '@/components/molecules/input/MarkdownDisplay'
import { useIsMainLayout } from '@/core/hooks/useLayoutQueries'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useOrganization } from '@/entity/organization/useOrganization'
import { StrapiResult } from '@/entity/site/useSearchDocument'
import { TEXT_PALETTE } from '@/style/palette'
interface DocumentPageProps {
  doc?: StrapiResult
  skipHeader?: boolean
  unauthFullWidth?: boolean
  withItems?: boolean
}

export const DocumentPage = ({
  doc,
  skipHeader,
  unauthFullWidth,
  withItems
}: DocumentPageProps) => {
  const isMainLayout = useIsMainLayout()
  const fullWidthPage = unauthFullWidth ? !isMainLayout : false
  const router = useRouter()
  const { i18n } = useRcTranslation()
  const { isOrgLimitedAdmin } = useOrganization()
  const {
    imgPageBanner,
    updateCfg,
    cfgPinnedHomeResources,
    cfgPartnerResource
  } = useFeatures()
  const { getImagePath } = useCMSFile()

  const defaultBannerImageSrc = imgPageBanner as string

  const ref = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    const handle = (e: KeyboardEvent) => {
      if (e.key === '`') {
        ref.current?.click?.()
      }
    }
    document.addEventListener('keydown', handle, false)

    return () => {
      document.removeEventListener('keydown', handle, false)
    }
  }, [])

  // always refresh when admins view to workaround stale-while-revalidate on vercel cache
  const routerRefresh = router.refresh
  useEffect(() => {
    isOrgLimitedAdmin && routerRefresh()
  }, [isOrgLimitedAdmin, routerRefresh])

  // the id key is prepended with engine name when going through a meta engine
  // to dedupe docs from all of the merged engines
  const key: string =
    cfgPartnerResource === DEFAULT_STRAPI_RC_RESOURCE
      ? `${doc?.id}`
      : `strapi-${doc?.model}|${doc?.id}`
  const current = JSON.parse(cfgPinnedHomeResources as string) as string[]
  const isPinned = current.includes(key)

  const togglePinned = () => {
    if (!doc?.id || !doc.model) return

    updateCfg(
      'cfgPinnedHomeResources',
      JSON.stringify(
        isPinned ? current.filter(it => it !== key) : [key, ...current]
      )
    )
  }

  const refresh = async () => {
    await fetch('/api/revalidate-tag', {
      method: 'POST',
      body: JSON.stringify({ key: doc?.key })
    })
    router.refresh()
  }

  const bannerImageSrc = getImagePath(
    doc?.attributes?.background?.data || undefined,
    ImageSize.Large
  )

  const content = useMemo(() => {
    const components = doc?.attributes?.content
    const grouped = components?.reduce(
      (acc, cur) => {
        const previous: any = last(acc)
        if (
          // explicitly merge components
          (cur.mergeWithPrevious && previous) ||
          // or, by convention, group sequential heroboxes up
          (cur.__component === 'dynamic.hero-box' &&
            previous?.__component === 'dynamic.hero-box')
        ) {
          previous.content.push(cur)
          return [...acc]
        } else {
          return [
            ...acc,
            { id: cur.id, __component: cur.__component, content: [cur] }
          ]
        }
      },
      [] as { id: string; __component: string; content: typeof components }[]
    )

    /* 
      withItems -> each grouping gets a page item wrapper
      otherwise they all go into 1 
    */
    const content = withItems ? (
      grouped?.map((grouping, idx) => (
        <Fragment key={grouping.id + grouping.__component}>
          {getContent({
            component: grouping,
            idx,
            withItem: true,
            fullWidthPage: fullWidthPage
          })}
        </Fragment>
      ))
    ) : (
      <PageItem contained={false}>
        <Stack direction='column' spacing={5}>
          {grouped?.map((grouping: any, idx: number) => (
            <Box key={grouping?.id + grouping.__component}>
              {getContent({
                component: grouping,
                idx,
                fullWidthPage: fullWidthPage
              })}
            </Box>
          ))}
        </Stack>
      </PageItem>
    )
    return content
  }, [doc?.attributes?.content, withItems, fullWidthPage])

  const cmsLink = `https://cms.rallycry.gg/admin/content-manager/collection-types/api::${doc?.model}.${doc?.model}/${doc?.id}?plugins[i18n][locale]=${i18n.language}`
  return (
    <Page unauthFullWidth={unauthFullWidth}>
      {skipHeader ? null : (
        <PageHeader
          name={doc?.attributes?.title}
          description={doc?.attributes?.subtitle}
          ImageDisplayProps={{
            path: bannerImageSrc || defaultBannerImageSrc || ''
          }}
        />
      )}
      {doc?.attributes ? content : <NotFound />}
      {isOrgLimitedAdmin ? (
        <Stack
          direction='row'
          alignItems='center'
          spacing={3}
          sx={theme => ({
            position: 'fixed',
            bottom: 15,
            right: 15,
            zIndex: theme.zIndex.modal,
            // glass background effect
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            borderRadius: 1,
            boxShadow: 1,
            padding: 1
          })}
        >
          {doc?.pinnable ? (
            <RcIconButton
              icon={
                isPinned ? ['fal', 'map-marker-check'] : ['fal', 'map-marker']
              }
              onClick={togglePinned}
              TooltipProps={{
                title: isPinned ? 'Pinned to home' : 'Pin to home'
              }}
            />
          ) : null}
          <RcIconButton
            disabled={!doc?.id}
            icon={['fal', 'link']}
            onClick={() => window.open(cmsLink)}
            TooltipProps={{
              title: !doc?.id
                ? `No ID for ${doc?.key}. Try refreshing.`
                : 'Edit in new tab'
            }}
          />
          <ModalTrigger
            modalProps={{
              fullScreen: true,
              noPadding: true,
              noFooter: true,
              keepMounted: true
            }}
            onClose={refresh}
            activation={open => (
              <RcIconButton
                disabled={!doc?.id}
                ref={ref}
                onClick={open}
                icon={['fal', 'pencil']}
                TooltipProps={{
                  title: !doc?.id
                    ? `No ID for ${doc?.key}. Try refreshing.`
                    : 'Edit in popup'
                }}
              />
            )}
          >
            {() => (
              <iframe
                src={cmsLink}
                style={{ width: '100%', height: 'calc(100dvh - 56px)' }}
              />
            )}
          </ModalTrigger>
          <RcIconButton
            icon={['fal', 'refresh']}
            onClick={refresh}
            TooltipProps={{
              title: 'Refresh page cache'
            }}
          />
        </Stack>
      ) : null}
    </Page>
  )
}

export const getContent = ({
  component,
  idx,
  withItem,
  fullWidthPage
}: {
  component: {
    id: string
    __component: string
    content: any[] //KnownStrapiTypes
    fillContent?: boolean
  }
  fullWidthPage?: boolean
  idx: number
  withItem?: boolean
}) => {
  const getInner = (content: any, innerIdx: number, skipPadding?: boolean) => {
    const renderers = {
      'dynamic.hero-box': () => <HeroBox {...content} />,
      'dynamic.side-by-side': () => (
        <SideBySide
          {...content}
          isFlipped={innerIdx % 2 === 1}
          fullWidthPage={fullWidthPage}
        />
      ),
      'dynamic.many-mini-boxes': () => <ManyMiniBoxes {...content} />,
      'dynamic.recent-news': () => <RecentNews limit={6} slideCards />,
      'dynamic.twitch-vod': () => (
        <TwitchEmbed
          channel={content.identifier}
          autoplay
          darkMode={true}
          withChat={false}
          height={800}
          width='100%'
        />
      ),
      'dynamic.twitch-channel': () => <TwitchChannel {...content} />,
      'dynamic.tweets': () => <Tweets {...content} />,
      'dynamic.gallery': () => <Gallery {...content} />,
      'dynamic.media-embed': () => <MediaEmbed {...content} />,
      'dynamic.markdown': () => (
        <MarkdownDisplay markdown={content?.markdown} />
      ),
      'dynamic.competition-bracket': () => <CompetitionBracket {...content} />,
      'dynamic.manual-leaderboard': () => <ManualLeaderboard {...content} />,
      'dynamic.competition-finder': () => <CompetitionFinder {...content} />,
      'dynamic.faq': () => (
        <FrequentlyAskedQuestions
          {...content}
          isFlipped={withItem && innerIdx % 2 === 1}
        />
      ),
      'dynamic.avatars': () => <Avatars {...content} />,
      'dynamic.quotes': () => <Quotes {...content} />,
      'dynamic.redirect': () => <Redirect {...content} />,
      'dynamic.i-frame-embed': () => <IFrameEmbed {...content} />,
      'dynamic.you-tube-embed': () => <IFrameEmbed {...content} isYouTube />,
      'dynamic.match-ticker': () => <MatchTicker {...content} />
    }
    const typeKey = (component.__component ||
      'dynamic.side-by-side') as keyof typeof renderers

    return <Box py={skipPadding ? 0 : 5}>{renderers[typeKey]?.()}</Box>
  }

  const firstContent = component.content[0]

  const inner = () =>
    component.content.length > 1
      ? component.content.map((it, innerIdx) => getInner(it, idx + innerIdx))
      : getInner(
          firstContent,
          idx,
          !withItem || containTypes.includes(component.__component)
        )
  const swipeTypes = ['dynamic.hero-box']
  const containTypes = ['dynamic.match-ticker', 'dynamic.media-embed']
  const escapeTypes = [
    'dynamic.match-ticker',
    fullWidthPage ? 'dynamic.avatars' : null
  ]
  const wrapped = swipeTypes.includes(component.__component) ? (
    <HeroBoxes content={component.content} />
  ) : (
    <PageItem
      contained={
        !containTypes.includes(component.__component) &&
        component.fillContent !== true
      }
      escapeContainer={escapeTypes.includes(component.__component)}
      color={getTextColor(firstContent?.background?.mode)}
      backgroundColor={
        withItem
          ? firstContent?.background?.color ||
            firstContent?.backgroundColor ||
            (idx % 2 === 1 ? 'paper' : 'default')
          : 'default'
      }
      backgroundImage={
        firstContent?.background?.image || firstContent?.backgroundImage
      }
      backgroundImagePosition={firstContent?.background?.position}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>{inner()}</ErrorBoundary>
    </PageItem>
  )

  return wrapped
}

export const getTextColor = (
  colorMode: 'dynamic' | 'dark' | 'light' | undefined
): string => {
  let textColor = 'inherit'
  switch (colorMode) {
    case 'dark':
      textColor = TEXT_PALETTE.primary.dark
      break
    case 'light':
      textColor = TEXT_PALETTE.primary.light
      break
  }
  return textColor
}

export const getFileSizeString = (size: number): string => {
  if (size > 1000) {
    return `${(size / 1000).toFixed(2)}MB`
  }
  return `${size.toFixed(0)}KB`
}
