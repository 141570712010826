import { useFormikContext } from 'formik'
import { isArray } from 'lodash-es'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { CommunityDocument } from '@rallycry/api-suite-typescript'
import { EntityAutocomplete } from './EntityAutocomplete'
import { useCommunityDocument } from '@/entity/community/useCommunityDocument'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

export const FormikOrgCommunityPicker = ({
  name,
  onChanged
}: {
  name: string
  onChanged: (doc: CommunityDocument | null | undefined) => void
}) => {
  const { t } = useRcTranslation()
  const { values, setFieldValue, getFieldMeta } = useFormikContext<any>()
  const [query, setQuery] = useState<string | undefined>(undefined)
  const [debouncedFilter] = useDebounce(query, 300)
  const {
    communities,
    query: { isValidating, size, setSize }
  } = useCommunityDocument({
    request: { q: debouncedFilter ? debouncedFilter : undefined }
  })

  const { touched, error } = getFieldMeta(name)

  return (
    <EntityAutocomplete
      error={touched ? error : ''}
      isValidating={isValidating}
      options={communities}
      selected={values[name] ? [values[name]] : []}
      placeholder={t('shared.competition-finder.search-placeholder')}
      onSearch={q => setQuery(q)}
      onLoadOptions={() => setSize(size + 1)}
      onChange={async updated => {
        const community = isArray(updated) ? undefined : updated
        setFieldValue(name, community?.id)
        onChanged(community)
      }}
    />
  )
}
