import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { useCompetitionActions } from '@/entity/competition/useCompetitionActions'

export const CheckInConfirm = ({
  checkinId,
  entryId
}: {
  checkinId: number
  entryId: number
}) => {
  const { checkin } = useCompetitionActions()

  const handleCheckin = async () => {
    await checkin(checkinId, entryId, false)
  }

  return (
    <RcButton
      size='small'
      variant='contained'
      onClick={handleCheckin}
      sx={{ whiteSpace: 'nowrap' }}
    >
      <RcTrans i18nKey='competition:issues.check-in' />
    </RcButton>
  )
}
