import { isString, some } from 'lodash-es'
import useSWRInfinite from 'swr/infinite'
import { useOrganization } from '../organization/useOrganization'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

interface SearchRequest {
  ids?: string[]
  engine?: string
  query?: string
  slug?: string
  tags?: string[]
  limit?: number
  omit?: string
  showExcluded?: boolean
}

interface SearchResult {
  facets?: {
    tags: {
      name: string
      type: string
      data: { count: number; value: string }[]
    }[]
  }
  meta?: {
    request_id: string
    page: {
      current: number
      total_pages: number
      total_results: number
      size: number
    }
  }
  results?: {
    id?: { raw?: string }
    model?: { raw?: string }
    slug?: { raw?: string }
    title?: { raw?: string }
    subtitle?: { raw?: string }
    description?: { raw?: string }
    preview_url?: { raw?: string }
    tags?: { raw?: string[] }
  }[]
}

const RAW = {
  raw: {}
}

export const useSearchDocuments = (request?: SearchRequest) => {
  const { cfgSearchToken, elasticApi, setElasticFallback } = useFeatures()
  const {
    i18n: { language }
  } = useRcTranslation()
  const { orgKey } = useOrganization()

  const documents = useSWRInfinite(
    idx => (request ? [elasticApi, { ...request, page: idx }, language] : null),
    async ([api, params, locale]) => {
      try {
        if (isString(params)) return
        const { ids, query, engine, slug, tags, page, limit, omit } = params
        const filters = []
        some(ids) && filters.push({ any: ids?.map(id => ({ id })) })
        slug && filters.push({ any: [{ slug }] })
        omit && filters.push({ none: [{ slug: omit }] })
        some(tags) && filters.push({ any: tags?.map(it => ({ tags: it })) })
        filters.push({ any: [{ locale }] })
        !request?.showExcluded && filters.push({ none: [{ exclude: 'true' }] })

        // get for current org + all (unset) orgs
        orgKey &&
          filters.push({
            any: [{ organizations: 'all' }, { organizations: orgKey }]
          })

        const body = JSON.stringify({
          query: query || '',
          sort: query ? undefined : { published: 'desc' },
          filters: { all: filters },
          page: { size: limit || 6, current: page + 1 },
          facets: {
            tags: [
              {
                type: 'value',
                name: 'tag_counts',
                sort: { count: 'desc' },
                size: 50
              }
            ]
          },
          result_fields: {
            model: RAW,
            slug: RAW,
            title: RAW,
            subtitle: RAW,
            description: RAW,
            tags: RAW,
            preview_url: RAW,
            published: RAW,
            file_count: RAW,
            file_size: RAW,
            exclude: RAW
          }
        })

        const headers = {
          Authorization: `Bearer ${cfgSearchToken}`,
          ['Content-Type']: 'application/json'
        }

        const res = await fetch(`${api}${engine}/search.json`, {
          method: 'POST',
          headers,
          body
        })

        if (res.status === 200) {
          const json = (await res.clone().json()) as SearchResult
          return json
        }

        return {} as SearchResult
      } catch {
        setElasticFallback(true)
        return {} as SearchResult
      }
    },
    { suspense: true, revalidateOnFocus: false, keepPreviousData: true }
  )

  const trackClick = async (document_id: string, request_id: string) => {
    const headers = {
      Authorization: `Bearer ${cfgSearchToken}`,
      ['Content-Type']: 'application/json'
    }
    await fetch(`${elasticApi}${request?.engine}/click`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query: request?.query,
        request_id,
        document_id,
        tags: request?.tags?.map(it => it.replace(/ /g, '_')) // elastic doesnt like spaces
      })
    })
  }

  return { ...documents, trackClick }
}
