import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { TwitchEmbed } from 'react-twitch-embed'
import { ActionLink } from '../../Landing/components/ActionLink'
import { ComponentDynamicTwitchChannel } from '../../../../../models/types'
import { useIsMainLayout } from '@/core/hooks/useLayoutQueries'
import { useIsMobile } from '@/core/hooks/useMediaQueries'

export const TwitchChannel = (props: ComponentDynamicTwitchChannel) => {
  const isMainLayout = useIsMainLayout()
  const isMobile = useIsMobile()

  return (
    <Stack direction='column' spacing={5}>
      <Typography variant='h2' textAlign='center'>
        {props.title}
      </Typography>
      <Typography textAlign='center' paragraph>
        {props.subtitle}
      </Typography>
      <TwitchEmbed
        channel={props.identifier}
        autoplay
        darkMode={true}
        withChat={false}
        height={800}
        width='100%'
      />
      <Stack direction='row' spacing={3} justifyContent='center'>
        {props?.actions?.map(it => (
          <ActionLink
            key={it?.path}
            action={it!}
            ButtonProps={{
              size: isMainLayout || isMobile ? 'medium' : 'large'
            }}
          />
        ))}
      </Stack>
    </Stack>
  )
}
