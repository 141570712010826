import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models'
import { RcIcon } from '@/components/atoms/RcIcon'

export const CalendarKindIcon = ({
  kind,
  size = 'xs'
}: {
  kind: EventKind
  size?: SizeProp
}) => {
  const icon: IconProp =
    kind === EventKind.LADDER
      ? ['fal', 'line-height']
      : kind === EventKind.REGISTRATION
      ? ['fal', 'calendar-lines-pen']
      : kind === EventKind.ROSTERLOCK
      ? ['fal', 'lock']
      : kind === EventKind.CHECKIN
      ? ['fal', 'circle-check']
      : kind === EventKind.MATCH
      ? ['fal', 'swords']
      : kind === EventKind.DONATION
      ? ['fal', 'hands-holding-heart']
      : ['fal', 'calendar-day']

  return <RcIcon icon={icon} size={size} colorHex='text.secondary' />
}
