import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { EntryMemberRequestResource } from '@rallycry/api-suite-typescript/dist/models/EntryMemberRequestResource'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import { orderBy, some } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { TeamApplicationRow } from './TeamApplicationRow'
import { RcTrans } from '@/components/atoms/RcTrans'
import { NoResults } from '@/components/molecules/text/NoResults'
import { expander } from '@/core/expand'
import { useCompetitionTeamApplications } from '@/entity/competition-team/useCompetitionTeamApplications'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'

export const TeamApplications = () => {
  const { isSoloCompetition } = useCompetition()
  const {
    read: { mutate: mutateParticipant }
  } = useCompetitionParticipant()
  const {
    applications,
    accept,
    reject,
    query: { mutate: mutateApplications }
  } = useCompetitionTeamApplications()

  useEffect(() => {
    mutateApplications()
  }, [mutateApplications])

  // sort alphabetically by name if available, username if not
  const content = useMemo(
    () =>
      orderBy(
        applications,
        [
          it => {
            const user = expander<UserResource>(it, 'entryRequestAgent')

            return user?.givenName
              ? `${user?.givenName.toLocaleLowerCase()} ${user?.familyName?.toLocaleLowerCase()} `
              : user?.name?.toLocaleLowerCase()
          }
        ],
        ['asc']
      ),
    [applications]
  )

  const applicationsPending = some(applications)

  if (!applicationsPending || isSoloCompetition) return <NoResults />

  const infoTip = {
    content: <RcTrans i18nKey='competition:team.applications-header-help' />
  }

  return (
    <Table>
      <TableBody>
        {content.map((app: EntryMemberRequestResource) => (
          <TeamApplicationRow
            application={app}
            key={app.id}
            onAccept={async () => {
              await accept(app.id as number)
              await mutateParticipant()
            }}
            onReject={() => reject(app.id as number)}
          />
        ))}
      </TableBody>
    </Table>
  )
}
