import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { some } from 'lodash-es'
import { ComponentDynamicManualLeaderboard } from '../../../../../models/types'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { useReadEntity } from '@/entity/useEntity'
import { useIsMobile } from '@/core/hooks/useMediaQueries'

export const ManualLeaderboard = (props: ComponentDynamicManualLeaderboard) => {
  const id = props.spreadsheetId!
  const entity = useReadEntity({
    key: 'manual-bracket',
    idOrKey: id,
    expand: props.tabName!,
    read: req =>
      fetch(`https://opensheet.elk.sh/${req.idOrKey}/${req.expand}`).then(res =>
        res.json()
      )
  })

  const isMobile = useIsMobile()

  return (
    <Stack direction='column' spacing={5}>
      <Typography variant='h1' textAlign='center'>
        {props.title}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            {Object.keys(entity.read?.data?.[0]).map(it => (
              <TableCell key={it}>{it}</TableCell>
            ))}
          </TableHead>
          <TableBody>
            {entity.read?.data?.map((it: any, idx: number) => (
              <TableRow key={idx}>
                {Object.values(it).map((that: any, idx2: number) => {
                  const splits = that.split('\n')
                  const [first, second, ...rest] = splits
                  return (
                    <TableCell key={that} sx={{ whiteSpace: 'pre-line' }}>
                      {first?.startsWith('http') ? (
                        <AvatarText
                          variant='rounded'
                          src={splits[0]}
                          size={isMobile ? 'medium' : 'xl'}
                        >
                          {second ? (
                            <Typography variant='body1'>{second}</Typography>
                          ) : null}
                          {some(rest) ? (
                            <Stack direction='row' spacing={1}>
                              {rest.map((it: string) => (
                                <Chip key={it} label={it} color='primary' />
                              ))}
                            </Stack>
                          ) : null}
                        </AvatarText>
                      ) : (
                        <Typography variant={idx2 === 0 ? 'h3' : 'body1'}>
                          {that}
                        </Typography>
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
