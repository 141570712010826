import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { some } from 'lodash-es'

import { JoinFlowContext } from './join-flow-machine'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { HandleForm } from '@/components/organisms/handles/HandleForm'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useMyContactAccounts } from '@/entity/user/useMyContactAccounts'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'

export interface HandleProps {
  context: JoinFlowContext
  processing: boolean
  onHandleSet: () => void
  onBack?: () => void
}

export const SetHandle: React.FC<HandleProps> = ({
  context,
  processing,
  onHandleSet,
  onBack
}) => {
  const { getNetworkString } = useNetworkKinds()
  const loc = getNetworkString(context.network!)
  const { handles } = useMyContactAccounts()

  const handleUnset = !some(
    handles,
    it => it.network === context.network && it.handle
  )

  return (
    <>
      <Grid container direction='column' spacing={3}>
        <Grid item container direction='column' spacing={2}>
          <Grid item>
            <Typography variant='body0'>
              <RcTrans
                i18nKey='competition:join-flow.set-handle.handle-label'
                tOptions={{ network: loc }}
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2'>
              <RcTrans
                i18nKey='competition:join-flow.set-handle.handle-context'
                tOptions={{
                  network: loc
                }}
              />
            </Typography>
          </Grid>
          <Grid item>
            <HandleForm network={context.network!} />
          </Grid>
        </Grid>
      </Grid>
      <ModalConfiguration
        title={<RcTrans i18nKey='competition:join-flow.set-handle-title' />}
        subtitle={<RcTrans i18nKey='competition:join-flow.set-handle.intro' />}
        topAction={onBack}
      >
        <RcButton
          disabled={handleUnset || processing}
          variant='contained'
          onClick={onHandleSet}
          fullWidth
        >
          <RcTrans i18nKey='shared.looks-good' />
        </RcButton>
      </ModalConfiguration>
    </>
  )
}
