import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material'
import Stack from '@mui/material/Stack'
import { groupBy, some, sortBy, uniqBy } from 'lodash-es'
import { useEffect, useState } from 'react'
import { Container, Draggable, DropResult } from 'react-smooth-dnd'
import {
  CompetitionEntryBatchUpdate,
  CompetitionEntryResource,
  ViewCompetitionEntriesIncludingIssuesRequest
} from '@rallycry/api-suite-typescript'
import { TeamIssueTooltip } from '../registration/TeamIssueTooltip'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { TeamAvatar } from '@/components/organisms/avatar/TeamAvatar'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { useCompetitionTeamsIncludingIssues } from '@/entity/competition-team/useCompetitionTeams'
import { useCompetition } from '@/entity/competition/useCompetition'
import { calcElevationString } from '@/style/palette'
import { useApiError } from '@/core/hooks/useApiError'
import { RcIcon } from '@/components/atoms/RcIcon'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { useIsTablet } from '@/core/hooks/useMediaQueries'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { RcToggle } from '@/components/molecules/input/RcToggle'

export const TeamsSeeding = ({
  selected,
  existing,
  showFilters,
  onComplete,
  onCancel
}: {
  selected: number[]
  existing?: number[]
  showFilters?: boolean
  onComplete: (teams: CompetitionEntryBatchUpdate[]) => Promise<any>
  onCancel: () => void
}) => {
  const { t } = useRcTranslation()
  const isMobile = useIsTablet()
  const { handle } = useApiError()
  const [filter, setFilter] = useState('')
  const { competition } = useCompetition()
  const [request, setRequest] =
    useState<ViewCompetitionEntriesIncludingIssuesRequest>({
      competitionId: competition?.id!,
      includeCheckIn: showFilters,
      includeTeamSize: showFilters,
      includeFormResponse: showFilters,
      includeDropped: showFilters
    })
  const { teams } = useCompetitionTeamsIncludingIssues({
    request
  })

  useEffect(() => {
    setState(prev => {
      const reseeded: CompetitionEntryResource[] =
        teams?.map(it => ({
          ...it,
          seed:
            (existing ? existing.indexOf(it.id!) + 1 : it.seed) ||
            prev.seeded?.findIndex(s => s.id === it.id!) + 1
        })) || []

      const sorted = sortBy(reseeded, [
        it => it._issues?.length || 0,
        it => !selected.includes(it.id!),
        it => it.seed,
        it => it.alternateName?.toLocaleLowerCase()
      ])

      const grouped = groupBy(sorted, it => (!!it.seed ? 'seeded' : 'unseeded'))

      return {
        unseeded:
          grouped['unseeded']?.map(it => ({ id: it.id, data: it })) || [],
        seeded: grouped['seeded']?.map(it => ({ id: it.id, data: it })) || []
      }
    })
  }, [teams, existing, selected])

  const [state, setState] = useState({
    unseeded: [] as { id?: number; data: CompetitionEntryResource }[],
    seeded: [] as { id?: number; data: CompetitionEntryResource }[]
  })

  const handleReset = () => {
    setState({
      unseeded: teams?.map(it => ({ id: it.id, data: it })) || [],
      seeded: []
    })
  }

  const handleSave = async () => {
    const unseeded = state.unseeded.map(it => ({
      id: it.data.id!,
      clearSeed: true
    }))
    const seeded = state.seeded.map((it, idx) => ({
      id: it.data.id!,
      seed: idx + 1
    }))

    try {
      await onComplete([...unseeded, ...seeded])
    } catch (e) {
      handle(e)
    }
  }

  const handleRandomlyAssignRemaining = () => {
    setState(s => {
      const grouped = groupBy(s.unseeded, it => some(it.data._issues))
      const unseeded = grouped['false'] || []
      const seeded = []
      while (unseeded.length) {
        const idx = Math.floor(Math.random() * unseeded.length)
        seeded.push(unseeded.splice(idx, 1)[0])
      }
      return {
        unseeded: grouped['true'] || [],
        seeded: [...s.seeded, ...seeded]
      }
    })
  }

  const seedTo = (teamId: number, location: 'top' | 'bottom') => {
    return (
      <RcIconButton
        size='small'
        icon={
          location === 'top'
            ? ['fal', 'arrow-up-to-line']
            : ['fal', 'arrow-down-to-line']
        }
        onClick={() =>
          setState(s => {
            const item =
              s.unseeded.find(it => it.id === teamId) ||
              s.seeded.find(it => it.id === teamId)
            if (!item) return s
            return {
              ...s,
              unseeded: s.unseeded.filter(it => it.id !== teamId),
              seeded: uniqBy(
                location === 'top'
                  ? [item, ...s.seeded]
                  : [...s.seeded.filter(it => it.id !== teamId), item],
                it => it.id
              )
            }
          })
        }
      />
    )
  }

  const displayColumn = (group: 'unseeded' | 'seeded') => {
    const filterLowered = filter?.toLocaleLowerCase()
    const isSeeded = group === 'seeded'
    const shouldFilter = !isSeeded && filterLowered
    const sorted = sortBy(
      state[group],
      it =>
        shouldFilter &&
        !it.data.alternateName?.toLocaleLowerCase().includes(filterLowered)
    )

    return (
      <Box key={group} width={isMobile ? '100%' : '50%'} height={'100%'} pb={5}>
        <Stack
          direction='column'
          justifyContent='space-between'
          spacing={2}
          pb={2}
        >
          <Typography variant='subtitle1' textAlign='left'>
            {!isSeeded ? (
              <RcTrans i18nKey={`competition:team.unseeded-label`} />
            ) : (
              <RcTrans i18nKey={`competition:team.seeded-label`} />
            )}
          </Typography>
        </Stack>
        <Box sx={theme => containerSx(theme, isMobile, showFilters)}>
          <Container
            groupName='1'
            getChildPayload={i => sorted[i]}
            dragClass='card-ghost'
            dropClass='card-ghost-drop'
            dropPlaceholder={{
              animationDuration: 150,
              showOnTop: true,
              className: 'drop-preview'
            }}
            onDrop={e =>
              setState(s => ({ ...s, [group]: applyDrag(s[group], e) }))
            }
          >
            {sorted.map((p, idx) => (
              <Draggable key={p.id}>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={1}
                  sx={theme =>
                    dragItemSx(
                      theme,
                      filterLowered
                        ? p.data.alternateName
                            ?.toLocaleLowerCase()
                            .includes(filterLowered) || false
                        : some(selected)
                          ? selected.includes(p.id!)
                          : p.data._issues?.length! > 0
                            ? false
                            : true
                    )
                  }
                >
                  {isSeeded ? (
                    <Typography sx={{ minWidth: 50, pl: 3 }}>
                      {idx + 1}
                    </Typography>
                  ) : null}
                  <TeamAvatar team={{ ...p.data }} skipLink />
                  <Box pr={1}>
                    <TeamIssueTooltip teamId={p.id!} />
                  </Box>
                  {seedTo(p.id!, 'bottom')}
                  {seedTo(p.id!, 'top')}
                  <Box /> {/* Spacer for Stack's not(style) */}
                  <RcIconButton
                    sx={{ cursor: 'grab' }}
                    icon={['fas', 'grip-dots-vertical']}
                    size='small'
                  />
                </Stack>
              </Draggable>
            ))}
          </Container>
        </Box>
      </Box>
    )
  }

  return (
    <Stack direction='column' spacing={6} height='100%'>
      <SectionHeader
        title={<RcTrans i18nKey='competition:seeding.title' />}
        subtitle={<RcTrans i18nKey='competition:seeding.subtitle' />}
        tooltips={[
          {
            kind: 'info',
            content: <RcTrans i18nKey='competition:seeding.explained' />
          }
        ]}
      />
      <TextField
        value={filter}
        onChange={e => setFilter(e.target.value)}
        variant='outlined'
        size='small'
        InputProps={{
          placeholder: t('competition:seeding.search-placeholder'),
          startAdornment: <RcIcon icon={['fal', 'search']} />
        }}
      />
      {showFilters ? (
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
          <RcToggle
            label={<RcTrans i18nKey='competition:team.include-team-size' />}
            property='includeTeamSize'
            source={request}
            update={async val => {
              setRequest(r => ({ ...r, includeTeamSize: val.includeTeamSize }))
            }}
          />
          <RcToggle
            label={<RcTrans i18nKey='competition:team.include-check-in' />}
            property='includeCheckIn'
            source={request}
            update={async val => {
              setRequest(r => ({ ...r, includeCheckIn: val.includeCheckIn }))
            }}
          />
          <RcToggle
            label={<RcTrans i18nKey='competition:team.include-form-response' />}
            property='includeFormResponse'
            source={request}
            update={async val => {
              setRequest(r => ({
                ...r,
                includeFormResponse: val.includeFormResponse
              }))
            }}
          />
        </Stack>
      ) : null}
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 0 : 10}
        sx={wrapperSx}
      >
        {displayColumn('unseeded')}
        {displayColumn('seeded')}
      </Stack>

      <ModalConfiguration>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          justifyContent='space-between'
          spacing={3}
        >
          <Stack direction='row' spacing={2}>
            <ConfirmingButton
              size='medium'
              variant='outlined'
              color='warning'
              onClick={handleReset}
              fullWidth={isMobile}
              message={
                <RcTrans i18nKey='competition:team.reset-seeding-confirmation' />
              }
            >
              <RcIcon icon={['fal', 'arrows-rotate']} mr={2} />
              <RcTrans i18nKey='competition:team.reset-seeding' />
            </ConfirmingButton>
            <Button
              variant='outlined'
              color='primary'
              onClick={handleRandomlyAssignRemaining}
              fullWidth={isMobile}
            >
              <RcTrans i18nKey='competition:team.seed-randomly' />
            </Button>
          </Stack>
          <Stack direction='row' spacing={2}>
            <Button
              variant='contained'
              color='secondary'
              onClick={onCancel}
              fullWidth={isMobile}
              sx={{ minWidth: 125 }}
            >
              <RcTrans i18nKey='shared.cancel' />
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSave}
              fullWidth={isMobile}
              sx={{ minWidth: 125 }}
            >
              <RcTrans i18nKey='shared.save' />
            </Button>
          </Stack>
        </Stack>
      </ModalConfiguration>
    </Stack>
  )
}

const applyDrag = (arr: any[], dragResult: DropResult) => {
  if (!dragResult) return arr
  const { removedIndex, addedIndex, payload } = dragResult
  if (removedIndex === null && addedIndex === null) return arr

  const result = [...arr]
  let itemToAdd = payload

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0]
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd)
  }

  return result
}

const wrapperSx = (theme: Theme) => ({
  '& .drop-preview': {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: calcElevationString(theme.palette.mode, [8])
  },
  '& .card-ghost': {
    transition: 'transform 0.18s ease',
    transform: 'rotateZ(3deg)'
  },
  '& .card-ghost-drop': {
    transition: 'transform 0.18s ease-in-out',
    transform: 'rotateZ(0deg)'
  }
})

const containerSx = (
  theme: Theme,
  isMobile: boolean,
  showFilters?: boolean
) => {
  const height = isMobile
    ? `calc(45dvh - 100px)`
    : `calc(100dvh - ${showFilters ? 450 : 395}px)`
  return {
    borderRadius: 1,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: calcElevationString(theme.palette.mode, [3]),
    overflowY: 'auto',
    height: height,
    maxHeight: height
  }
}

const dragItemSx = (theme: Theme, isSelected: boolean) => ({
  cursor: 'grab',
  backgroundColor: theme.palette.primary.main,
  backgroundImage: calcElevationString(theme.palette.mode, [5]),
  flexGrow: 1,
  p: 2,
  opacity: isSelected ? 1 : 0.6
})
