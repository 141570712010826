import { useState } from 'react'
import { JoinFlow } from './_JoinFlow'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { RootRoute } from '@/core/route-keys'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useQuery } from '@/core/hooks/useQuery'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'

export const CompetitionJoinModal = ({
  buttonSize = 'medium',
  children,
  freeAgent,
  selectedTeam
}: {
  buttonSize?: 'large' | 'medium' | 'small'
  freeAgent?: boolean
  selectedTeam?: number
  children?: React.ReactNode
}) => {
  const q = useQuery()
  const [open, setOpen] = useState(!!q.get('join'))
  const { navTo } = useNavigation()
  const { account } = useUserAccount()
  const [code] = useLocalStorage<{ teamId: number; code: string }>({
    key: 'team-invite-code'
  })

  return (
    <ModalTrigger
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      activation={handleOpen =>
        children ? (
          <Link
            underline='none'
            onClick={() =>
              account ? handleOpen() : navTo({ root: RootRoute.Register })
            }
          >
            {children}
          </Link>
        ) : (
          <RcButton
            size={buttonSize}
            onClick={() =>
              account ? handleOpen() : navTo({ root: RootRoute.Register })
            }
            variant='contained'
          >
            {freeAgent ? (
              <RcTrans i18nKey='competition:team.create-participant' />
            ) : (
              <RcTrans i18nKey='shared.apply' />
            )}
          </RcButton>
        )
      }
    >
      {({ handleClose }) => (
        <JoinFlow
          code={code?.code}
          freeAgent={freeAgent}
          selectedTeam={selectedTeam}
          onComplete={handleClose}
        />
      )}
    </ModalTrigger>
  )
}
