import Stack from '@mui/material/Stack'
import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  CompetitionInfoRoute,
  CompetitionRoute,
  RootRoute
} from '@/core/route-keys'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useNavigation } from '@/core/hooks/useNavigation'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'

export const useCompetitionAlertsConcluded = () => {
  const { navTo } = useNavigation()
  const { competition } = useCompetition()

  const issues: TutorialItem[] = []

  // competition is over, show winners
  if (competition?.state === CompetitionState.CONCLUDED) {
    issues.push({
      type: TutorialType.DEPRECATED,
      onClick: () =>
        navTo(
          {
            root: RootRoute.Competition,
            rootId: competition?.id,
            subRoute: CompetitionRoute.Info,
            subId: CompetitionInfoRoute.Details
          },
          true
        ),
      severity: 'info',
      children: (
        <Stack direction='column' spacing={3}>
          <RcTrans i18nKey='competition:issues.concluded' />
        </Stack>
      )
    })
  }

  return issues
}
