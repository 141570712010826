import { FormResponseEditProps } from '../FormResponseQuestionEdit'
import { RcTextInput } from '@/components/molecules/input/RcTextInput'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'

export const FormResponseEditAssignment = ({
  responseId,
  question
}: FormResponseEditProps) => {
  const { answers, createFormAnswer, updateFormAnswer } = useFormResponse({
    idOrKey: responseId
  })

  const answer = answers?.find(it => it.question?.id === question?.id)
  const value = answer?.value

  return (
    <RcTextInput
      // disable form answer while fake id -1 during creation
      isSubmitting={answer?.id === -1}
      property='value'
      source={{
        value: value
      }}
      update={(val: any) =>
        answer
          ? updateFormAnswer(answer?.id!, { ...val })
          : createFormAnswer({
              question: question.id!,
              ...val
            })
      }
      required={question.required}
      defaultValue=''
      email
    />
  )
}
