import { CuratedFormControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CuratedFormControllerApi'
import { FormOptionControllerApi } from '@rallycry/api-suite-typescript/dist/apis/FormOptionControllerApi'
import { FormQuestionControllerApi } from '@rallycry/api-suite-typescript/dist/apis/FormQuestionControllerApi'
import { FormSectionControllerApi } from '@rallycry/api-suite-typescript/dist/apis/FormSectionControllerApi'
import { CuratedFormUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/CuratedFormUpdateCommand'
import { FormOptionCreateCommand } from '@rallycry/api-suite-typescript/dist/models/FormOptionCreateCommand'
import { FormOptionUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/FormOptionUpdateCommand'
import { FormQuestionCreateCommand } from '@rallycry/api-suite-typescript/dist/models/FormQuestionCreateCommand'
import { FormQuestionUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/FormQuestionUpdateCommand'
import { FormSectionCreateCommand } from '@rallycry/api-suite-typescript/dist/models/FormSectionCreateCommand'
import { FormSectionResource } from '@rallycry/api-suite-typescript/dist/models/FormSectionResource'
import { FormSectionUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/FormSectionUpdateCommand'
import { orderBy, uniqBy } from 'lodash-es'
import { useMemo } from 'react'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useCuratedForm = (entityOptions?: EntityOptions) => {
  const formId = useParsedParam('formId')
  const { ctrl } = useController(CuratedFormControllerApi)
  const { ctrl: section } = useController(FormSectionControllerApi)
  const { ctrl: question } = useController(FormQuestionControllerApi)
  const { ctrl: option } = useController(FormOptionControllerApi)

  const idOrKey = Number(entityOptions?.idOrKey || formId)
  const entity = useReadEntity({
    key: 'useCuratedForm',
    expand: 'sections{questions{options}}',
    persist: true,
    idOrKey,
    ...entityOptions,
    read: req => ctrl().readCuratedForm(req),
    remove: id => ctrl().removeCuratedForm({ id })
  })

  const updateForm = async (cmd: CuratedFormUpdateCommand) => {
    await ctrl().updateCuratedForm({
      id: idOrKey,
      CuratedFormUpdateCommand: cmd
    })
    await entity.read.mutate()
  }

  const removeForm = async () => entity.remove(idOrKey)

  const createFormSection = async (cmd: FormSectionCreateCommand) => {
    const res = await section().createFormSection({
      formId: idOrKey,
      FormSectionCreateCommand: cmd
    })
    await entity.read.mutate()
    return res
  }

  const updateFormSection = async (
    sectionId: number,
    cmd: FormSectionUpdateCommand
  ) => {
    await section().updateFormSection({
      id: sectionId,
      FormSectionUpdateCommand: cmd
    })
    await entity.read.mutate()
  }

  const removeFormSection = async (id: number) => {
    await section().removeFormSection({ id })
    await entity.read.mutate()
  }

  const createFormQuestion = async (
    sectionId: number,
    cmd: FormQuestionCreateCommand
  ) => {
    await question().createFormQuestion({
      sectionId,
      FormQuestionCreateCommand: { ...cmd, encrypted: true }
    })
    await entity.read.mutate()
  }

  const updateFormQuestion = async (
    questionId: number,
    cmd: FormQuestionUpdateCommand
  ) => {
    await question().updateFormQuestion({
      id: questionId,
      FormQuestionUpdateCommand: cmd
    })
    await entity.read.mutate()
  }

  const removeFormQuestion = async (id: number) => {
    await question().removeFormQuestion({ id })
    await entity.read.mutate()
  }

  const createFormOption = async (
    questionId: number,
    cmd: FormOptionCreateCommand
  ) => {
    await option().createFormOption({
      questionId,
      FormOptionCreateCommand: cmd
    })
    await entity.read.mutate()
  }

  const updateFormOption = async (
    optionId: number,
    cmd: FormOptionUpdateCommand
  ) => {
    await option().updateFormOption({
      id: optionId,
      FormOptionUpdateCommand: cmd
    })
    await entity.read.mutate()
  }

  const removeFormOption = async (id: number) => {
    await option().removeFormOption({ id })
    await entity.read.mutate()
  }

  const reorderSections = async (ordered: FormSectionResource[]) => {
    const content = ordered?.map((it, idx) => ({
      id: it.id!,
      ordinal: idx + 1,
      next: it.next?.id,
      clearNext: !it.next
    }))
    await section().batchUpdateFormSections({
      formId: idOrKey,
      FormSectionBatchUpdateCommand: { content }
    })
    await entity.read.mutate()
  }

  const reorderQuestions = async (
    sectionId: number,
    ordered: { id?: number }[]
  ) => {
    const content = ordered?.map((it, idx) => ({
      id: it.id!,
      ordinal: idx + 1
    }))
    await question().batchUpdateFormQuestions({
      sectionId,
      FormQuestionBatchUpdateCommand: { content }
    })
    await entity.read.mutate()
  }

  const reorderOptions = async (
    questionId: number,
    ordered: { id?: number }[]
  ) => {
    const content = ordered?.map((it, idx) => ({
      id: it.id!,
      ordinal: idx + 1
    }))
    await option().batchUpdateFormOptions({
      questionId,
      FormOptionBatchUpdateCommand: { content }
    })
    await entity.read.mutate()
  }

  const form = entity.read.data

  const sections = useMemo(
    () =>
      uniqBy(
        orderBy(form?._expanded?.formSection || [], ['ordinal', 'id']),
        'id'
      ),
    [form]
  )
  const questions = useMemo(
    () => orderBy(form?._expanded?.formQuestion || [], ['ordinal', 'id']),
    [form]
  )
  const options = useMemo(
    () => orderBy(form?._expanded?.formOption || [], ['ordinal', 'id']),
    [form]
  )

  return {
    ...entity,
    form,
    sections,
    questions,
    options,
    hasMultipleSections: sections?.length! > 1,
    updateForm,
    removeForm,
    createFormSection,
    updateFormSection,
    removeFormSection,
    createFormQuestion,
    updateFormQuestion,
    removeFormQuestion,
    createFormOption,
    updateFormOption,
    removeFormOption,
    reorderSections,
    reorderQuestions,
    reorderOptions
  }
}
