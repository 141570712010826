import {
  CompetitionBracketControllerApi,
  QueryCompetitionBracketsRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionBracketControllerApi'
import { CompetitionBracketCreateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketCreateCommand'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserMeta } from '../user/useUserMeta'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useBrackets = (
  options?: EntityOptions<QueryCompetitionBracketsRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const { read: meta } = useCompetitionMeta()
  const { read: userMeta } = useUserMeta()
  const { ctrl } = useController(CompetitionBracketControllerApi)

  const entity = useQueryEntity({
    key: 'useBrackets',
    expand: '_links,content{settings}',
    ...options,
    request: { competitionId, ...options?.request },
    metas: [userMeta, meta],
    read: (req, { brackets, access }) =>
      ctrl({ metas: { brackets, access } }).queryCompetitionBrackets(req),
    create: (cmd: CompetitionBracketCreateCommand) =>
      ctrl().createCompetitionBracket({
        competitionId,
        CompetitionBracketCreateCommand: cmd
      })
  })

  return { ...entity, brackets: entity.flat }
}
