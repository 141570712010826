import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { first, last, some } from 'lodash-es'
import { PageForm } from '@/components/organisms/page/PageForm'
import { useApiError } from '@/core/hooks/useApiError'

interface Mark {
  value: number
  label?: React.ReactNode
}
interface RcSliderProps {
  disabled?: boolean
  label?: React.ReactNode
  description?: React.ReactNode
  value: number | number[]
  min: number
  max: number
  step?: number | null
  track?: 'normal' | false | 'inverted'
  marks?: Mark[]
  getLabel?: (size: number) => React.ReactNode
  precommitFn?: (value: number | number[]) => Promise<number | number[]>
  updateFn: (value: number | number[]) => Promise<any>
}

export const RcSlider: React.FC<RcSliderProps> = ({
  disabled,
  label,
  description,
  value: externalValues,
  min,
  max,
  step,
  track,
  marks,
  getLabel,
  precommitFn,
  updateFn
}) => {
  const [state, setState] = React.useState<{
    value: number | number[]
    isSaving: boolean
  }>({
    value: externalValues,
    isSaving: false
  })
  const { handle } = useApiError()

  // Handle state changes while dragging, prior to commit
  const handleMinMaxChanged = (_event: any, value: number | number[]) => {
    setState(s => ({ ...s, value }))
  }

  // Handle for when when marker is "dropped"
  const handleMinMaxCommitted = async (
    _event: any,
    updated: number | number[]
  ) => {
    // Caller may optionally adjust value for soft validation
    const value = (await precommitFn?.(updated)) || updated
    setState(s => ({ ...s, value, isSaving: true }))

    try {
      await updateFn(value)
    } catch (error) {
      await handle(error)
    } finally {
      setState(s => ({ ...s, isSaving: false }))
    }
  }

  const begin = first(marks)
  const end = last(marks)

  return (
    <PageForm title={label} description={description}>
      <Slider
        disabled={state.isSaving || disabled}
        value={state.value}
        onChange={handleMinMaxChanged}
        onChangeCommitted={handleMinMaxCommitted}
        aria-labelledby='track-false-slider'
        valueLabelFormat={getLabel}
        valueLabelDisplay={getLabel ? 'auto' : 'off'}
        min={min}
        max={max}
        step={step}
        track={track}
        marks={marks?.slice(1, -1)} // Skip first and last mark
        sx={theme => ({
          '&.MuiSlider-root': {
            width: `calc(100% - ${theme.spacing(4)})`,
            mx: 2
          },
          '&.MuiSlider-marked': {
            marginBottom: 0,
            maxHeight: 0
          }
        })}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant='body2'
          onClick={() => handleMinMaxChanged(null, begin?.value!)}
          color={
            state.value === begin?.value ? 'text.primary' : 'text.secondary'
          }
          sx={{ cursor: 'pointer' }}
        >
          {begin?.label}
        </Typography>
        <Typography
          variant='body2'
          onClick={() => handleMinMaxChanged(null, end?.value!)}
          color={state.value === end?.value ? 'text.primary' : 'text.secondary'}
          sx={{ cursor: 'pointer' }}
        >
          {end?.label}
        </Typography>
      </Box>
    </PageForm>
  )
}
