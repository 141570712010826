import {
  BracketMatchChannelControllerApi,
  QueryMatchChannelsRequest
} from '@rallycry/api-suite-typescript/dist/apis/BracketMatchChannelControllerApi'
import { MatchChannelsByQueryParams } from '@rallycry/api-suite-typescript'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useBracketMatchChannels = (
  options?: EntityOptions<QueryMatchChannelsRequest>
) => {
  const parsedId = useParsedParam('matchId')
  const { ctrl } = useController(BracketMatchChannelControllerApi)

  const matchId = Number(options?.idOrKey) || parsedId
  const entity = useQueryEntity({
    key: 'useBracketMatchChannels',
    expand: '_links',
    ...options,
    request:
      options?.request || matchId
        ? { ...options?.request, matchId }
        : undefined,
    read: req => ctrl().queryMatchChannels(req)
  })

  const createChats = async (
    bracketId: number,
    params: MatchChannelsByQueryParams
  ) => {
    await ctrl().saveMatchChannelsByQuery({
      bracketId,
      MatchChannelsByQueryParams: params
    })
    await entity.query.mutate()
  }

  return { ...entity, channels: entity.flat, createChats }
}
