import { ComponentDynamicMediaEmbed } from '../../../../../models/types'
import { ImageDisplay } from '@/components/atoms/ImageDisplay'
import { ImageSize, useCMSFile } from '@/core/hooks/useCMSFile'

export const MediaEmbed = (props: ComponentDynamicMediaEmbed) => {
  const { getImagePath } = useCMSFile()
  const path = getImagePath(props.content?.data, ImageSize.Large)

  return <ImageDisplay rounded width='100%' height='auto' path={path} />
}
