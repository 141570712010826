import {
  CompetitionTicketControllerApi,
  QueryCompetitionTicketsRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionTicketControllerApi'
import { TicketUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/TicketUpdateCommand'
import { ChatControllerApi } from '@rallycry/social-api-typescript/dist/apis'
import {
  ChatAccessLevel,
  MemberKind
} from '@rallycry/social-api-typescript/dist/models'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useCompetition } from './useCompetition'
import { useCompetitionMeta } from './useCompetitionMeta'
import { useController, useSocialController } from '@/core/hooks/useSWRApi'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

export const useCompetitionTickets = (
  options?: EntityOptions<QueryCompetitionTicketsRequest>
) => {
  const { user } = useFirebase()
  const { account } = useUserAccount()
  const { read: meta } = useCompetitionMeta()
  const { competition, isCompetitionModerator } = useCompetition()
  const competitionId = competition?.id!
  const { ctrl } = useController(CompetitionTicketControllerApi)
  const { ctrl: chatCtrl } = useSocialController(ChatControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionTickets',
    expand: 'content{user}',
    paused: !isCompetitionModerator || !competition?.id,
    ...options,
    metas: [meta],
    request: {
      competitionId,
      closed: false,
      ...options?.request
    } as QueryCompetitionTicketsRequest,
    read: (req, { tickets }) =>
      ctrl({ metas: { tickets } }).queryCompetitionTickets(req)
  })

  const create = async (description: string) => {
    const res = await ctrl().createCompetitionTicket({
      competitionId,
      CompetitionTicketCreateCommand: {
        description,
        user: account?.id!
      }
    })
    return res
  }

  const update = async (id: number, cmd: TicketUpdateCommand) => {
    await ctrl().updateCompetitionTicket({
      id,
      TicketUpdateCommand: cmd
    })
    await entity.query.mutate()
  }

  const addModerator = async (ticketId: number, socialId: string) => {
    const { ticket } = await ctrl().obtainCompetitionTicketTicket({
      id: ticketId
    })

    return chatCtrl({ ticket }).grantChatMember({
      id: socialId,
      chatGrantMemberCommand: {
        accessLevel: ChatAccessLevel.MODERATOR,
        name: account?.name,
        kind: MemberKind.FIREBASEUSER,
        image: account?.hideImage
          ? undefined
          : account?.imageOverride || account?._expanded?.user?.[0]?.image,
        externalId: user?.uid,
        id: account?.id?.toString()!
      }
    })
  }

  const close = async (id: number) => {
    await ctrl().closeCompetitionTicket({ id })
  }

  return {
    ...entity,
    competition,
    tickets: entity.flat,
    create,
    update,
    addModerator,
    close
  }
}
