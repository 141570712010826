import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { StackProps } from '@mui/material/Stack'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ActionLink } from '../../Landing/components/ActionLink'
import { ComponentDynamicManyMiniBoxes } from '../../../../../models/types'
import { RcIcon } from '@/components/atoms/RcIcon'
import { MarkdownDisplay } from '@/components/molecules/input/MarkdownDisplay'
import { DiscoverCard } from '@/components/organisms/card/DiscoverCard'

export const ManyMiniBoxes: React.FC<ComponentDynamicManyMiniBoxes> = props => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      spacing={8}
      className='page-mini-boxes'
    >
      {props.content?.map((it: any, idx: number) => (
        <Grid key={it.id} item xs={12} sm={idx === 0 ? 12 : 4}>
          {idx > 0 ? (
            <DiscoverCard
              disabled
              content={
                <ActionLink action={it}>
                  <PageIconBox
                    icon={it.icon}
                    title={it.title}
                    description={it.description}
                    sx={{
                      height: '100%'
                    }}
                  />
                </ActionLink>
              }
            />
          ) : (
            <ActionLink action={it}>
              <PageIconBox
                icon={it.icon}
                title={it.title}
                description={it.description}
                sx={{
                  height: '100%'
                }}
              />
            </ActionLink>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

interface PageIconBoxProps extends StackProps {
  icon?: IconName
  title?: string
  description?: string
}

const PageIconBox: React.FC<PageIconBoxProps> = ({
  icon,
  title,
  description,
  ...props
}) => {
  const titleOnly = !icon && !description
  const titleSize: 'body0' | 'h2' = titleOnly ? 'h2' : 'body0'
  const justify: 'center' | 'flex-start' = titleOnly ? 'center' : 'flex-start'

  return (
    <Stack direction='column' spacing={4} justifyContent={justify} {...props}>
      {icon ? (
        <Box display='flex' justifyContent='center'>
          <RcIcon size='3x' color='secondary' icon={['fal', icon]} />
        </Box>
      ) : null}
      {title ? (
        <Typography align='center' variant={titleSize} textAlign='center'>
          <strong>{title}</strong>
        </Typography>
      ) : null}
      {description ? (
        <MarkdownDisplay
          variant='body2'
          typographyProps={{ align: 'center' }}
          markdown={description}
        />
      ) : null}
    </Stack>
  )
}
