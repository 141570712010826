import MenuItem from '@mui/material/MenuItem'
import { JoinRestriction } from '@rallycry/api-suite-typescript/dist/models/JoinRestriction'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSelect } from '@/components/molecules/input/RcSelect'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetition } from '@/entity/competition/useCompetition'

export const TeamJoinRestrictionForm = () => {
  const { settings } = useCompetition()
  const { team, update } = useCompetitionTeam()

  return (
    <RcSelect
      property='joinRestriction'
      source={{ joinRestriction: team?.joinRestriction! }}
      update={values =>
        update(team?.id!, {
          ...values,
          lookingForMore:
            values.joinRestriction === JoinRestriction.INVITEONLY
              ? false
              : team?.lookingForMore
        })
      }
      label={<RcTrans i18nKey='competition:settings.label' />}
      description={
        settings?.representSameCommunity ? (
          <RcTrans i18nKey='competition:settings.context-same-community' />
        ) : (
          <RcTrans i18nKey='competition:settings.context-any-community' />
        )
      }
      disabled={!team?._links?.update}
    >
      <MenuItem value={JoinRestriction.NORMAL}>
        <RcTrans i18nKey='competition:restriction.option-invite' />
      </MenuItem>
      <MenuItem value={JoinRestriction.INVITEONLY}>
        <RcTrans i18nKey='competition:restriction.invite-only' />
      </MenuItem>
    </RcSelect>
  )
}
