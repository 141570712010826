import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup'
import { RcButton } from './RcButton'
import { RcIcon } from '@/components/atoms/RcIcon'

export interface YesNoButtonGroupProps extends Omit<ButtonGroupProps, 'size'> {
  id?: string
  onClickYes: () => void
  onClickNo: () => void
  size?: SizeProp
}

export const YesNoButtonGroup: React.FC<YesNoButtonGroupProps> = ({
  id,
  onClickYes,
  onClickNo,
  size = 'lg',
  ...rest
}) => {
  return (
    <ButtonGroup {...rest}>
      <RcButton id={id || 'generic' + 'yes-action'} onClick={onClickYes}>
        <RcIcon color='success' icon={['fal', 'check']} size={size} />
      </RcButton>
      <RcButton id={(id || 'generic') + 'no-action'} onClick={onClickNo}>
        <RcIcon color='error' icon={['fal', 'times']} size={size} />
      </RcButton>
    </ButtonGroup>
  )
}
