import { BracketState } from '@rallycry/api-suite-typescript/dist/models/BracketState'
import { some } from 'lodash-es'
import { LeaderboardTableRow } from './LeaderboardTableRow'
import { NoResults } from '@/components/molecules/text/NoResults'
import { useBracket } from '@/entity/bracket/useBracket'
import { useBracketLeaderboard } from '@/entity/leaderboard/useBracketLeaderboard'
import { RcTable, RcTableBody } from '@/components/molecules/text/RcTable'

export const LeaderboardTable = ({ lastUpdated }: { lastUpdated?: Date }) => {
  const { bracket, bracketSettings } = useBracket()
  const { scores } = useBracketLeaderboard({ lastUpdated })

  const LeaderboardTable = (
    <RcTable>
      <RcTableBody
        sx={{
          width: '100%',
          '.MuiTableRow-root.cutoff:hover': {
            borderBottomLeftRadius: '0 !important',
            borderBottomRightRadius: '0 !important'
          },
          '.MuiTableRow-root.cutoff + .MuiTableRow-root:not(.cutoff)': {
            borderTopLeftRadius: '0 !important',
            borderTopRightRadius: '0 !important',
            borderTop: 'gold 2px solid'
          }
        }}
      >
        {scores?.map(it => {
          const adjustedRank =
            scores.findIndex(that => that.totalPoints === it.totalPoints) + 1
          return (
            <LeaderboardTableRow
              key={it.assignment?.id}
              rank={adjustedRank}
              resource={it}
              isCutoff={adjustedRank > (bracketSettings?.submissionCutoff || 0)}
              isFinal={bracket?.state === BracketState.FINALIZED}
            />
          )
        })}
      </RcTableBody>
    </RcTable>
  )

  return (
    <>
      <>{LeaderboardTable}</>
      {!some(scores) ? <NoResults /> : null}
    </>
  )
}
