import Typography from '@mui/material/Typography'
import { SearchCompetitionsRequest } from '@rallycry/api-suite-typescript/dist/apis/CompetitionDocumentControllerApi'
import { ReactNode } from 'react'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { CompetitionDocumentCard } from '@/components/organisms/card/CompetitionDocumentCard'
import { CardsSection } from '@/components/organisms/card/CardsSection'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useCompetitionDocument } from '@/entity/competition/useCompetitionDocument'
import { DiscoveryRoute, RootRoute } from '@/core/route-keys'
import { useNavigation } from '@/core/hooks/useNavigation'

export const CompetitionSearchResultSlider = ({
  request,
  googleTag,
  title
}: {
  request: Partial<SearchCompetitionsRequest>
  googleTag?: string
  title?: ReactNode
}) => {
  const { getPath } = useNavigation()
  const { competitions } = useCompetitionDocument({
    request: request
  })

  return (
    <>
      <PageItemContent pb={0}>
        <SectionHeader title={title}>
          {competitions.length > 3 ? (
            <Typography align='right'>
              <NavigationLink
                to={getPath({
                  root: RootRoute.Discovery,
                  subRoute: DiscoveryRoute.Competitions
                })}
              >
                <RcButton variant='text'>
                  <RcTrans i18nKey='shared.view-more' />
                </RcButton>
              </NavigationLink>
            </Typography>
          ) : null}
        </SectionHeader>
      </PageItemContent>

      <PageItemContent px={{ xs: 0, sm: 4 }}>
        <CardsSection
          align='flex-start'
          cardItems={competitions}
          render={cardItem => (
            <CompetitionDocumentCard
              googleTag={googleTag || 'competition-conversion:slider'}
              key={cardItem.id}
              resource={cardItem}
            />
          )}
        />
      </PageItemContent>
    </>
  )
}
