import { InlineCommunity } from '@rallycry/api-suite-typescript/dist/models/InlineCommunity'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import { useOrganizationCommunity } from '../organization/useOrganizationCommunity'
import { useFeatures } from '@/components/providers/site/FeatureProvider'

export const useCompetitionTeamImage = ({
  leader,
  representing,
  isSingleRestrictedCommunity,
  isSoloCompetition
}: {
  leader?: UserResource
  representing?: InlineCommunity
  isSingleRestrictedCommunity: boolean
  isSoloCompetition: boolean
}) => {
  const { imgCommunityAvatar, featHideCommunity } = useFeatures()
  const community = useOrganizationCommunity({ community: representing })
  const defaultAvatarSrc = imgCommunityAvatar as string
  const communityImage =
    featHideCommunity || isSingleRestrictedCommunity ? '' : community?.image

  return isSoloCompetition
    ? leader?.image || communityImage || defaultAvatarSrc
    : communityImage || leader?.image || defaultAvatarSrc
}
