import {
  EntryMemberRequestControllerApi,
  QuerySelfEntryMemberRequestsRequest
} from '@rallycry/api-suite-typescript/dist/apis/EntryMemberRequestControllerApi'
import { AcceptDecline } from '@rallycry/api-suite-typescript/dist/models/AcceptDecline'
import { InviteApply } from '@rallycry/api-suite-typescript/dist/models/InviteApply'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useCompetitionTeamMembers } from './useCompetitionTeamMembers'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useCompetitionTeamSelfInvitations = (
  options?: EntityOptions<QuerySelfEntryMemberRequestsRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const teamId = useParsedParam('teamId')
  const { account } = useUserAccount()
  const { read: meta } = useCompetitionMeta()
  const {
    query: { mutate: mutateRoster }
  } = useCompetitionTeamMembers()
  const { ctrl } = useController(EntryMemberRequestControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionTeamSelfInvitations',
    ...options,
    request: {
      competitionId,
      kind: InviteApply.INVITE,
      status: AcceptDecline.PENDING,
      ...options?.request
    },
    read: req =>
      ctrl({
        expectedStatuses: [401],
        fallbackResponse: { content: [] }
      }).querySelfEntryMemberRequests(req)
  })

  const accept = async (id: number) => {
    await ctrl().acceptEntryMemberRequest({ id })
    await Promise.allSettled([meta.mutate(), mutateRoster()])
  }

  const decline = async (id: number) => {
    await ctrl().declineEntryMemberRequest({ id })
    await meta.mutate()
  }

  const invite = async (participantId: number, teamId: number) => {
    await ctrl().createEntryMemberRequest({
      entryId: teamId,
      EntryMemberRequestCreateCommand: {
        recipient: participantId,
        kind: InviteApply.INVITE
      }
    })
  }

  const invitation = entity.flat.find(
    it => it.recipient?.id === account?.user?.id && it.entry?.id === teamId
  )

  return {
    ...entity,
    invitations: entity.flat,
    invitation,
    invite,
    accept,
    decline
  }
}
