import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Maybe } from '../../../../../models/types'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { RcIcon } from '@/components/atoms/RcIcon'

export const MatchTickerRow = ({
  avatar,
  avatarName,
  shortName,
  longName,
  score,
  isWinner
}: {
  avatar?: string
  avatarName?: Maybe<string>
  shortName?: Maybe<string>
  longName?: Maybe<string>
  score?: Maybe<string>
  isWinner?: boolean
}) => {
  const s = Number(score || 0)
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={2}
    >
      <Tooltip title={avatarName}>
        <RcAvatar
          sx={{ width: 20, height: 20, mr: 1 }}
          src={avatar}
          variant='rounded'
        />
      </Tooltip>

      <Tooltip
        title={longName || shortName}
        placement='top-start'
        sx={{ flexGrow: 1 }}
      >
        <Box width={165}>
          <Typography
            overflow='hidden'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            variant='subtitle1'
            flexGrow={1}
            color='text.primary'
          >
            {shortName && longName?.length! > 17 ? shortName : longName}
          </Typography>
        </Box>
      </Tooltip>

      <Typography
        variant='subtitle2'
        textAlign='right'
        sx={{
          minWidth:
            (isWinner ? 20 : 0) +
            (s >= 10000
              ? 40
              : s >= 1000
              ? 35
              : s >= 100
              ? 25
              : s >= 10
              ? 15
              : 10)
        }}
        color={isWinner ? 'success.main' : 'text.secondary'}
      >
        <Stack direction='row' justifyContent='flex-end' alignItems='center'>
          {isWinner ? <RcIcon icon={['fal', 'caret-right']} size='sm' /> : null}
          {s}
        </Stack>
      </Typography>
    </Stack>
  )
}
