import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { PermissionLevel } from '@rallycry/api-suite-typescript/dist/models/PermissionLevel'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionOfficers } from '@/entity/competition/useCompetitionOfficers'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'

export const useCompetitionAlertsAdmin = () => {
  const { competition, isCompetitionModerator, update, isDiscordChat } =
    useCompetition()
  const { isOrgModerator } = useOrganization()
  const { featChat } = useFeatures()

  const { user } = useUserAccount({ paused: !isOrgModerator || !featChat })
  const { officers, create } = useCompetitionOfficers({
    paused: !isOrgModerator || !featChat
  })

  const issues: TutorialItem[] = []
  if (
    isCompetitionModerator &&
    competition?.state === CompetitionState.INITIAL
  ) {
    issues.push({
      header: <RcTrans i18nKey='competition:issues.draft-header' />,
      type: TutorialType.TASK,
      dismissable: `${competition?.id}-draft`,
      severity: 'warning',
      children: <RcTrans i18nKey='competition:issues.draft-alert' />,
      action: (
        <RcButton
          size='medium'
          onClick={() =>
            update(competition?.id!, {
              state: CompetitionState.INPROGRESS
            })
          }
        >
          <RcTrans i18nKey='shared.publish' />
        </RcButton>
      )
    })
  }

  // prompt organization moderators to "subscribe" to competition
  if (
    featChat &&
    isOrgModerator &&
    !officers[0]?._expanded?.user?.find(it => it.id === user?.id) &&
    competition?.state === CompetitionState.INPROGRESS &&
    !isDiscordChat
  ) {
    issues.push({
      header: <RcTrans i18nKey='competition:issues.subscribe-header' />,
      type: TutorialType.TASK,
      dismissable: `${competition?.id}-subscribe`,
      severity: 'info',
      children: <RcTrans i18nKey='competition:issues.subscribe-alert' />,
      action: (
        <RcButton
          size='medium'
          onClick={() => create(user?.id!, PermissionLevel.MODERATOR)}
        >
          <RcTrans i18nKey='shared.subscribe' />
        </RcButton>
      )
    })
  }

  return issues
}
