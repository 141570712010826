import {
  ReadSelfCommunitiesRequest,
  UserControllerApi
} from '@rallycry/api-suite-typescript/dist/apis/UserControllerApi'
import { CommunityResource } from '@rallycry/api-suite-typescript/dist/models/CommunityResource'
import { useCallback } from 'react'
import { orderBy } from 'lodash-es'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserAccount } from './useUserAccount'
import { useUserMeta } from './useUserMeta'
import { expand, ExpansionType } from '@/core/expand'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserSelfCommunities = (
  options?: EntityOptions<ReadSelfCommunitiesRequest>
) => {
  const { account } = useUserAccount()
  const { ctrl } = useController(UserControllerApi)
  const { read: userMeta } = useUserMeta(options)

  const entity = useQueryEntity({
    key: 'useUserSelfCommunities' + options?.skipImpersonation,
    request: {},
    metas: [userMeta],
    expand:
      'content{community{profile,organization,additionalOrganizations{organization}}}',
    ...options,
    paused: !account?.id ? true : options?.paused,
    read: (req, { communities, expiredCode }) =>
      ctrl({
        metas: { communities, expiredCode },
        expectedStatuses: [401],
        skipImpersonation: options?.skipImpersonation
      }).readSelfCommunities(req)
  })

  const communities = entity.flat

  const getMemberships = useCallback(() => {
    return orderBy(
      communities.map(c => ({
        id: c.id!,
        community: expand<CommunityResource>(
          c,
          c._expanded,
          ExpansionType.Community
        )!
      })),
      [it => it.community?.name?.toLocaleLowerCase()]
    )
  }, [communities])

  return { ...entity, communities, getMemberships }
}
