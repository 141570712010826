import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { EntryMemberRequestResource } from '@rallycry/api-suite-typescript/dist/models/EntryMemberRequestResource'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import React, { useRef } from 'react'
import { RcTrans } from '@/components/atoms/RcTrans'
import { ActionMenu } from '@/components/molecules/interactive/ActionMenu'
import { YesNoButtonGroup } from '@/components/molecules/interactive/YesNoButtonGroup'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { expander } from '@/core/expand'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetitionTeamSelfApplications } from '@/entity/competition-team/useCompetitionTeamSelfApplications'

export interface TeamApplicationRowProps {
  application: EntryMemberRequestResource
  onAccept: () => void
  onReject: () => void
}

export const TeamApplicationRow = ({
  application,
  onAccept,
  onReject
}: TeamApplicationRowProps) => {
  const profileRef = useRef<any>()
  const { team } = useCompetitionTeam()
  const { withdraw } = useCompetitionTeamSelfApplications()

  const user = expander<UserResource>(application, 'entryRequestAgent')!

  const isAdmin = !!team?._links?._delete
  const canWithdraw = !!application?._links?._delete
  const actions = canWithdraw
    ? [
        {
          key: 1,
          display: <RcTrans i18nKey='competition:withdraw-application' />,
          action: () => withdraw()
        }
      ]
    : [
        {
          key: 2,
          display: <RcTrans i18nKey='community:members.view-profile' />,
          action: () => profileRef.current.open()
        }
      ]

  return (
    <TableRow>
      <TableCell>
        <UserAvatar user={user} ref={profileRef} />
      </TableCell>
      <TableCell
        align='right'
        sx={{ paddingRight: isAdmin ? { xs: 2, sm: 5 } : { xs: 3, sm: 8 } }}
      >
        {isAdmin ? (
          <YesNoButtonGroup
            onClickYes={onAccept}
            onClickNo={onReject}
            variant='text'
          />
        ) : (
          <ActionMenu options={actions} />
        )}
      </TableCell>
    </TableRow>
  )
}
