import { CompetitionControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionControllerApi'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionMeta = (options?: EntityOptions) => {
  const parsedId = useParsedParam('competitionId')
  const { user } = useFirebase()
  const { ctrl } = useController(CompetitionControllerApi)

  const competitionId = Number(options?.idOrKey) || parsedId
  const entity = useReadEntity({
    key: 'useCompetitionMeta',
    idOrKey: competitionId,
    ...options,
    read: req =>
      user
        ? ctrl({ skipCache: true }).readCompetitionEntrantMeta(req)
        : ctrl().readCompetitionMeta(req)
  })

  return { ...entity, meta: entity.read.data }
}
