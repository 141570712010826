import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'
import { orderBy, shuffle } from 'lodash-es'
import { useState } from 'react'
import { FormResponseEditProps } from '../FormResponseQuestionEdit'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'
import { StyledCheckbox } from '@/components/molecules/input/StyledCheckbox'

export const FormResponseEditCheckboxes = ({
  responseId,
  question
}: FormResponseEditProps) => {
  const {
    response,
    answers,
    createFormAnswer,
    updateFormAnswer,
    removeFormAnswer
  } = useFormResponse({ idOrKey: responseId })

  const answer = answers?.find(it => it.question?.id === question?.id)

  const formOptions = response?._expanded?.formOption?.filter(
    it => question.options?.map(o => o.id)?.includes(it.id)
  )

  const orderedOptions = question.shuffle
    ? orderBy(shuffle(formOptions), ['isOther'], ['desc'])
    : orderBy(formOptions, ['ordinal', 'isOther'], ['asc', 'desc'])

  const otherAnswer = answers?.find(
    it =>
      it.question?.id === question?.id &&
      formOptions?.find(that => that.id === it.option?.id)?.other
  )
  const [otherVal, setOtherVal] = useState(otherAnswer?.value)

  return (
    <FormGroup>
      {orderedOptions?.map(it => {
        const optionAnswer = answers?.find(
          that =>
            that.question?.id === question?.id && that.option?.id === it.id
        )
        return (
          <FormControlLabel
            key={it.id}
            value={it.id}
            label={
              it.other ? (
                <TextField
                  // disable form answer while fake id -1 during creation
                  disabled={!optionAnswer || answer?.id === -1}
                  placeholder={it.name}
                  value={optionAnswer ? otherVal : ''}
                  onChange={evt => setOtherVal(evt.target.value)}
                  onBlur={() =>
                    answer
                      ? updateFormAnswer(optionAnswer?.id!, {
                          value: otherVal
                        })
                      : createFormAnswer({
                          question: question.id!,
                          option: it.id,
                          value: otherVal
                        })
                  }
                  required
                />
              ) : (
                <>{it.name}</>
              )
            }
            control={
              <StyledCheckbox
                // disable form answer while fake id -1 during creation
                disabled={answer?.id === -1}
                checked={!!optionAnswer}
                onChange={evt =>
                  evt.target.checked
                    ? createFormAnswer({
                        question: question.id!,
                        option: Number(it.id),
                        value: it.other ? '' : 'checked'
                      })
                    : removeFormAnswer(optionAnswer?.id!)
                }
              />
            }
          />
        )
      })}
    </FormGroup>
  )
}
