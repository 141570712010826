import { ComponentDynamicHeroBox } from '../../../../../models/types'
import { HeroBox } from './HeroBox'
import { SwipeableContent } from '@/components/molecules/interactive/SwipeableContent'

export const HeroBoxes = ({
  content
}: {
  content: ComponentDynamicHeroBox[]
}) => {
  return content.length > 1 ? (
    <SwipeableContent
      content={content}
      render={(it, key) => <HeroBox multi key={key} {...it} />}
      className={'hero-boxes'}
      dotPosition={20}
    />
  ) : (
    <HeroBox {...content[0]} />
  )
}
