import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useChallonge } from '@/core/hooks/useChallonge'
import Box from '@mui/material/Box'
import { useEffect } from 'react'

export const BracketChallongeStandings = ({
  bracketExternalKey,
  height = 500
}: {
  bracketExternalKey: string
  height?: number
}) => {
  const { cfgChallongeThemeDark } = useFeatures()
  const { ref, url, setConfiguration } = useChallonge()

  // Update challonge configuration
  useEffect(
    () => {
      setConfiguration({
        bracketId: bracketExternalKey,
        themeId: cfgChallongeThemeDark as string,
        showStandings: true
      })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bracketExternalKey, cfgChallongeThemeDark, setConfiguration]
  )
  return (
    <Box
      sx={{
        position: 'relative',
        height: height,
        overflow: 'hidden'
      }}
    >
      <iframe
        frameBorder='0'
        height={height + 160}
        ref={ref}
        src={url.href}
        title='standings'
        width='100%'
      />
    </Box>
  )
}
