import Typography from '@mui/material/Typography'
import { MatchGameResultCommand } from '@rallycry/api-suite-typescript/dist/models/MatchGameResultCommand'

export const MatchScoreNumeric = ({
  score,
  isFirstReport
}: {
  score: MatchGameResultCommand
  isFirstReport?: boolean
}) => {
  const value = (isFirstReport ? score.scores?.[0] : score.scores?.[1]) || 0
  return (
    <Typography variant='h3' align='center'>
      {value}
    </Typography>
  )
}
