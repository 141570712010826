import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { JoinRestriction } from '@rallycry/api-suite-typescript/dist/models/JoinRestriction'
import { TeamJoinRestrictionForm } from './TeamJoinRestrictionForm'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSelect } from '@/components/molecules/input/RcSelect'
import { RcTextInput } from '@/components/molecules/input/RcTextInput'
import { RcToggle } from '@/components/molecules/input/RcToggle'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { PageForm } from '@/components/organisms/page/PageForm'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useUserSelfCommunities } from '@/entity/user/useUserSelfCommunities'

export const TeamSettings = () => {
  const { isSoloCompetition, isCompetitionModerator } = useCompetition()
  const { team, update } = useCompetitionTeam()
  const { getMemberships } = useUserSelfCommunities()

  const isTeamOwner = !!team?._links?._delete || isCompetitionModerator
  const showCommunity =
    team?._expanded?.community?.[0]?.kind !== CommunityKind.TEAM

  const { alternateName, rank, lookingForMore, abbreviation, initialRating } =
    team!

  // t('competition:description-context-solo')
  // t('competition:description-context-team')
  return (
    <Stack direction='column' spacing={8}>
      <RcTextInput
        property='alternateName'
        source={{ alternateName }}
        update={value => update(team?.id!, value)}
        label={<RcTrans i18nKey='competition:team.name-label' />}
        description={<RcTrans i18nKey='competition:team.name-context' />}
        disabled={
          !team?._links?.update ||
          (isSoloCompetition && !isCompetitionModerator)
        }
        required
      />
      {showCommunity ? (
        <RcSelect
          property='representing'
          source={{ representing: team?.representing?.id || 0 }}
          update={value => update(team?.id!, value)}
          label={<RcTrans i18nKey='shared.community' />}
          description={<RcTrans i18nKey='competition:community-context' />}
        >
          {getMemberships()?.map(it => (
            <MenuItem key={it.community?.id} value={it.community?.id}>
              <AvatarText src={it.community.image} variant='rounded'>
                {it.community?.name}
              </AvatarText>
            </MenuItem>
          ))}
        </RcSelect>
      ) : null}

      <RcTextInput
        property='abbreviation'
        source={{ abbreviation: abbreviation || '' }}
        defaultValue=''
        update={value =>
          update(team?.id!, {
            abbreviation: value.abbreviation ? value.abbreviation : undefined,
            clearAbbreviation: !value.abbreviation
          })
        }
        label={<RcTrans i18nKey='competition:settings.abbreviation-label' />}
        description={
          <RcTrans i18nKey={'competition:settings.abbreviation-context'} />
        }
      />

      {isTeamOwner && !isSoloCompetition ? <TeamJoinRestrictionForm /> : null}

      {!isSoloCompetition ? (
        <PageForm
          title={<RcTrans i18nKey='competition:team.looking-for-more' />}
          description={
            team?.joinRestriction === JoinRestriction.INVITEONLY ? (
              <RcTrans i18nKey='competition:team.looking-for-more-disabled' />
            ) : (
              <RcTrans i18nKey='competition:team.looking-for-more-description' />
            )
          }
          sideChild
        >
          <RcToggle
            disabled={team?.joinRestriction === JoinRestriction.INVITEONLY}
            property={'lookingForMore'}
            source={{ lookingForMore: lookingForMore }}
            update={value => update(team?.id!, value)}
          />
        </PageForm>
      ) : null}

      {isCompetitionModerator ? (
        <RcSelect
          property='rank'
          source={{ rank: rank || 0 }}
          update={value =>
            update(
              team?.id!,
              value.rank > 0 ? { rank: value.rank } : { clearRank: true }
            )
          }
          label={<RcTrans i18nKey='competition:team.rank-label' />}
        >
          {Array.from(Array(13).keys()).map(value => (
            <MenuItem key={value} value={value}>
              {value === 0 ? (
                <RcTrans i18nKey='shared.unranked' />
              ) : (
                <RcTrans i18nKey={`shared.rank-${value}`} />
              )}
            </MenuItem>
          ))}
        </RcSelect>
      ) : null}

      <ModalConfiguration
        title={<RcTrans i18nKey='competition:team.settings-title' />}
      />
    </Stack>
  )
}
