import { MatchNoteControllerApi } from '@rallycry/api-suite-typescript'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useCompetition } from '../competition/useCompetition'

export const useMatchNote = (options?: EntityOptions) => {
  const id = useParsedParam('matchId')
  const { isCompetitionModerator } = useCompetition()
  const { ctrl } = useController(MatchNoteControllerApi)

  const entity = useReadEntity({
    key: `useMatchNote`,
    idOrKey: id,
    paused: !isCompetitionModerator,
    ...options,
    read: req => ctrl().readMatchNote(req),
    suspense: false
  })

  const save = async (note: string) => {
    await ctrl().saveMatchNote({
      id: id!,
      MatchNoteCommand: {
        value: note
      }
    })
    await entity.read.mutate()
  }

  const remove = async () => {
    if (!entity?.read?.data?.id) return
    await ctrl().removeMatchNote({
      id: entity?.read?.data?.id
    })
    await entity.read.mutate(undefined, { revalidate: false })
  }

  const hasNote = id
  return {
    ...entity,
    hasNote,
    note: hasNote ? entity.read.data : undefined,
    save,
    remove
  }
}
