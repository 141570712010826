'use client'

import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { SUPPORTED_EXTERNAL_NETWORKS } from '@/components/pages/Competition/components/bracket/BracketCreateEdit/BracketCreateEditForm'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import { sneakySean } from '@/core/animations'
import { expander } from '@/core/expand'
import { getUserDisplayName } from '@/core/utils'
import { useCompetitionTeamContactAccounts } from '@/entity/competition-team/useCompetitionTeamContactAccounts'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useGateway } from '@/entity/site/useGateway'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useUserAchievements } from '@/entity/user/useUserAchievements'
import { useUserContactAccounts } from '@/entity/user/useUserContactAccounts'
import { useUserProfile } from '@/entity/user/useUserProfile'
import { useUserTopCommunities } from '@/entity/user/useUserTopCommunities'
import { useUserTopGames } from '@/entity/user/useUserTopGames'
import { ContactFlowLink } from '@/flows/Site/ContactFlow/ContactFlowLink'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import {
  CommunityKind,
  CommunityMemberResource
} from '@rallycry/api-suite-typescript'
import { CommunityDomainResource } from '@rallycry/api-suite-typescript/dist/models/CommunityDomainResource'
import { CommunityResource } from '@rallycry/api-suite-typescript/dist/models/CommunityResource'
import { ContactAccountResource } from '@rallycry/api-suite-typescript/dist/models/ContactAccountResource'
import { GameResource } from '@rallycry/api-suite-typescript/dist/models/GameResource'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { ProfileGameResource } from '@rallycry/api-suite-typescript/dist/models/ProfileGameResource'
import { SidType } from '@rallycry/api-suite-typescript/dist/models/SidType'
import { groupBy, keyBy, merge, some, values } from 'lodash-es'
import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { CommunityAvatar } from '../avatar/CommunityAvatar'
import { GameAvatar } from '../avatar/GameAvatar'
import { ModalConfiguration } from '../modal/ModalConfiguration'
import { PageHeader } from '../page/PageHeader'
import { PageItem } from '../page/PageItem'
import { ArchiveUser } from './ArchiveUser'
import { ManageEmailTrigger } from './ManageEmailTrigger'
import { ManageUserAccessGroupsButton } from './ManageUserAccessGroupsButton'
import { UserFormResponses } from './UserFormResponses'
import { UserNetwork } from './UserNetwork'
import { UserProfileAchievement } from './UserProfileAchievement'

export interface UserViewerProps {
  userId?: number
  showBorders?: boolean
  isModal?: boolean
}

export const UserViewer: React.FC<UserViewerProps> = ({
  userId,
  showBorders,
  isModal
}) => {
  const { setImpersonation } = useImpersonation()
  const { isRootAdmin } = useGateway()
  const { isOrgSuperAdmin, isOrgLimitedAdmin, isOrgModerator, organization } =
    useOrganization()
  const { account } = useUserAccount()
  const { featHideCommunity } = useFeatures()
  const { user } = useUserProfile({ idOrKey: userId })
  const { contactAccounts, refresh } = useUserContactAccounts({
    idOrKey: userId
  })
  const { achievements } = useUserAchievements({
    request: { userId: user?.id! }
  })
  const { contacts: teamAccounts } = useCompetitionTeamContactAccounts()
  const { topCommunities } = useUserTopCommunities({ idOrKey: userId })
  const { topGames } = useUserTopGames({ idOrKey: userId })

  const communitiesByKind = groupBy(
    topCommunities,
    (it: CommunityMemberResource) => {
      const community = expander<CommunityResource>(it, 'community')!
      return community?.kind
    }
  )

  const userSquads: CommunityResource[] =
    communitiesByKind[CommunityKind.TEAM] || []

  const communities: CommunityResource[] =
    communitiesByKind[CommunityKind.BASIC] || []

  const [tab, setTab] = useState(0)

  const hasFullName = !!user?.givenName
  const displayName = getUserDisplayName(user, isOrgLimitedAdmin)

  // external contacts from matches merged with contacts accessible to user via api
  const mergedContacts = merge(
    keyBy(teamAccounts.filter(it => it.user?.id === userId) || [], 'id'),
    keyBy(
      contactAccounts.filter(it => it.network !== NetworkKind.EMAIL) || [],
      'id'
    )
  )

  const sortedContacts = values(mergedContacts)
  const showReport = account && user && account.id !== user.id

  const display = (idx: number, children: React.ReactNode) =>
    idx === tab ? (
      <Stack
        key={idx}
        direction='column'
        spacing={3}
        sx={theme => ({
          paddingX: theme.spacing(6),
          maxWidth: '100%',
          overflowX: 'hidden',
          flexGrow: 1
        })}
      >
        {children}
      </Stack>
    ) : (
      <></>
    )

  const tabs: JSX.Element[] = []
  const items: JSX.Element[] = []
  let idx = 0

  if (some(sortedContacts)) {
    tabs.push(
      <Tab
        key={idx}
        value={idx}
        label={<RcTrans i18nKey='profile:contacts' />}
      />
    )
    items.push(
      display(
        idx,
        sortedContacts.map((it: ContactAccountResource) => (
          <UserNetwork
            key={'network' + it?.id}
            account={it}
            refresh={
              !!it._links?.update &&
              SUPPORTED_EXTERNAL_NETWORKS.includes(it.network!)
                ? () => refresh(it.id!)
                : undefined
            }
          />
        ))
      )
    )
    idx++
  }

  if (!featHideCommunity && some(topCommunities)) {
    tabs.push(
      <Tab
        key={idx}
        value={idx}
        label={<RcTrans i18nKey='shared.communities' />}
      />
    )
    items.push(
      display(
        idx,
        communities.map((it: CommunityDomainResource) => {
          const community = expander<CommunityResource>(it, 'community')!
          return (
            <CommunityAvatar
              key={'community' + community?.id}
              community={community}
            />
          )
        })
      )
    )
    idx++
  }

  if (some(topGames)) {
    tabs.push(
      <Tab key={idx} value={idx} label={<RcTrans i18nKey='profile:games' />} />
    )
    items.push(
      display(
        idx,
        topGames.map((it: ProfileGameResource) => (
          <GameAvatar
            key={'game' + it.id}
            game={expander<GameResource>(it, 'game')}
          />
        ))
      )
    )
    idx++
  }

  if (some(userSquads)) {
    tabs.push(
      <Tab
        key={idx}
        value={idx}
        label={<RcTrans i18nKey='discovery:squads-title' />}
      />
    )
    items.push(
      display(
        idx,
        userSquads.map(it => {
          const community = expander<CommunityResource>(it, 'community')!
          return (
            <CommunityAvatar
              key={'community' + community.id + 1}
              community={community}
            />
          )
        })
      )
    )
    idx++
  }

  if (isOrgSuperAdmin) {
    tabs.push(
      <Tab
        key={idx}
        value={idx}
        label={<RcTrans i18nKey='profile:responses' />}
      />
    )
    items.push(
      display(
        idx,
        <RcSuspense skipLoader>
          <UserFormResponses userId={user?.id} />
        </RcSuspense>
      )
    )
    idx++
  }

  if (isOrgModerator || some(achievements)) {
    tabs.push(
      <Tab
        key={idx}
        value={idx}
        label={<RcTrans i18nKey='shared.achievements-title' />}
      />
    )
    items.push(
      display(
        idx,
        <UserProfileAchievement key={'achievement' + idx} userId={user?.id!} />
      )
    )
    idx++
  }

  const header = (
    <PageHeader
      transparent={!showBorders}
      AvatarProps={{ src: user?.image, variant: 'circular' }}
      name={user?.name}
      description={
        hasFullName ? (
          <Typography variant='subtitle1' color='text.secondary'>
            {displayName}
          </Typography>
        ) : undefined
      }
      actions={
        user?.archived ? null : (
          <>
            {isRootAdmin && user ? (
              <>
                <Box>
                  <ArchiveUser userId={user?.id!} />
                </Box>
                <ManageUserAccessGroupsButton userId={user?.id!} />
                <ManageEmailTrigger userId={user?.id!} />
              </>
            ) : null}

            {(isRootAdmin || isOrgModerator) && user ? (
              <RcIconButton
                sx={sneakySean}
                disabled={user?.ownerType === SidType.COMMUNITYOFFICER}
                onClick={() =>
                  setImpersonation({
                    impersonation: user,
                    organization: isRootAdmin ? null : organization?.id,
                    isProfile: false
                  })
                }
                icon={['fal', 'user-secret']}
                TooltipProps={{
                  arrow: true,
                  title:
                    user?.ownerType === SidType.COMMUNITYOFFICER ? (
                      <RcTrans i18nKey='shared.cannot-impersonate-placeholders' />
                    ) : (
                      <RcTrans i18nKey='shared.impersonate' />
                    )
                }}
              />
            ) : null}

            {showReport ? (
              <ContactFlowLink initial='report' reportedPlayer={user}>
                <RcIconButton
                  icon={['fal', 'message-exclamation']}
                  TooltipProps={{
                    title: <RcTrans i18nKey='shared.report-player' />
                  }}
                />
              </ContactFlowLink>
            ) : null}
          </>
        )
      }
    >
      {user?.archived ? null : (
        <Tabs
          variant='scrollable'
          textColor='inherit'
          indicatorColor='secondary'
          value={tab}
          onChange={(_, v) => setTab(v)}
          sx={{ '& .MuiTab-root': { mx: 2 } }}
        >
          {tabs}
        </Tabs>
      )}
    </PageHeader>
  )

  return (
    <>
      {user?.archived ? null : (
        <PageItem
          contained={!isModal}
          sx={{
            border: showBorders ? undefined : 'none',
            borderRadius: 0,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <ModalConfiguration
            key={new Date()?.toDateString()}
            subtitle={header}
          />

          <Box
            sx={theme => ({
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              pb: 4,
              height: '100%'
            })}
          >
            <SwipeableViews
              index={tab}
              onChangeIndex={setTab}
              style={{
                WebkitOverflowScrolling: 'touch',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              {items}
            </SwipeableViews>
          </Box>
        </PageItem>
      )}
    </>
  )
}
