import Stack from '@mui/material/Stack'
import { take } from 'lodash-es'
import React, { useState } from 'react'
import { JoinFlowContext } from './join-flow-machine'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcPaperButton } from '@/components/molecules/interactive/RcPaperButton'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { TranslationNamespace } from '@/core/translation'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useCommunityMembers } from '@/entity/community/useCommunityMembers'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

interface SetMembersProps {
  context: JoinFlowContext
  onSelected: (values: JoinFlowContext) => void
  onBack?: () => void
}

export const SetMembers: React.FC<SetMembersProps> = ({
  context,
  onSelected,
  onBack
}) => {
  const { t } = useRcTranslation([TranslationNamespace.Competition])
  const { account } = useUserAccount()
  const { settings } = useCompetition()
  const min = settings?.teamSizeMin || 0
  const max = settings?.teamSizeMax || 0

  const communityMembers = useCommunityMembers({
    idOrKey: context.representing
  })
  const { members, hasMore, query, totalElements } = communityMembers
  const { isValidating, size, setSize } = query

  const membersWithoutSelf = members?.filter(
    it => it.member?.id !== account?.id
  )

  // use a local state to allow selection prior to hitting "next"
  const [selected, setSelected] = useState<number[]>(
    context.selectedMembers ||
      take(membersWithoutSelf, min - 1).map(it => it.member?.id!)
  )

  const current = selected.length + 1 // +1 for self
  const isAtMin = current >= min
  const isAtMax = current === max

  return (
    <>
      <Stack direction='column' spacing={2} pt={3}>
        <RcPaperButton disabled selected>
          <UserAvatar disableClick user={account!} textColor={'inherit'} />
        </RcPaperButton>

        {membersWithoutSelf?.map(it => {
          return (
            <RcPaperButton
              key={it.id}
              onClick={() => {
                setSelected(previous => {
                  if (previous.includes(it.member?.id!)) {
                    return previous.filter(id => id !== it.member?.id!)
                  } else {
                    return [...previous, it.member?.id!]
                  }
                })
              }}
              disabled={isAtMax && !selected.includes(it.member?.id!)}
              selected={selected.includes(it.member?.id!)}
            >
              <UserAvatar
                disableClick
                user={it.member!}
                textColor={
                  selected.includes(it.member?.id!)
                    ? 'inherit'
                    : 'text.secondary'
                }
              />
            </RcPaperButton>
          )
        })}
      </Stack>
      <ModalConfiguration
        title={<RcTrans i18nKey='competition:join-flow.set-members-title' />}
        subtitle={
          isAtMax ? (
            <RcTrans i18nKey='competition:join-flow.set-members-at-max' />
          ) : isAtMin ? (
            t('competition:join-flow.set-members-in-range', {
              count: current,
              remaining: max - min + current
            })
          ) : (
            t('competition:join-flow.set-members-need-more', {
              count: current,
              additional: min - current
            })
          )
        }
        topAction={onBack}
      >
        <RcButton
          disabled={!isAtMin}
          variant='contained'
          onClick={() =>
            onSelected({
              selectedMembers: selected
            })
          }
          fullWidth
        >
          <RcTrans i18nKey='shared.continue' />
        </RcButton>
      </ModalConfiguration>
    </>
  )
}
