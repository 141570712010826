import { useState } from 'react'
import { FormResponseMultiPage } from '@/components/pages/CuratedForms/Response/FormResponseMultiPage'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'

export const CompetitionIssueAlertTrigger = ({
  activation,
  onComplete,
  competitionFormId,
  responseId
}: {
  activation: (handleOpen: () => void) => React.ReactNode
  onComplete?: () => void
  competitionFormId: number
  responseId?: number
}) => {
  const [internalId, setInternalId] = useState(responseId)
  const { createCompetitionFormResponse } = useFormResponse()

  const handleOpen = async (openModal: () => any) => {
    // if the user has not already started a response, create one before opening modal
    // to begin working on the draft.
    if (!internalId) {
      const res = await createCompetitionFormResponse({ competitionFormId })
      setInternalId(res?.id)
    }

    openModal()
  }

  return (
    <ModalTrigger
      activation={openModal => activation(() => handleOpen(openModal))}
      onClose={onComplete}
    >
      {({ handleClose }) => (
        <FormResponseMultiPage
          responseId={internalId}
          onComplete={handleClose}
        />
      )}
    </ModalTrigger>
  )
}
