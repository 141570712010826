import Stack from '@mui/material/Stack'
import { useParams } from 'next/navigation'
import { TeamDetails } from './TeamDetails'
import { TeamHeader } from './TeamHeader'
import { TeamOverview } from './TeamOverview'
import { TeamPendingInvite } from './TeamPendingInvite'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RouteParamOverrideProvider } from '@/components/providers/site/RouteParamOverrideProvider'
import { useIsTablet } from '@/core/hooks/useMediaQueries'
import { TeamInviteCode } from '@/components/atoms/TeamInviteCode'

export const TeamDisplay = ({
  team,
  condensed
}: {
  team?: number
  condensed?: boolean
}) => {
  const slug = useParams().slug?.[0]
  const teamId = team?.toString() || slug
  const isTablet = useIsTablet()
  const shouldCondense = isTablet || condensed

  return (
    <RouteParamOverrideProvider context={{ teamId }}>
      <TeamInviteCode />

      <Stack direction='column' spacing={3}>
        <TeamHeader condensed={shouldCondense} />

        <RcSuspense skipError skipLoader>
          <TeamPendingInvite />
        </RcSuspense>

        <RcSuspense skipError skipLoader>
          <TeamOverview condensed={shouldCondense} />
          <TeamDetails condensed={shouldCondense} />
        </RcSuspense>
      </Stack>
    </RouteParamOverrideProvider>
  )
}
