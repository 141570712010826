import { useLayoutEffect, useRef, useState } from 'react'

export const useLeftRightScrolled = (key: any) => {
  const ref = useRef<HTMLDivElement>(null)
  const [refState, setRefState] = useState({
    isStart: false,
    isEnd: false
  })

  const { isStart, isEnd } = refState

  // update react state when scroll events take place. the useRef will not trigger rerenders by itself.
  useLayoutEffect(() => {
    const current = ref.current

    if (!current) return
    const handle = () => {
      setRefState({
        isStart: current?.scrollLeft === 0,
        isEnd:
          current?.scrollWidth! - current?.clientWidth! - 10 <=
          current?.scrollLeft!
      })
    }

    window?.addEventListener('resize', handle, false)
    current?.addEventListener('scroll', handle, false)
    handle()

    return () => {
      window?.removeEventListener('resize', handle, false)
      current?.removeEventListener('scroll', handle, false)
    }
  }, [key])

  return { ref, isStart, isEnd }
}
