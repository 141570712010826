import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { AssignmentSubmissionsResource } from '@rallycry/api-suite-typescript/dist/models/AssignmentSubmissionsResource'
import { Expanded } from '@rallycry/api-suite-typescript/dist/models/Expanded'
import { some } from 'lodash-es'
import React, { useState } from 'react'
import { SubmissionsDisplayTable } from './SubmissionsDisplayTable'
import { useCompetition } from '@/entity/competition/useCompetition'
import { expand, expandById, ExpansionType } from '@/core/expand'
import { TeamAvatar } from '@/components/organisms/avatar/TeamAvatar'
import {
  RcTableBodyRow,
  RcTableCell
} from '@/components/molecules/text/RcTable'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { useBracket } from '@/entity/bracket/useBracket'
import { useIsMobile } from '@/core/hooks/useMediaQueries'

export const LeaderboardTableRow: React.FC<{
  rank: number
  isCutoff?: boolean
  isFinal?: boolean
  resource: AssignmentSubmissionsResource & {
    totalPoints: number
    _expanded?: Expanded
  }
}> = ({ rank, isCutoff, isFinal, resource }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { isCompetitionModerator } = useCompetition()
  const { bracketSettings } = useBracket()
  const isMobile = useIsMobile()
  const assignment = expandById(
    resource.assignment?.id,
    resource._expanded,
    ExpansionType.BracketAssignment
  )
  const team = expand(
    assignment,
    resource._expanded,
    ExpansionType.CompetitionEntry
  )!
  const community = expand(
    team,
    resource._expanded,
    ExpansionType.CompetitionCommunity
  )

  return (
    <RcTableBodyRow
      onClick={() => setIsExpanded(e => !e)}
      sx={{ cursor: 'pointer' }}
      className={isCutoff ? undefined : 'cutoff'}
    >
      <RcTableCell>
        <Grid container direction='row' alignItems='center'>
          <Grid item>
            <Stack direction='row' spacing={3} alignItems='center'>
              <Typography
                variant={isCutoff ? 'h3' : 'h1'}
                sx={{ minWidth: 25 }}
              >
                {some(resource.submissions) ? rank : ''}
              </Typography>
              <TeamAvatar
                team={{ ...team, rank: isFinal && !isCutoff ? rank : 0 }}
                community={{
                  ...community,
                  additionalOrganizations:
                    resource._expanded?.additionalOrganization
                }}
                modal
              />
            </Stack>
          </Grid>
          <Grid item xs>
            <Typography
              variant={isMobile ? 'body1' : 'h2'}
              align='right'
              noWrap
            >
              <RcTrans
                i18nKey='shared.points'
                tOptions={{ points: resource.totalPoints?.toLocaleString() }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Collapse
          in={isExpanded}
          mountOnEnter={isCompetitionModerator}
          unmountOnExit={isCompetitionModerator}
          onClick={e => e.stopPropagation()}
        >
          <RcSuspense skipLoader>
            <SubmissionsDisplayTable
              submissions={resource.submissions}
              team={team}
              bracketSettings={bracketSettings}
            />
          </RcSuspense>
        </Collapse>
      </RcTableCell>
    </RcTableBodyRow>
  )
}
