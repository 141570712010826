import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { FormQuestionKind } from '@rallycry/api-suite-typescript/dist/models/FormQuestionKind'
import { filter, find, first, isEmpty, last, some } from 'lodash-es'
import { useState } from 'react'
import { FormResponseQuestionEdit } from './FormResponseQuestionEdit'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { useModal } from '@/components/organisms/modal/ModalProvider'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useCuratedForm } from '@/entity/curated-forms/useCuratedForm'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'

export const FormResponseMultiPage = ({
  responseId,
  onComplete
}: {
  responseId?: number
  onComplete?: () => any
}) => {
  const { isInModalContext } = useModal()
  const { response, answers, completeFormResponse } = useFormResponse({
    idOrKey: responseId
  })

  const { form, sections, questions, options } = useCuratedForm({
    idOrKey: response?.form?.id,
    paused: !response?.form?.id
  })

  const [history, setHistory] = useState([first(sections)?.id!])
  const section = sections?.find(it => it.id === last(history))
  const sectionQuestions = filter(
    questions,
    it =>
      it.section?.id === section?.id && it.kind !== FormQuestionKind.APPROVAL
  )

  const assignment = sectionQuestions.find(
    it => it.kind === FormQuestionKind.ASSIGNMENT
  )

  // if there's an assignment question, thats the only thing the user needs to fill out
  const filteredQuestions = assignment ? [assignment] : sectionQuestions

  const sectionQuestionIds = filteredQuestions.map(it => it.id)
  const sectionOptionsIds = filter(options, it =>
    sectionQuestionIds.includes(it.question?.id)
  ).map(it => it.id)
  const nextOption = find(
    options,
    it =>
      !!it.next &&
      sectionOptionsIds.includes(it.id) &&
      !!answers?.find(answer => answer.option?.id === it.id)
  )

  const requiredQuestions = filter(filteredQuestions, it => !!it.required)

  const missingQuestions = filter(requiredQuestions, question => {
    const answer = answers?.find(answer => answer.question?.id === question.id)
    return !(answer?.option?.id || answer?.value || answer?.complete)
  })

  const notice = response?._expanded?.competitionForm?.[0]?.notice

  const isSectionComplete = isEmpty(missingQuestions)

  const nextSectionId = nextOption?.next?.id || section?.next?.id!
  const isSubmit = !nextSectionId
  const hasHistory = history.length > 1

  const handleSubmit = async () => {
    await completeFormResponse()
    onComplete?.()
  }

  const handleBack = () =>
    setHistory(h => {
      h.pop()
      return [...h]
    })

  const handleGoto = (id: number) => setHistory(h => [...h, id])

  return (
    <Stack direction='column' spacing={3}>
      <Stack direction='column' spacing={5}>
        {notice && !hasHistory ? (
          <Typography
            sx={{
              whiteSpace: 'pre-line'
            }}
          >
            {notice}
          </Typography>
        ) : null}
        <SectionHeader
          title={section?.name}
          subtitle={section?.disambiguatingDescription}
        />
        {filteredQuestions?.map(it => (
          <FormResponseQuestionEdit
            key={it.id!}
            responseId={responseId}
            question={it}
          />
        ))}
      </Stack>

      <ModalConfiguration
        title={form?.name}
        topAction={hasHistory ? handleBack : undefined}
      >
        {hasHistory && !isInModalContext ? (
          <RcButton variant='outlined' onClick={handleBack} fullWidth>
            <RcTrans i18nKey='shared.back' />
          </RcButton>
        ) : null}

        <Tooltip
          title={
            some(missingQuestions) ? (
              <RcTrans
                i18nKey='shared.responses-missing'
                tOptions={{
                  missing: missingQuestions?.map(it => it.name)?.join(', ')
                }}
              />
            ) : (
              ''
            )
          }
        >
          <div>
            {isSubmit ? (
              <RcButton
                onClick={handleSubmit}
                disabled={!isSectionComplete}
                fullWidth
              >
                <RcTrans i18nKey='shared.submit' />
              </RcButton>
            ) : (
              <RcButton
                onClick={() => handleGoto(nextSectionId)}
                disabled={!isSectionComplete}
                fullWidth
              >
                <RcTrans i18nKey='shared.next' />
              </RcButton>
            )}
          </div>
        </Tooltip>
      </ModalConfiguration>
    </Stack>
  )
}
