import {
  ReadUserFamilyRequest,
  UserControllerApi
} from '@rallycry/api-suite-typescript/dist/apis/UserControllerApi'
import { UserChildCreateCommand } from '@rallycry/api-suite-typescript/dist/models/UserChildCreateCommand'
import { UserAccountControllerApi } from '@rallycry/api-suite-typescript/dist/apis/UserAccountControllerApi'
import { UserAccountUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/UserAccountUpdateCommand'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserMeta } from './useUserMeta'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserFamilyMembers = (
  options?: EntityOptions<ReadUserFamilyRequest>
) => {
  const { user } = useFirebase()
  const { ctrl } = useController(UserControllerApi)
  const { ctrl: accountCtrl } = useController(UserAccountControllerApi)
  const { read: meta } = useUserMeta()

  const entity = useQueryEntity({
    key: 'useUserFamilyMembers',
    expand: '',
    paused: !user,
    metas: [meta],
    ...options,
    request: {},
    read: (req, { personal }) =>
      ctrl({ metas: { personal } }).readUserFamily(req)
  })

  const create = async (cmd: UserChildCreateCommand) => {
    const response = await ctrl({ skipImpersonation: true }).createChild({
      UserChildCreateCommand: cmd
    })
    if (meta.data?.personal) {
      meta.mutate({ ...meta.data, personal: meta.data.personal + 1 }, false)
    }
    return response
  }

  const update = async (id: number, cmd: UserAccountUpdateCommand) =>
    accountCtrl().updateUserAccountById({ id, UserAccountUpdateCommand: cmd })

  return {
    ...entity,
    create,
    update,
    parent: entity.flat.find(it => !it.parent),
    // can remove sort or this entire property when this is merged and live for a while
    // (it's http cached)  https://github.com/rallycry/api-suite/pull/1167
    family: entity.flat.sort((a, b) =>
      a.parent ? 1 : b.parent ? -1 : a.id! - b.id!
    )
  }
}
