import { Theme } from '@mui/material'
import { elevateColor } from '@/style/palette'

export const stickyCellSx = (theme: Theme) => ({
  position: 'sticky',
  left: 0,
  padding: 0,
  paddingLeft: '0 !important',
  backgroundColor: elevateColor(theme.palette.background.paper, 1),
  borderBottom: `0px !important`
})
export const stickyCellInnerSx = (theme: Theme, elevation: number) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingX: theme.spacing(1),
  paddingY: theme.spacing(4),
  borderTopLeftRadius: 15,
  borderBottomLeftRadius: 15,
  backgroundColor: elevateColor(theme.palette.background.paper, elevation),
  minWidth: { xs: colWidthXs, sm: colWidthSm }
})
export const contentCellSx = (theme: Theme) => ({
  borderBottom: `0px !important`
})
export const colWidthXs = 225
export const colWidthSm = 275
