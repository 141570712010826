import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { CompetitionEntryDocument } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryDocument'
import Box from '@mui/material/Box'
import {
  IdResource,
  InlineCommunity
} from '@rallycry/api-suite-typescript/dist/models'
import React from 'react'
import { NavigationLink } from '../navigation/NavigationLink'
import { Trophy } from '../site/Trophy'
import { ModalTrigger } from '../modal/ModalTrigger'
import {
  AvatarText,
  AvatarTextProps
} from '@/components/molecules/text/AvatarText'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { TeamCountDisplay } from '@/components/pages/Competition/components/registration/TeamCountDisplay'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionTeamImage } from '@/entity/competition-team/useCompetitionTeamImage'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useNavigation } from '@/core/hooks/useNavigation'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'
import { useIsTablet } from '@/core/hooks/useMediaQueries'
import { TeamDisplay } from '@/components/pages/Competition/components/team/_TeamDisplay'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'
import { RouteParamOverrideProvider } from '@/components/providers/site/RouteParamOverrideProvider'
import { TeamNoteIcon } from '@/components/pages/Competition/components/team/note/TeamNoteIcon'

interface TeamAvatarProps extends AvatarTextProps {
  team?: CompetitionEntryDocument & { note?: IdResource }
  community?: InlineCommunity
  showLfm?: boolean
  rank?: number
  showMemberCount?: boolean
  skipLink?: boolean
  skipCommunity?: boolean
  modal?: boolean
  rightAlign?: boolean
  lineClamp?: number
  onRefresh?: () => void
}

/*
 * Displays teams with lfm and rank
 */
export const TeamAvatar = ({
  team,
  community,
  showLfm,
  showMemberCount,
  skipLink,
  skipCommunity,
  modal,
  rightAlign,
  lineClamp = 2,
  onRefresh,
  ...rest
}: TeamAvatarProps) => {
  const isTablet = useIsTablet()
  const { featHideCommunity } = useFeatures()
  const { settings, isSingleRestrictedCommunity, isSoloCompetition } =
    useCompetition()
  const { getPath, navTo } = useNavigation()

  const image = useCompetitionTeamImage({
    isSingleRestrictedCommunity,
    isSoloCompetition,
    leader: team?.leader,
    representing: community
  })

  const content = (
    <AvatarText src={image} rightAlign={rightAlign} variant='rounded' {...rest}>
      <Stack direction={rightAlign ? 'row-reverse' : 'row'} alignItems='center'>
        <EllipsisBox lineClamp={1}>
          <Typography
            variant={rest.size === 'xs' ? 'body1' : 'body0'}
            flex='center'
            textAlign={rightAlign ? 'right' : 'left'}
          >
            {team?.alternateName || (
              <RcTrans i18nKey='competition:match.to-be-determined' />
            )}
          </Typography>
        </EllipsisBox>

        {team?.rank && team?.rank < 5 ? (
          <Box sx={{ ml: 2 }}>
            <Trophy size='sm' placement={team?.rank} />
          </Box>
        ) : null}

        {showLfm && team?.lookingForMore ? (
          <Tooltip
            enterDelay={0}
            arrow
            placement='top'
            title={<RcTrans i18nKey='competition:team.looking-for-more' />}
          >
            <div>
              <RcIcon icon={['fad', 'pennant']} size='sm' color='secondary' />
            </div>
          </Tooltip>
        ) : null}
      </Stack>
      {!isTablet && community && !featHideCommunity && !skipCommunity ? (
        <Typography
          variant='subtitle2'
          color='text.secondary'
          textAlign={rightAlign ? 'right' : 'left'}
        >
          {community?.name}
        </Typography>
      ) : null}
      {showMemberCount ? (
        <Stack direction='row' spacing={3}>
          <TeamCountDisplay
            current={team?.membersCount}
            min={settings?.teamSizeMin}
            max={settings?.teamSizeMax}
            inline
          />
        </Stack>
      ) : null}
    </AvatarText>
  )

  return skipLink ? (
    content
  ) : modal ? (
    <PreventParentClick>
      <ModalTrigger
        modalProps={{
          noHeader: true,
          noFooter: true,
          noPadding: true,
          maxWidth: 'md'
        }}
        onClose={onRefresh}
        onExpand={() => {
          navTo({
            root: RootRoute.Competition,
            rootId: team?.competition?.id,
            subRoute: CompetitionRoute.Team,
            subId: team?.id
          })
        }}
        activation={handleOpen => (
          <Box sx={{ cursor: 'pointer' }} onClick={handleOpen}>
            {content}
          </Box>
        )}
      >
        {() => <TeamDisplay team={team?.id} condensed />}
      </ModalTrigger>
    </PreventParentClick>
  ) : (
    <NavigationLink
      color='inherit'
      underline='none'
      scroll={true}
      to={getPath({
        root: RootRoute.Competition,
        rootId: team?.competition?.id,
        subRoute: CompetitionRoute.Team,
        subId: team?.id
      })}
    >
      {content}
    </NavigationLink>
  )
}
