import Box from '@mui/material/Box'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@mui/styles'

export const CheckedboxUncheckedIcon = () => {
  return (
    <Box
      className='fa-stack'
      sx={{
        p: 0,
        m: 0,
        verticalAlign: 'top',
        fontSize: '11px',
        height: '2.5em'
      }}
    >
      <FontAwesomeIcon icon={['fal', 'square']} className='fa-stack-2x' />
    </Box>
  )
}
export const CheckedboxCheckedIcon = () => {
  const theme = useTheme()
  return (
    <Box
      className='fa-stack'
      sx={{
        p: 0,
        m: 0,
        verticalAlign: 'top',
        fontSize: '11px',
        height: '2.5em'
      }}
    >
      <FontAwesomeIcon
        icon={['fas', 'square']}
        color={theme.palette.primary.main}
        className='fa-stack-2x'
      />
      <FontAwesomeIcon
        icon={['fas', 'check']}
        color='#FFF'
        className='fa-stack-1x'
      />
    </Box>
  )
}

export const CheckboxIndeterminateIcon = () => {
  const theme = useTheme()
  return (
    <Box
      className='fa-stack'
      sx={{
        p: 0,
        m: 0,
        verticalAlign: 'top',
        fontSize: '11px',
        height: '2.5em'
      }}
    >
      <FontAwesomeIcon
        icon={['fas', 'square']}
        color={theme.palette.primary.main}
        className='fa-stack-2x'
      />
      <FontAwesomeIcon
        icon={['fas', 'minus']}
        color='#FFF'
        className='fa-stack-1x'
      />
    </Box>
  )
}

export const StyledCheckbox = (props: CheckboxProps) => {
  const theme = useTheme()
  return (
    <Checkbox
      icon={<CheckedboxUncheckedIcon />}
      checkedIcon={<CheckedboxCheckedIcon />}
      indeterminateIcon={<CheckboxIndeterminateIcon />}
      {...props}
    />
  )
}
