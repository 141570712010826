'use client'

import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { TranslationNamespace } from '@/core/translation'
import { useMatchSchedulerPoll } from '@/entity/bracket-match/useMatchSchedulerPoll'
import { useUserAccount } from '@/entity/user/useUserAccount'
import Card from '@mui/material/Card'
import {
  CompetitionEventResource,
  EventKind
} from '@rallycry/api-suite-typescript/dist/models'
import { first, uniqBy } from 'lodash-es'
import moment from 'moment-timezone'
import { RcScheduler } from '../../../../../molecules/input/RcScheduler'
import { CHUNK_SIZE, getEventsFromVotes } from './scheduler-utils'

export const AvailabilityCalendar = ({
  onComplete
}: {
  onComplete: () => void
}) => {
  const { user } = useUserAccount()
  const { t } = useRcTranslation([TranslationNamespace.Competition])

  const { query, flat, setAvailability } = useMatchSchedulerPoll()

  const myVotes = first(query.data)?._expanded?.pollVote?.filter(
    it => it.user?.id === user?.id
  )
  const otherVotes = first(query.data)?._expanded?.pollVote?.filter(
    it => it.user?.id !== user?.id
  )

  const optionsWithVotes = flat.filter(it => !!it.voteCount)

  const myUpvotes = optionsWithVotes.filter(
    it => myVotes?.find(v => v.option?.id === it.id && !v.retracted)
  )
  const otherUpvotes = optionsWithVotes.filter(
    it => otherVotes?.filter(v => v.option?.id === it.id && !v.retracted)
  )

  const handleUpdate = async (all: CompetitionEventResource[]) => {
    const chunked = all
      .map(it => {
        const start = moment(it.startDate)
        const end = moment(it.endDate)
        const diff = end.diff(start, 'm')
        const chunks = diff / CHUNK_SIZE
        return Array.from({ length: chunks }, (_, i) => {
          const startDate = start.clone().add(i * CHUNK_SIZE, 'm')
          const endDate = startDate.clone().add(CHUNK_SIZE, 'm')
          return {
            startDate: startDate.toDate(),
            endDate: endDate.toDate()
          }
        })
      })
      .flat()

    const uniqued = uniqBy(chunked, it => it.startDate?.toISOString())

    const newAvailability = uniqued.map(it => it.startDate.toISOString())

    await setAvailability(newAvailability)

    onComplete()
  }

  const expand = first(query.data)?._expanded
  return (
    <Card elevation={0}>
      <RcScheduler
        existing={getEventsFromVotes(myUpvotes, expand, 0, true)}
        backgroundEvents={getEventsFromVotes(otherUpvotes, expand, user?.id)}
        onComplete={({ all }) => handleUpdate(all)}
        kind={EventKind.OTHER}
        title={t('competition:match.reschedule.add-slots-title')}
        subtitle={t('competition:match.reschedule.add-slots-subtitle')}
        label={t('competition:match.reschedule.add-slots-label')}
        submit={t('shared.submit')}
      />
    </Card>
  )
}
