import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { isEmpty, some, uniqBy } from 'lodash-es'
import { FreeAgentSettings } from '../registration/FreeAgentSettings'
import { DependentAccounts } from '@/components/pages/Settings/components/DependentAccounts'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { ExpansionType, expand } from '@/core/expand'
import {
  CompetitionRoute,
  CompetitionTeamsRoute,
  DiscoveryRoute,
  RootRoute
} from '@/core/route-keys'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useUserSelfOrgCommunities } from '@/entity/user/useUserSelfOrgCommunities'
import { JoinFlow } from '@/flows/Competition/JoinFlowXstate/_JoinFlow'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { RcIcon } from '@/components/atoms/RcIcon'

export const CompetitionRegistrationActions = () => {
  const { featDependentAccounts, featEntryCoaches } = useFeatures()
  const { navTo } = useNavigation()
  const { competition, isSoloCompetition, isFreeAgentMode, settings } =
    useCompetition()
  const { isFullParticipant, isFreeAgentParticipant, involvement } =
    useCompetitionParticipant()
  const { user } = useUserAccount()
  const { orgCommunities } = useUserSelfOrgCommunities()
  const { isProfile, stopImpersonation } = useImpersonation()

  const { isRegistrationActive, hasRegistrationStarted, isRosterLocked } =
    useCompetitionEvents()

  const isCompetitionConcluded =
    competition?.state === CompetitionState.CONCLUDED

  if (isCompetitionConcluded || !hasRegistrationStarted) {
    return null
  }

  const avatar = (
    <RcAvatar src={user?.image} sx={{ width: 32, height: 32 }}>
      {user?.name?.charAt(0)?.toLocaleUpperCase()}
    </RcAvatar>
  )

  const getOptions = () => {
    const options: ActionMenuOption[] = []

    // skip showing child profile when feature disabled
    // or on a parent account in parent only mode
    if (!featDependentAccounts || (!settings?.allowChildUsers && !isProfile)) {
      // noop
    }
    // parent only mode but user is on child profile
    else if (!settings?.allowChildUsers && isProfile) {
      options.push({
        key: 'switch-profile-to-parent',
        display: (
          <Stack direction='row' spacing={2} alignItems='center'>
            {avatar}
            <Typography variant='body2'>
              <RcTrans
                i18nKey='competition:registration.switch-to-parent'
                tOptions={{ ...user }}
              />
            </Typography>
          </Stack>
        ),
        skipClose: true,
        action: stopImpersonation
      })
      // early bail out for failure case that requires swapping to parent profile to proceed
      return options
    }
    // child only mode but user is on parent profile
    else if (!settings?.allowParentUsers && !isProfile) {
      options.push({
        key: 'switch-profile-to-child',
        display: (
          <Stack direction='row' spacing={2} alignItems='center'>
            {avatar}
            <Typography variant='body2'>
              <RcTrans
                i18nKey='competition:registration.switch-to-child'
                tOptions={{ ...user }}
              />
            </Typography>
          </Stack>
        ),
        skipClose: true,
        modal: ({ handleClose }) => (
          <DependentAccounts onSelected={handleClose} />
        )
      })
      // early bail out for failure case that requires swapping to child profile to proceed
      return options
    }
    // default case for showing current user + profile picker
    else {
      options.push({
        key: 'view-profile',
        display: (
          <Stack direction='row' spacing={2} alignItems='center'>
            {avatar}
            <Typography variant='body2'>
              <RcTrans
                i18nKey='competition:registration.switch-profile-mode'
                tOptions={{ ...user }}
              />
            </Typography>
          </Stack>
        ),
        skipClose: true,
        modal: ({ handleClose }) => (
          <DependentAccounts onSelected={handleClose} />
        )
      })
    }

    if (isFullParticipant) {
      const participantEntry =
        involvement?.participantOf &&
        expand(
          involvement?.participantOf,
          competition?._expanded,
          ExpansionType.CompetitionEntry
        )
      const coachedEntries = involvement?.leaderOf || []
      const mergedEntries = participantEntry
        ? uniqBy([participantEntry, ...coachedEntries], it => it.id)
        : coachedEntries

      const hasMultipleTeams = mergedEntries?.length > 1

      options.push({
        key: `view-my-teams`,
        display: (
          <Stack direction='column' spacing={2}>
            <Typography variant='body0'>
              {hasMultipleTeams ? (
                <RcTrans
                  i18nKey={`competition:registration.view-teams-title`}
                />
              ) : (
                <RcTrans i18nKey={`competition:registration.view-team-title`} />
              )}
            </Typography>
            <Typography variant='body2'>
              {hasMultipleTeams ? (
                <RcTrans
                  i18nKey={`competition:registration.view-teams-description`}
                />
              ) : (
                <RcTrans
                  i18nKey={`competition:registration.view-team-description`}
                />
              )}
            </Typography>
          </Stack>
        ),
        action: async () =>
          navTo({
            root: RootRoute.Competition,
            rootId: competition?.id,
            subRoute: CompetitionRoute.Team,
            subId: CompetitionTeamsRoute.Mine
          })
      })
    }

    // feature gate joining multiple entries for coach mode
    if (!featEntryCoaches && isFullParticipant) {
      return options
    }

    if (!settings?.entryRepresentingOptional && isEmpty(orgCommunities)) {
      options.push({
        key: 'find-community',
        display: (
          <Typography variant='body2'>
            <RcTrans i18nKey='competition:registration.find-a-community' />
          </Typography>
        ),
        action: async () =>
          navTo({
            root: RootRoute.Discovery,
            subRoute: DiscoveryRoute.Communities
          })
      })

      // early bail out for failure case that requires finding a community
      return options
    }

    const orgIds = orgCommunities.map(it => it.id!)
    const restricted =
      competition?._restrictToCommunities?.map(it => it.id!) || []
    // if the user is not a member of any of the restricted communities, then they cannot register
    if (restricted.length > 0 && !some(orgIds, it => restricted.includes(it))) {
      options.push({
        key: 'restricted-community',
        display: (
          <Typography variant='body2'>
            <RcTrans i18nKey='competition:registration.community-restricted' />
          </Typography>
        ),
        action: async () =>
          navTo({
            root: RootRoute.Discovery,
            subRoute: DiscoveryRoute.Communities
          })
      })

      // early bail out for failure case that requires being in a certain community
      return options
    }

    if (isRegistrationActive && !isRosterLocked) {
      options.push({
        key: 'create-team',
        display: (
          <Stack direction='column' spacing={2}>
            <Typography variant='body0'>
              {isSoloCompetition ? (
                <RcTrans
                  i18nKey={`competition:registration.register-solo-title`}
                />
              ) : (
                <RcTrans
                  i18nKey={`competition:registration.register-team-title`}
                />
              )}
            </Typography>
            <Typography variant='body2'>
              {isSoloCompetition ? (
                <RcTrans
                  i18nKey={`competition:registration.register-solo-description`}
                />
              ) : (
                <RcTrans
                  i18nKey={`competition:registration.register-team-description`}
                />
              )}
            </Typography>
          </Stack>
        ),
        modal: ({ handleClose }) => <JoinFlow onComplete={handleClose} />
      })
    }

    if (!isSoloCompetition && !isRosterLocked && !isFullParticipant) {
      options.push({
        key: 'join-team',
        display: (
          <Stack direction='column' spacing={2}>
            <Typography variant='body0'>
              <RcTrans i18nKey={`competition:registration.join-team-title`} />
            </Typography>
            <Typography variant='body2'>
              <RcTrans
                i18nKey={`competition:registration.join-team-description`}
              />
            </Typography>
          </Stack>
        ),
        action: async () => {
          navTo({
            root: RootRoute.Competition,
            rootId: competition?.id,
            subRoute: CompetitionRoute.Team,
            query: 'scrollTo=true'
          })
        }
      })
    }

    if (
      isFreeAgentMode &&
      !isSoloCompetition &&
      !isRosterLocked &&
      !isFullParticipant
    ) {
      isFreeAgentParticipant
        ? options.push({
            key: 'free-agent-edit',
            display: (
              <Stack direction='column' spacing={2}>
                <Typography variant='body0'>
                  <RcTrans
                    i18nKey={`competition:registration.free-agent-edit-title`}
                  />
                </Typography>
                <Typography variant='body2'>
                  <RcTrans
                    i18nKey={`competition:registration.free-agent-edit-description`}
                  />
                </Typography>
              </Stack>
            ),
            modal: ({ handleClose }) => (
              <FreeAgentSettings onClose={handleClose} />
            )
          })
        : options.push({
            key: 'free-agent-create',
            display: (
              <Stack direction='column' spacing={2}>
                <Typography variant='body0'>
                  <RcTrans
                    i18nKey={`competition:registration.free-agent-create-title`}
                  />
                </Typography>
                <Typography variant='body2'>
                  <RcTrans
                    i18nKey={`competition:registration.free-agent-create-description`}
                  />
                </Typography>
              </Stack>
            ),

            modal: ({ handleClose }) => (
              <JoinFlow freeAgent onComplete={handleClose} />
            )
          })
    }

    return options
  }

  const buttonVariant =
    isFullParticipant ||
    isFreeAgentParticipant ||
    isRosterLocked ||
    (isSoloCompetition && !isRegistrationActive)
      ? 'outlined'
      : 'contained'

  const buttonText = isFullParticipant ? (
    <RcTrans i18nKey='competition:registered-button' />
  ) : isRosterLocked ? (
    <RcTrans i18nKey='competition:rosters-locked' />
  ) : isFreeAgentParticipant ? (
    <RcTrans i18nKey='competition:free-agent-button' />
  ) : (
    <RcTrans i18nKey='competition:register-button' />
  )

  return !user ? (
    <Button
      onClick={() =>
        navTo({
          root: RootRoute.Register
        })
      }
    >
      {buttonText}
    </Button>
  ) : // skip menus when joining as parent for parent only + solo mode
  !settings?.allowChildUsers && !isProfile && isSoloCompetition ? (
    isFullParticipant ? (
      <Button
        variant={buttonVariant}
        onClick={() =>
          navTo({
            root: RootRoute.Competition,
            rootId: competition?.id,
            subRoute: CompetitionRoute.Team,
            subId: CompetitionTeamsRoute.Mine
          })
        }
      >
        {buttonText}
        <RcIcon icon={['fal', 'chevron-down']} ml={2} />
      </Button>
    ) : (
      <ModalTrigger
        activation={handleOpen => (
          <Button
            disabled={!isRegistrationActive || isRosterLocked}
            variant={buttonVariant}
            onClick={handleOpen}
          >
            {buttonText}
          </Button>
        )}
      >
        {({ handleClose }) => <JoinFlow onComplete={handleClose} />}
      </ModalTrigger>
    )
  ) : (
    <ActionMenu
      options={getOptions()}
      buttonProps={{ variant: buttonVariant, sx: {} }}
    >
      {buttonText}
      <RcIcon icon={['fal', 'chevron-down']} ml={2} />
    </ActionMenu>
  )
}
