import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { isEmpty } from 'lodash-es'
import { NoResults } from '@/components/molecules/text/NoResults'
import { FormResponsesRow } from '@/components/pages/CuratedForms/Responses/FormResponsesRow'
import { InfiniteScrollV2 } from '@/components/atoms/InfiniteScrollV2'
import { useFormResponsesByUser } from '@/entity/curated-forms/useFormResponsesByUser'

export const UserFormResponses = ({ userId }: { userId?: number }) => {
  const {
    responses,
    remove,
    hasMore,
    query: { isValidating, size, setSize, mutate }
  } = useFormResponsesByUser({
    request: { user: userId }
  })
  return (
    <>
      <Table>
        <TableBody>
          {responses.map(it => (
            <FormResponsesRow
              key={it.id}
              response={it}
              onComplete={async () => mutate()}
              onRemove={async () => remove(it.id!)}
            />
          ))}
        </TableBody>
      </Table>
      {isEmpty(responses) && !isValidating ? <NoResults /> : null}
      <InfiniteScrollV2
        hasMore={hasMore}
        isLoading={isValidating}
        onLoad={() => setSize(size + 1)}
      />
    </>
  )
}
