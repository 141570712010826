import Typography from '@mui/material/Typography'
import { AdditionalOrganizationResource } from '@rallycry/api-suite-typescript/dist/models/AdditionalOrganizationResource'
import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { CommunityResource } from '@rallycry/api-suite-typescript/dist/models/CommunityResource'
import { OrganizationResource } from '@rallycry/api-suite-typescript/dist/models/OrganizationResource'
import { NavigationLink } from '../navigation/NavigationLink'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import {
  AvatarText,
  AvatarTextProps
} from '@/components/molecules/text/AvatarText'
import { ChipinatorDisplay } from '@/components/molecules/text/Chipinator'
import { ExpansionType, expand, expandById } from '@/core/expand'
import { RootRoute } from '@/core/route-keys'
import { trimTrailingSlash } from '@/core/utils'
import { useConfig } from '@/entity/site/useConfig'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useNavigation } from '@/core/hooks/useNavigation'

export interface CommunityAvatarProps {
  community: CommunityResource
  skipTags?: boolean
  Component?: React.ElementType<any>
  onClick?: () => any
}

/*
 * Avatar with auto navigation to community resource
 */
export const CommunityAvatar = ({
  community,
  skipTags,
  Component,
  onClick,
  ...rest
}: CommunityAvatarProps & AvatarTextProps) => {
  const config = useConfig()
  const { organization } = useOrganization()
  const { imgCommunityAvatar } = useFeatures()
  const { getPath } = useNavigation()

  const defaultAvatarSrc = imgCommunityAvatar as string

  const communityOrg = expand<OrganizationResource>(
    community,
    community?._expanded,
    ExpansionType.Organization
  )

  const additionalOrgRes = community.additionalOrganizations
    ?.map(it =>
      expandById<AdditionalOrganizationResource>(
        it.id,
        community._expanded,
        ExpansionType.CommunityAdditionalOrganizations
      )
    )
    .find(it => it?.organization?.id === organization?.id)

  const additionalOrg = expandById(
    additionalOrgRes?.organization?.id,
    community?._expanded,
    ExpansionType.Organization
  )

  const avatar = (
    <AvatarText
      src={additionalOrgRes?.image || community?.image || defaultAvatarSrc}
      variant='rounded'
      {...rest}
    >
      <Typography
        variant='h4'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 160
        }}
      >
        {community.name}
      </Typography>
      {(additionalOrgRes?.disambiguatingDescription ||
        community.disambiguatingDescription) &&
      !skipTags ? (
        <ChipinatorDisplay
          value={
            additionalOrgRes?.disambiguatingDescription ||
            community.disambiguatingDescription
          }
        />
      ) : null}
    </AvatarText>
  )

  return onClick ? (
    <Link underline='none' style={{ color: 'inherit' }} onClick={onClick}>
      {Component ? <Component tabIndex={-1}>{avatar}</Component> : avatar}
    </Link>
  ) : // only render cross domain links if going to a different organization
  communityOrg?.rootForDomain &&
    communityOrg.id !== organization?.id &&
    !additionalOrgRes ? (
    <Link
      underline='none'
      style={{ color: 'inherit' }}
      href={`https://${trimTrailingSlash(communityOrg.rootForDomain)}${getPath({
        rootMode: true,
        root:
          community.kind === CommunityKind.BASIC
            ? RootRoute.Community
            : RootRoute.Squad,
        rootId: community.id
      })}`}
    >
      {Component ? <Component tabIndex={-1}>{avatar}</Component> : avatar}
    </Link>
  ) : (
    <NavigationLink
      underline='none'
      to={getPath({
        organization: config.rootMode
          ? undefined
          : additionalOrg?.key || communityOrg?.key,
        root:
          community.kind === CommunityKind.BASIC
            ? RootRoute.Community
            : RootRoute.Squad,
        rootId: community.id
      })}
      style={{ color: 'inherit' }}
    >
      {Component ? <Component tabIndex={-1}>{avatar}</Component> : avatar}
    </NavigationLink>
  )
}
