import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { BracketAssignmentResource } from '@rallycry/api-suite-typescript/dist/models/BracketAssignmentResource'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { isUndefined } from 'lodash-es'
import { MatchScoreCheck } from './MatchScoreCheck'
import { MatchScoreNumeric } from './MatchScoreNumeric'
import { RcTrans } from '@/components/atoms/RcTrans'

export const MatchResultRow = ({
  result,
  isNumericMode,
  bracketAssignment1,
  bracketAssignment2
}: {
  result: { ordinal: number; winner: number; scores: number[] }
  isNumericMode?: boolean
  bracketAssignment1: BracketAssignmentResource | undefined
  bracketAssignment2: BracketAssignmentResource | undefined
}) => (
  <Card key={result.ordinal}>
    <CardContent>
      <Grid
        key={result.ordinal}
        container
        item
        direction='row'
        alignItems='center'
      >
        <Grid item xs>
          {isNumericMode ? (
            <MatchScoreNumeric score={result} isFirstReport />
          ) : (
            <MatchScoreCheck score={result} assignment={bracketAssignment1} />
          )}
        </Grid>
        <Grid item xs={6}>
          <Stack direction='column' spacing={1} alignItems='center'>
            <Typography variant='subtitle1' align='center'>
              <RcTrans
                i18nKey='competition:match.game-number'
                tOptions={{
                  ordinal: result.ordinal
                }}
              />
            </Typography>
            <Button
              variant={isUndefined(result.winner) ? 'contained' : 'outlined'}
              size='small'
            >
              <RcTrans i18nKey='competition:match.TIE' />
            </Button>
          </Stack>
        </Grid>
        <Grid item xs style={{ textAlign: 'right' }}>
          {isNumericMode ? (
            <MatchScoreNumeric score={result} />
          ) : (
            <MatchScoreCheck score={result} assignment={bracketAssignment2} />
          )}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
)
