import { Timestamp, doc, onSnapshot, setDoc } from 'firebase/firestore'
import { orderBy } from 'lodash-es'
import useSWRSubscription, { SWRSubscriptionOptions } from 'swr/subscription'
import { EntityOptions } from '@/entity/useEntity'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

export enum SceneKind {
  Background = 'background',
  Countdown = 'countdown',
  PrizingAll = 'prizing-all',
  PrizingTop = 'prizing-top',
  Bracket = 'bracket',
  MultiBox = 'multi-box',
  MatchRoster = 'match-roster',
  MatchSchedule = 'match-schedule',
  MatchTicker = 'match-ticker'
}

export enum BracketFilter {
  Both = 'both',
  Upper = 'upper',
  Lower = 'lower'
}

export interface Scene {
  uid: string
  ordinal: number
  kind: SceneKind
  title: string
  competition?: number
  bracket?: number
  bracketLimit?: number
  spacingY?: number
  bracketFilter?: BracketFilter
  currentMatch?: number
  prevMatch?: number
  nextMatch?: number
  header?: boolean
  headerImage?: string
  background?: boolean
  prizingSmall?: string[]
  prizingLarge?: string[]
  multiboxes?: { title?: string; subtitle?: string }[]
  countdown?: Timestamp
}

interface CompetitionBroadcastDocument {
  id: number
  scenes?: Scene[]
}

export const useCompetitionBroadcasts = (
  options: EntityOptions & {
    userId?: string
    competitionId?: number
    shouldSubscribe?: boolean
  }
) => {
  const { userId, competitionId } = options
  const { firestore } = useFirebase()

  const docKey = `Broadcast/${userId}/competitions/${competitionId}`

  const data = useSWRSubscription(
    [docKey],
    (
      [docKey],
      { next }: SWRSubscriptionOptions<CompetitionBroadcastDocument, Error>
    ) => {
      const ref = doc(firestore, docKey)
      const unsub = onSnapshot(
        ref,
        res => {
          const data = res.data() as CompetitionBroadcastDocument
          next(null, data)
        },
        err => next(err)
      )
      return () => unsub()
    },
    { suspense: false }
  )

  const document = data.data

  const saveScene = async (scene: Scene) => {
    const ref = doc(firestore, docKey)
    await setDoc(ref, {
      ...document,
      scenes: orderBy(
        [
          ...(document?.scenes?.filter(it => it.uid !== scene.uid) || []),
          scene
        ],
        'ordinal'
      )
    })
  }

  const deleteScene = async (scene: Partial<Scene>) => {
    const ref = doc(firestore, docKey)
    await setDoc(ref, {
      ...document,
      scenes: [...(document?.scenes?.filter(it => it.uid !== scene.uid) || [])]
    })
  }

  return { document, saveScene, deleteScene }
}
