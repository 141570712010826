import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  ComponentDynamicQuotes,
  ComponentGenericQuote
} from '../../../../../models/types'
import { SwipeableContent } from '@/components/molecules/interactive/SwipeableContent'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { ImageSize, useCMSFile } from '@/core/hooks/useCMSFile'

export const Quotes = ({ content }: ComponentDynamicQuotes) => {
  return (
    <SwipeableContent
      content={content!}
      render={(it, key) => <Quote key={key} {...it!} />}
    />
  )
}

const Quote = (props: ComponentGenericQuote) => {
  const { getImagePath } = useCMSFile()

  return (
    <Stack
      direction='column'
      spacing={5}
      paddingX={{ md: 15 }}
      justifyContent='center'
      alignItems='center'
      height='100%'
    >
      <Typography variant='h3' textAlign='center'>
        &ldquo;{props.content}&rdquo;
      </Typography>
      <Box>
        <AvatarText
          src={getImagePath(props.userAvatar?.data?.[0]!, ImageSize.Thumbnail)}
        >
          <Typography variant='body0' textAlign='center'>
            {props.userTitle}
          </Typography>
          <Typography
            variant='subtitle1'
            textAlign='center'
            color='text.secondary'
          >
            {props.userSubtitle}
          </Typography>
        </AvatarText>
      </Box>
    </Stack>
  )
}
