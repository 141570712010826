import { ButtonProps } from '@mui/material/Button'
import { last } from 'lodash-es'
import { ComponentGenericActionLink } from '../../../../../models/types'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { FormResponseAdhoc } from '@/components/pages/CuratedForms/Response/FormResponseAdhoc'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { useOrganization } from '@/entity/organization/useOrganization'
import { ContactFlowLink } from '@/flows/Site/ContactFlow/ContactFlowLink'

export const ActionLink = ({
  action,
  ButtonProps,
  children
}: {
  action: ComponentGenericActionLink
  ButtonProps?: ButtonProps
  children?: React.ReactNode
}) => {
  const { profile, getRoutedPath } = useOrganization()
  let color = 'inherit'
  if (
    action?.color &&
    ['primary', 'secondary', 'info'].includes(action.color)
  ) {
    color = action.color
  }

  const path = (action?.path || '')?.replace(
    '{{discordLink}}',
    `${profile?.primaryDiscordInviteUrl}`
  )

  const inner = children || (
    <RcButton
      variant={(action.variant as any) || 'contained'}
      color={color as any}
      {...ButtonProps}
    >
      {action?.title || ''}
    </RcButton>
  )

  return action.iframe ? (
    <ModalTrigger
      modalProps={{ noFooter: true, noPadding: true }}
      activation={handleOpen => (
        <Link underline='none' onClick={handleOpen}>
          {inner}
        </Link>
      )}
    >
      {() => (
        <iframe
          src={action?.path!}
          title={action?.path!}
          style={{ flexGrow: 1, width: '100%', minHeight: 500, border: 0 }}
        />
      )}
    </ModalTrigger>
  ) : action?.path === '{{contactLink}}' ? (
    <ContactFlowLink>{inner}</ContactFlowLink>
  ) : action?.path?.includes('form-') ? (
    <FormResponseAdhoc
      formId={Number(last(action?.path?.split('-'))?.replace('}}', ''))}
      activation={handleOpen => (
        <Link underline='none' onClick={handleOpen}>
          {inner}
        </Link>
      )}
    />
  ) : path.startsWith('http') || path.startsWith('mailto:') ? (
    <Link underline='none' href={path} target='_new' rel='noopener'>
      {inner}
    </Link>
  ) : path ? (
    <NavigationLink underline='none' to={getRoutedPath(path)} style={{ color }}>
      {inner}
    </NavigationLink>
  ) : (
    inner
  )
}
