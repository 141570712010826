import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { BoxProps } from '@mui/material/Box'
import { useState } from 'react'

export interface ImageDisplayProps extends BoxProps {
  path?: string
  cms?: string
  alt?: string
  fileType?: 'png' | 'svg' | 'webm' | 'mp4'
  objectFit?: 'cover' | 'contain'
  variant?: 'text' | 'circular' | 'rectangular'
  width?: string | number
  height?: string | number
  transparent?: boolean
  rounded?: boolean
  lazy?: boolean
}

/**
 * Image loading and display with automatic skeletons
 */
export const ImageDisplay = ({
  path,
  alt,
  lazy = true,
  fileType = 'png',
  objectFit = 'cover',
  variant = 'rectangular',
  width = 50,
  height = 50,
  transparent,
  rounded,
  ...props
}: ImageDisplayProps) => {
  const [error, setError] = useState(false)
  const src = path

  const skeleton = (
    <Skeleton
      sx={{
        width: '100%',
        height: '100%'
      }}
      variant={variant}
    />
  )

  const content = (
    <Box
      width={width}
      height={height}
      minWidth={width}
      minHeight={height}
      borderRadius={rounded ? 1 : undefined}
      overflow='hidden'
      {...props}
    >
      {src ? (
        src.endsWith('mp4') ? (
          <video
            controls
            playsInline
            preload='metadata'
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          >
            <source src={src} type='video/mp4' />
          </video>
        ) : (
          <img
            draggable={false}
            src={src}
            alt={alt}
            width={'100%'}
            height={'100%'}
            onError={() => setError(true)}
            style={{
              display: error ? 'none' : 'block',
              objectFit
            }}
            fetchPriority={lazy ? 'low' : 'high'}
            loading={lazy ? 'lazy' : 'eager'}
          />
        )
      ) : transparent ? null : (
        skeleton
      )}
    </Box>
  )

  return content
}
