import {
  ContactAccountControllerApi,
  ReadAllContactAccountsByUserRequest
} from '@rallycry/api-suite-typescript/dist/apis/ContactAccountControllerApi'
import { UserAccountControllerApi } from '@rallycry/api-suite-typescript/dist/apis/UserAccountControllerApi'
import { VerificationControllerApi } from '@rallycry/api-suite-typescript/dist/apis/VerificationControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserContactAccounts = (
  options?: EntityOptions<ReadAllContactAccountsByUserRequest>
) => {
  const userId = useParsedParam('userId')
  const { ctrl } = useController(ContactAccountControllerApi)
  const { ctrl: accountCtrl } = useController(UserAccountControllerApi)
  const { ctrl: verificationCtrl } = useController(VerificationControllerApi)
  const id = Number(userId || options?.idOrKey)

  const entity = useQueryEntity({
    key: 'useUserContactAccounts',
    expand: 'content{_links}',
    ...options,
    request:
      id || options?.request ? { userId: id, ...options?.request } : undefined,
    read: req =>
      ctrl({
        expectedStatuses: [403, 404],
        fallbackResponse: { content: [] }
      }).readAllContactAccountsByUser(req)
  })

  const updatePrimaryEmail = async (
    originalEmail: string,
    newEmail: string
  ) => {
    await accountCtrl().changePrimaryEmail({
      id,
      ChangePrimaryEmailCommand: { originalEmail, newEmail }
    })
    await entity.query.mutate()
  }

  const removeEmail = async (id: number) => {
    await ctrl().removeSelfContactAccount({ id })
    await entity.query.mutate()
  }

  const forceVerifyEmail = async (
    email: string,
    confirmAdditional?: number
  ) => {
    await verificationCtrl().confirmVerificationByUser({
      userId,
      VerificationConfirmByUserCommand: {
        email,
        confirmAdditional,
        confirmUser: id
      }
    })
    await entity.query.mutate()
  }

  const refresh = async (id: number) => {
    await ctrl().refreshContactAccount({ id })
    await entity.query.mutate()
  }

  return {
    ...entity,
    contactAccounts: entity.flat,
    updatePrimaryEmail,
    removeEmail,
    forceVerifyEmail,
    refresh
  }
}
