import Typography from '@mui/material/Typography'
import { SubmissionScoreBonusResource } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreBonusResource'
import { SubmissionScoreFormat } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreFormat'
import { SubmissionScoreResource } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreResource'
import { sumBy } from 'lodash-es'
import { toOrdinal } from '@/core/utils'

export const SubmissionsDisplayPoints = ({
  resource,
  bonuses,
  score = 0
}: {
  resource: SubmissionScoreResource
  bonuses?: SubmissionScoreBonusResource[]
  score?: number
}) => {
  const bonusPoints = sumBy(
    bonuses?.filter(it => it.min! <= score! && score! <= it.max!),
    it => it.bonus || 0
  )

  /* silly span / br combo to gt tooltips to center correctly */
  return (
    <>
      <Typography variant='body0' noWrap component='span'>
        {resource.format === SubmissionScoreFormat.NORMAL
          ? score?.toLocaleString()
          : toOrdinal(score)}
      </Typography>
      <br />
      <Typography
        variant='subtitle2'
        noWrap
        color='text.secondary'
        component='span'
      >
        {score! >= 0 ? '+' : ''}
        {(score * resource.multiplier! + bonusPoints).toLocaleString()}
      </Typography>
    </>
  )
}
