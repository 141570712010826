import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { AchievementResource } from '@rallycry/api-suite-typescript/dist/models/AchievementResource'
import { CommunityResource } from '@rallycry/api-suite-typescript'
import { ModalConfiguration } from '../modal/ModalConfiguration'
import { NavigationLink } from '../navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcConfetti } from '@/components/molecules/interactive/RcConfetti'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { useNavigation } from '@/core/hooks/useNavigation'
import { RootRoute, SettingsRoute } from '@/core/route-keys'

const DisplayNewAchievements = ({
  achievement,
  community,
  onComplete
}: {
  achievement?: AchievementResource
  community?: CommunityResource
  onComplete: () => void
}) => {
  const { getPath } = useNavigation()
  const link = getPath({
    root: RootRoute.Settings,
    subRoute: SettingsRoute.Achievements
  })

  return (
    <>
      <RcConfetti />
      <ModalConfiguration
        direction='row'
        subtitle={
          <Stack direction='column' alignItems='center' spacing={4} mt={5}>
            <Typography variant='h2' color='text.primary'>
              {community?.name ? (
                <RcTrans
                  i18nKey='shared.community-achievements-title-grant'
                  tOptions={{ community: community?.name }}
                />
              ) : (
                <RcTrans i18nKey='shared.achievements-title-grant' />
              )}
            </Typography>
            <Stack direction='column' alignItems='center' spacing={1}>
              <Typography variant='h3' color='text.primary'>
                {achievement?.name}
              </Typography>
              <Typography variant='body2'>
                {achievement?.description}
              </Typography>
            </Stack>
          </Stack>
        }
        topContent={
          <Box position='relative' py={4}>
            <Box position='absolute' left={142} top={-115}>
              <RcAvatar size='xxl' src={achievement?.image} variant='rounded' />
            </Box>
          </Box>
        }
      >
        <NavigationLink to={link} underline='none' onClick={onComplete}>
          <RcButton variant='contained' color='secondary' fullWidth>
            <RcTrans i18nKey='discovery:achievements-view-button' />
          </RcButton>
        </NavigationLink>

        <RcButton
          variant='contained'
          color='primary'
          fullWidth
          onClick={onComplete}
        >
          <RcTrans i18nKey='discovery:achievements-close-button' />
        </RcButton>
      </ModalConfiguration>
    </>
  )
}

export default DisplayNewAchievements
