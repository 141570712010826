import React, { useCallback, useEffect, useRef, useState } from 'react'

type IntegrationData = Record<number, any>

interface BracketSettings {
  backgroundMediaMIMEType?: string
  backgroundMediaSrc?: string
  collapseCenteringData?: {
    identifier?: number
    yCenter?: number
  }
  compressUnopenedRounds?: boolean
  defaultAnimationDuration?: number
  numCollapsedRounds?: number
  panOnSingleClick?: boolean
  scaleToFit?: boolean
  showDetailsOnHover?: boolean
  tournamentOptionOverrides?: {
    hideSeeds?: boolean
    hideIdentifiers?: boolean
    showStationAndTime?: boolean
  }
  zoomScale?: number
  zoomScaleOnDoubleClick?: boolean
}

interface Coordinates {
  x: number
  y: number
}

interface ChallongeConfiguration {
  bracketId: string
  filteredRounds?: number[]
  integrationData?: IntegrationData
  onLoad?: () => void
  onMatchClicked?: (externalId: string) => void
  onParticipantClicked?: (data: any) => void
  overrideSettings?: BracketSettings
  scaleToFit?: boolean
  showStandings?: boolean
  themeId: string
}

const BASE_URL = 'https://rallycry.challonge.com'

/*
 * Encapsulates Challonge iframe interactions.
 */
export const useChallonge = () => {
  const ref = useRef<any>()
  const [configuration, setConfiguration] = useState<ChallongeConfiguration>()
  const [integrationData, setIntegrationData] = useState<IntegrationData>({})
  const [mounted, setMounted] = useState(false)
  const [bracketHeight, setBracketHeight] = useState(500)
  const animationDuration = 300

  const postMessage = (action: string, data: any) => {
    ref?.current?.contentWindow?.postMessage(
      {
        ChallongeJSAPIAction: action,
        parameters: data
      },
      BASE_URL
    )
  }

  const filterRounds = useCallback((rounds: number[]) => {
    postMessage('filterRounds', { rounds })
  }, [])

  const loadTheme = (theme: string) => {
    postMessage('loadTheme', { theme })
  }

  const loadIntegrationData = useCallback(() => {
    postMessage('loadIntegrationData', {
      participants: JSON.stringify(integrationData),
      linkToParticipantInfo: true,
      suppressModals: true,
      mappingAttribute: 'misc'
    })
  }, [integrationData])

  const scrollToCoordinates = (coordinates: Coordinates) => {
    postMessage('scrollToCoordinates', {
      coordinates,
      animationDuration
    })
  }

  const scrollToMatchIdentifier = (matchIdentifier: number) => {
    postMessage('scrollToMatchIdentifier', {
      matchIdentifier,
      animationDuration
    })
  }

  const setBracketSettings = useCallback((bracketSettings: BracketSettings) => {
    postMessage('setBracketSettings', { bracketSettings })
  }, [])

  const setZoomScale = (zoomScale: number) => {
    postMessage('setZoomScale', {
      zoomScale,
      animationDuration
    })
  }

  const zoomToCoordinates = (zoomScale: number, coordinates: Coordinates) => {
    postMessage('zoomToCoordinates', {
      zoomScale,
      coordinates,
      animationDuration
    })
  }

  const zoomToMatchIdentifier = useCallback(
    (zoomScale: number, matchIdentifier: number, durationOverride?: number) => {
      postMessage('zoomToMatchIdentifier', {
        zoomScale,
        matchIdentifier,
        animationDuration: durationOverride || animationDuration
      })
    },
    []
  )

  // On configuration update
  useEffect(() => {
    if (configuration?.integrationData) {
      setIntegrationData(configuration.integrationData)
    }

    const handleMessage = (event: any) => {
      if (event.origin !== BASE_URL) return

      switch (event?.data?.ChallongeJSAPIEvent) {
        case 'bracketMounted':
          setMounted(true)
          loadIntegrationData()

          if (configuration?.filteredRounds) {
            filterRounds(configuration.filteredRounds)
          }

          if (configuration?.overrideSettings) {
            setBracketSettings(configuration.overrideSettings)
          }

          // Request iframe height
          postMessage('broadcastHeight', '*')
          break
        case 'bracketHeightChanged':
          setBracketHeight(event.data.parameters.height)
          break
        case 'matchClicked':
          configuration?.onMatchClicked?.(event?.data?.parameters?.match?.id)
          break
        // Note: this will not fire until we have called 'loadIntegrationData' on the current window at least once
        case 'participantClicked':
          configuration?.onParticipantClicked?.(
            event?.data?.parameters?.participant
          )
          break
      }
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
      setMounted(false)
    }
  }, [
    configuration,
    filterRounds,
    integrationData,
    loadIntegrationData,
    setBracketSettings
  ])

  // On integration data update
  useEffect(() => {
    if (!integrationData) return
    loadIntegrationData()
  }, [integrationData, loadIntegrationData])

  // On filtered round update
  useEffect(() => {
    if (!configuration?.filteredRounds) return
    filterRounds(configuration.filteredRounds)
  }, [filterRounds, configuration?.filteredRounds])

  const url = new URL(`${BASE_URL}/${configuration?.bracketId}/module`)

  if (configuration?.showStandings) {
    url.searchParams.append('standings_only', '1')
  }

  if (configuration?.themeId) {
    url.searchParams.append('theme', configuration?.themeId)
  }

  if (configuration?.scaleToFit) {
    url.searchParams.append('scale_to_fit', '1')
  }

  url.searchParams.append('show_live_status', '0')

  return {
    ref,
    url,
    filterRounds,
    bracketHeight,
    loadTheme,
    mounted,
    scrollToCoordinates,
    scrollToMatchIdentifier,
    setBracketSettings,
    setConfiguration,
    setIntegrationData,
    setZoomScale,
    zoomToCoordinates,
    zoomToMatchIdentifier
  }
}
