import Grid from '@mui/material/Grid'
import { UserAccountResource } from '@rallycry/api-suite-typescript/dist/models/UserAccountResource'
import { UserChildCreateCommand } from '@rallycry/api-suite-typescript/dist/models/UserChildCreateCommand'
import { Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { drop, omit, shuffle } from 'lodash-es'
import dynamic from 'next/dynamic'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { DiscoverCard } from '@/components/organisms/card/DiscoverCard'
import {
  LabeledField,
  LabeledFieldHeader
} from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useApiError } from '@/core/hooks/useApiError'
import { FormikOrgCommunityPicker } from '@/components/molecules/input/FormikOrgCommunityPicker'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'

const RcFileInput = dynamic(
  async () =>
    (await import('@/components/molecules/input/RcFileInput')).RcFileInput,
  { ssr: false, loading: () => <></> }
)

class FormModel {
  communityId = 0
  name = ''
  givenName = ''
  familyName = ''
  imageUpload = ''
  imageOverride = ''
  imageCommunity = ''
}

const ART = [
  'https://cdn.rallycryapp.com/default-images/avatars/rc-default-1.png',
  'https://cdn.rallycryapp.com/default-images/avatars/rc-default-2.png',
  'https://cdn.rallycryapp.com/default-images/avatars/rc-default-3.png',
  'https://cdn.rallycryapp.com/default-images/avatars/rc-default-4.png',
  'https://cdn.rallycryapp.com/default-images/avatars/rc-default-5.png'
]

export const ChildDetailsForm = ({
  title,
  communityId,
  existing,
  onCreate,
  onComplete
}: {
  title: React.ReactNode
  communityId?: number
  existing?: UserAccountResource
  onCreate: (
    communityId: number,
    cmd: UserChildCreateCommand
  ) => Promise<UserAccountResource>
  onComplete: (user: UserAccountResource, communityId: number) => Promise<void>
}) => {
  const { featHideCommunity } = useFeatures()
  const { handle } = useApiError()
  const { account } = useUserAccount()
  const { organization, isOrgModerator } = useOrganization()
  const { deleteFile } = useFirebase()
  const theme = useTheme()

  const onSubmit = async (model: FormModel) => {
    try {
      const res = await onCreate(model.communityId, {
        account: {
          ...model,
          imageOverride: model.imageOverride,
          locale: account?.locale,
          organization: organization?.id!
        }
      })

      await onComplete(res, model.communityId)

      return res
    } catch (error) {
      await handle(error)
    }
    return Promise.resolve()
  }

  const validation = Yup.object<FormModel>({
    communityId: featHideCommunity ? Yup.number() : Yup.number().required(),
    name: Yup.string().required(),
    familyName: Yup.string(),
    givenName: Yup.string(),
    imageUpload: Yup.string(),
    imageOverride: Yup.string(),
    imageCommunity: Yup.string()
  })

  const initial = {
    ...new FormModel(),
    communityId: communityId!,
    imageOverride: shuffle(ART)[0],
    ...existing
  }

  return (
    <Formik
      initialValues={initial}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form id='create-child-form'>
          <Grid container direction='column' spacing={4}>
            <Grid item container direction='row' spacing={3}>
              {/* show the ART as a grid of images in cards */}
              {(values.imageCommunity
                ? [values.imageCommunity, ...drop(ART, 1)]
                : ART
              ).map(art => (
                <Grid item key={art} xs={4}>
                  <RcAvatar
                    src={art}
                    sx={{
                      cursor: 'pointer',
                      width: 125,
                      height: 125,
                      border:
                        values.imageOverride === art
                          ? `2px solid ${theme.palette.primary.light}`
                          : ''
                    }}
                    onClick={() => {
                      setFieldValue('imageOverride', art)
                      setFieldValue('imageUpload', '')
                      deleteFile(values.imageUpload)
                    }}
                  />
                </Grid>
              ))}
              <Grid item xs={4}>
                <Box
                  width={125}
                  sx={{
                    aspectRatio: 1,
                    borderRadius: 30,
                    overflow: 'hidden',
                    border:
                      values.imageOverride === values?.imageUpload
                        ? `2px solid ${theme.palette.primary.light}`
                        : ''
                  }}
                >
                  <RcFileInput
                    source={values?.imageUpload}
                    onComplete={async val => {
                      setFieldValue('imageUpload', val)
                      setFieldValue('imageOverride', val)
                    }}
                    onClear={async () => {
                      setFieldValue('imageUpload', '')
                      setFieldValue('imageOverride', '')
                    }}
                    saveAs={`user-${uuid()}`}
                    circle
                  />
                </Box>
              </Grid>
            </Grid>

            {communityId || existing || featHideCommunity ? null : (
              <Grid item>
                <LabeledFieldHeader
                  label={<RcTrans i18nKey='shared.community' />}
                />
                <FormikOrgCommunityPicker
                  name={'communityId'}
                  onChanged={doc => {
                    setFieldValue('imageCommunity', doc?.image)
                    setFieldValue('imageOverride', doc?.image)
                  }}
                />
              </Grid>
            )}

            <Grid item>
              <LabeledField
                disabled={existing && !isOrgModerator}
                component={TextField}
                label={<RcTrans i18nKey='home:create-child-flow.name-label' />}
                name='name'
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item>
              <LabeledField
                component={TextField}
                label={
                  <RcTrans i18nKey='home:create-child-flow.given-name-label' />
                }
                name='givenName'
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item>
              <LabeledField
                component={TextField}
                label={
                  <RcTrans i18nKey='home:create-child-flow.family-name-label' />
                }
                name='familyName'
                variant='outlined'
                fullWidth
              />
            </Grid>

            <ModalConfiguration title={title}>
              <RcButton
                fullWidth
                type='submit'
                disabled={isSubmitting}
                form='create-child-form'
                variant='contained'
              >
                <RcTrans i18nKey='home:create-child-flow.complete-details-button' />
              </RcButton>
            </ModalConfiguration>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
