import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {
  AchievementResource,
  CommunityResource
} from '@rallycry/api-suite-typescript'
import { InboxMessageResource } from '@rallycry/api-suite-typescript/dist/models/InboxMessageResource'
import { some, take } from 'lodash-es'
import { useState } from 'react'
import { ModalTrigger } from '../modal/ModalTrigger'
import DisplayNewAchievements from '../site/DisplayNewAchievements'
import { InboxMessageDisplay } from '../site/InboxMessageDisplay'
import { NoResults } from '@/components/molecules/text/NoResults'

export interface PageActivityProps {
  expanded?: boolean
  messages?: InboxMessageResource[]
}

export const PageActivity = ({ expanded, messages }: PageActivityProps) => {
  const [state, setState] = useState<{
    achievement?: AchievementResource
    community?: CommunityResource
  }>()

  if (!some(messages)) return <NoResults />

  return (
    <>
      <Table>
        <TableBody>
          {take(messages, expanded ? 25 : 8)?.map(message => (
            <TableRow key={message.id}>
              <TableCell>
                <InboxMessageDisplay
                  color='text.primary'
                  key={message.id}
                  message={message}
                  render={message => message}
                  onAchievement={(a, c) => {
                    setState({ achievement: a, community: c })
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalTrigger
        open={!!state?.achievement}
        modalProps={{
          noPadding: true,
          skipCloseButton: true,
          skipFullScreen: true,
          headerBackground: true
        }}
        onClose={() => setState(undefined)}
      >
        <DisplayNewAchievements
          achievement={state?.achievement}
          community={state?.community}
          onComplete={() => setState(undefined)}
        />
      </ModalTrigger>
    </>
  )
}
