import Stack from '@mui/material/Stack'
import { FormResponseEditProps } from '../FormResponseQuestionEdit'
import { RcTrans } from '@/components/atoms/RcTrans'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'

const APPROVED = 'approved'
const REJECTED = 'rejected'

export const FormResponseEditApproval = ({
  responseId,
  question
}: FormResponseEditProps) => {
  const { answers, createFormAnswer, updateFormAnswer } = useFormResponse({
    idOrKey: responseId
  })

  const answer = answers?.find(it => it.question?.id === question?.id)
  const value = answer?.value

  return (
    <Stack direction='row' spacing={3}>
      <ConfirmingButton
        message={
          <RcTrans i18nKey='curated-forms:approval-confirmation-message' />
        }
        onClick={() =>
          answer
            ? updateFormAnswer(answer?.id!, {
                value: REJECTED,
                complete: false
              })
            : createFormAnswer({
                question: question.id!,
                value: REJECTED,
                complete: false
              })
        }
        fullWidth
        color='error'
        variant={value === REJECTED ? 'contained' : 'outlined'}
      >
        {value && value === REJECTED ? (
          <RcTrans i18nKey='shared.rejected' />
        ) : (
          <RcTrans i18nKey='shared.reject' />
        )}
      </ConfirmingButton>
      <ConfirmingButton
        message={
          <RcTrans i18nKey='curated-forms:approval-confirmation-message' />
        }
        onClick={() =>
          answer
            ? updateFormAnswer(answer?.id!, {
                value: APPROVED,
                complete: true
              })
            : createFormAnswer({
                question: question.id!,
                value: APPROVED,
                complete: true
              })
        }
        fullWidth
        color='success'
        variant={value === APPROVED ? 'contained' : 'outlined'}
      >
        {value && value === APPROVED ? (
          <RcTrans i18nKey='shared.approved' />
        ) : (
          <RcTrans i18nKey='shared.approve' />
        )}
      </ConfirmingButton>
    </Stack>
  )
}
