import { Expanded } from '@rallycry/api-suite-typescript/dist/models/Expanded'
import { InlineCommunity } from '@rallycry/api-suite-typescript/dist/models/InlineCommunity'
import { CommunityProfileResource } from '@rallycry/api-suite-typescript/dist/models/CommunityProfileResource'
import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { useOrganizationMeta } from './useOrganizationMeta'
import { useFeatures } from '@/components/providers/site/FeatureProvider'

export const useOrganizationCommunity = ({
  community,
  expanded,
  profile
}: {
  community?: InlineCommunity & {
    kind?: CommunityKind
    disambiguatingDescription?: string
  }
  expanded?: Expanded
  profile?: CommunityProfileResource
}) => {
  const { organizationId } = useOrganizationMeta()
  const { imgCommunityBanner, imgCommunityAvatar } = useFeatures()
  const additionalOrg = (
    expanded?.additionalOrganization?.filter(
      it => it.community?.id === community?.id
    ) || community?.additionalOrganizations
  )?.find(it => it.organization?.id === organizationId)

  const defaultAvatarSrc = imgCommunityAvatar as string

  const defaultBannerImageSrc = imgCommunityBanner as string

  return community?.id
    ? ({
        id: community?.id,
        kind: community?.kind || CommunityKind.BASIC,
        name: community?.name,
        image:
          additionalOrg?.image || community?.image || defaultAvatarSrc || '',
        bannerImage:
          additionalOrg?.bannerImage ||
          profile?.bannerImage ||
          expanded?.communityProfile?.find(
            it => it.community?.id === community?.id
          )?.bannerImage ||
          defaultBannerImageSrc ||
          '',
        disambiguatingDescription:
          additionalOrg?.disambiguatingDescription ||
          community?.disambiguatingDescription
      } as {
        id?: number
        kind?: CommunityKind
        name?: string
        image?: string
        bannerImage?: string
        disambiguatingDescription?: string
      })
    : undefined
}
