import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ComponentDynamicTweets } from '../../../../../models/types'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { TwitterTweetEmbed } from '@/components/organisms/site/TwitterEmbed'
import { CardsSection } from '@/components/organisms/card/CardsSection'
import { RcTrans } from '@/components/atoms/RcTrans'

export const Tweets = (props: ComponentDynamicTweets) => {
  return (
    <Stack direction='column' alignItems='center' spacing={3}>
      <Typography variant='h2'>
        <RcTrans i18nKey='shared.twitter' />
      </Typography>
      <CardsSection
        cardItems={props.content?.filter(it => !!it?.identifer)}
        render={it => <TwitterTweetEmbed tweetId={it.identifer!} />}
      />
      {props.handle ? (
        <NavigationLink href={`https://twitter.com/${props.handle}`}>
          <RcButton>
            <RcTrans i18nKey='shared.see-more' />
          </RcButton>
        </NavigationLink>
      ) : null}
    </Stack>
  )
}
