import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { TitleParagraph } from '@/components/molecules/text/TitleParagraph'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcPaperButton } from '@/components/molecules/interactive/RcPaperButton'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'

export const Conduct = () => {
  const { featCodeOfConduct } = useFeatures()
  return (
    <Grid container direction='column' wrap='nowrap' spacing={4}>
      <Grid item>
        <RcPaperButton disabled>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <RcIcon icon={['fal', 'comment-times']} size='lg' />
            <TitleParagraph
              align='center'
              title={
                <RcTrans i18nKey='competition:conduct.harrassment.title' />
              }
              paragraph={
                <RcTrans i18nKey='competition:conduct.harrassment.description' />
              }
            />
          </Box>
        </RcPaperButton>
      </Grid>
      <Grid item>
        <RcPaperButton disabled>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <RcIcon icon={['fal', 'handshake']} size='lg' />
            <TitleParagraph
              align='center'
              title={<RcTrans i18nKey='competition:conduct.safe.title' />}
              paragraph={
                <RcTrans i18nKey='competition:conduct.safe.description' />
              }
            />
          </Box>
        </RcPaperButton>
      </Grid>

      <Grid item>
        <RcPaperButton disabled>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <RcIcon icon={['fal', 'gamepad-alt']} size='lg' />
            <TitleParagraph
              align='center'
              title={<RcTrans i18nKey='competition:conduct.fair.title' />}
              paragraph={
                <RcTrans i18nKey='competition:conduct.fair.description' />
              }
            />
          </Box>
        </RcPaperButton>
      </Grid>
      {featCodeOfConduct ? (
        <Grid item>
          <NavigationLink href={featCodeOfConduct as string}>
            <Typography variant='body1' align='center'>
              <RcTrans i18nKey='competition:conduct.link' />
            </Typography>
          </NavigationLink>
        </Grid>
      ) : null}
    </Grid>
  )
}
