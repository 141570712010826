import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import React, { useCallback } from 'react'
import { RcTrans } from '../../../components/atoms/RcTrans'
import { useRcTranslation } from '../useRcTranslation'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'

// from REQUIRED_NETWORK_REGEX on server
export const competitionNetworks: NetworkKind[] = [
  NetworkKind.DISCORD,
  NetworkKind.BATTLENET,
  NetworkKind.STEAM,
  NetworkKind.XBOX,
  NetworkKind.PLAYSTATION,
  NetworkKind.FACEBOOK,
  NetworkKind.CHESSCOM,
  NetworkKind.NINTENDO,
  NetworkKind.DOMINIONONLINE,
  NetworkKind.ORIGIN,
  NetworkKind.EPIC,
  NetworkKind.WIZARDSOFTHECOAST,
  NetworkKind.RIOT,
  NetworkKind.RIOTVALORANTAMERICAS,
  NetworkKind.SUPERCELL,
  NetworkKind.TETRIO,
  NetworkKind.GARENA,
  NetworkKind.UNITY,
  NetworkKind.STREETFIGHTERID,
  NetworkKind.UBISOFTID,
  NetworkKind.ACTIVISIONID
]

/*
 * Wrapper for getting sorted translations and icons for network kind enum.
 */
export const useNetworkKinds = () => {
  const { t } = useRcTranslation()

  // Hardcoded instead of dynamic so that translator can extract them
  const getNetworkString = useCallback(
    (network?: NetworkKind) => {
      const record = {
        [NetworkKind.MASTERYCODING]: t('networks.MASTERYCODING'),
        [NetworkKind.FROSTGIANT]: t('networks.FROSTGIANT'),
        [NetworkKind.BATTLENET]: t('networks.BATTLENET'),
        [NetworkKind.CHESSCOM]: t('networks.CHESSCOM'),
        [NetworkKind.DISCORD]: t('networks.DISCORD'),
        [NetworkKind.DOMINIONONLINE]: t('networks.DOMINIONONLINE'),
        [NetworkKind.EMAIL]: t('networks.EMAIL'),
        [NetworkKind.EPIC]: t('networks.EPIC'),
        [NetworkKind.FACEBOOK]: t('networks.FACEBOOK'),
        [NetworkKind.WEBSITE]: t('networks.WEBSITE'),
        [NetworkKind.INSTAGRAM]: t('networks.INSTAGRAM'),
        [NetworkKind.LINKEDIN]: t('networks.LINKEDIN'),
        [NetworkKind.NINTENDO]: t('networks.NINTENDO'),
        [NetworkKind.ORIGIN]: t('networks.ORIGIN'),
        [NetworkKind.PLAYSTATION]: t('networks.PLAYSTATION'),
        [NetworkKind.RIOT]: t('networks.RIOT'),
        [NetworkKind.RIOTVALORANTAMERICAS]: t('networks.RIOTAMERICAS'),
        [NetworkKind.RIOTVALORANTASIA]: t('networks.RIOTASIA'),
        [NetworkKind.RIOTVALORANTEUROPE]: t('networks.RIOTEUROPE'),
        [NetworkKind.STEAM]: t('networks.STEAM'),
        [NetworkKind.SUPERCELL]: t('networks.SUPERCELL'),
        [NetworkKind.TETRIO]: t('networks.TETRIO'),
        [NetworkKind.TWITCH]: t('networks.TWITCH'),
        [NetworkKind.TWITTER]: t('networks.TWITTER'),
        [NetworkKind.WIZARDSOFTHECOAST]: t('networks.WIZARDSOFTHECOAST'),
        [NetworkKind.XBOX]: t('networks.XBOX'),
        [NetworkKind.UNITY]: t('networks.UNITY'),
        [NetworkKind.YOUTUBE]: t('networks.YOUTUBE'),
        [NetworkKind.BOYSCOUTSOFAMERICA]: t('networks.BOYSCOUTSOFAMERICA'),
        [NetworkKind.IDME]: t('networks.IDME'),
        [NetworkKind.GARENA]: t('networks.GARENA'),
        [NetworkKind.TIKTOK]: t('networks.TIKTOK'),
        [NetworkKind.ACTIVISIONID]: t('networks.ACTIVISIONID'),
        [NetworkKind.UBISOFTID]: t('networks.UBISOFTID'),
        [NetworkKind.STREETFIGHTERID]: t('networks.STREETFIGHERID')
      } as Partial<Record<NetworkKind, string>>

      return (record[network!] || t('networks.unknown')) as string
    },
    [t]
  )

  const getNetworkIcon = useCallback((network?: NetworkKind): IconProp => {
    const record = {
      [NetworkKind.MASTERYCODING]: ['fal', 'graduation-cap'],
      [NetworkKind.FROSTGIANT]: ['fal', 'ice-cream'],
      [NetworkKind.BATTLENET]: ['fab', 'battle-net'],
      [NetworkKind.CHESSCOM]: ['fal', 'chess-pawn'],
      [NetworkKind.DISCORD]: ['fab', 'discord'],
      [NetworkKind.DOMINIONONLINE]: ['fab', 'dominion'] as any,
      [NetworkKind.EMAIL]: ['fal', 'envelope'],
      [NetworkKind.EPIC]: ['fab', 'epic'] as any,
      [NetworkKind.FACEBOOK]: ['fab', 'facebook'],
      [NetworkKind.WEBSITE]: ['fal', 'external-link'],
      [NetworkKind.INSTAGRAM]: ['fab', 'instagram'] as any,
      [NetworkKind.LINKEDIN]: ['fab', 'linkedin-in'],
      [NetworkKind.NINTENDO]: ['fab', 'nintendo'] as any,
      [NetworkKind.ORIGIN]: ['fab', 'origin'] as any,
      [NetworkKind.PLAYSTATION]: ['fab', 'playstation'],
      [NetworkKind.RIOT]: ['fab', 'riot-games'] as any,
      [NetworkKind.RIOTVALORANTAMERICAS]: ['fab', 'riot-games'] as any,
      [NetworkKind.RIOTVALORANTASIA]: ['fab', 'riot-games'] as any,
      [NetworkKind.RIOTVALORANTEUROPE]: ['fab', 'riot-games'] as any,
      [NetworkKind.STEAM]: ['fab', 'steam'],
      [NetworkKind.SUPERCELL]: ['fab', 'supercell'] as any,
      [NetworkKind.TETRIO]: ['fab', 'tetris'] as any,
      [NetworkKind.TWITCH]: ['fab', 'twitch'] as any,
      [NetworkKind.TWITTER]: ['fab', 'x-twitter'],
      [NetworkKind.WIZARDSOFTHECOAST]: ['fab', 'wizards-of-the-coast'],
      [NetworkKind.XBOX]: ['fab', 'xbox'],
      [NetworkKind.UNITY]: ['fab', 'unity'],
      [NetworkKind.YOUTUBE]: ['fab', 'youtube'] as any,
      [NetworkKind.BOYSCOUTSOFAMERICA]: ['fab', 'boy-scouts-of-america'] as any,
      [NetworkKind.IDME]: ['fab', 'id-me'] as any,
      [NetworkKind.GARENA]: ['fab', 'garena'] as any,
      [NetworkKind.TIKTOK]: ['fab', 'tiktok'] as any,
      [NetworkKind.ACTIVISIONID]: ['fab', 'activision'] as any,
      [NetworkKind.UBISOFTID]: ['fab', 'ubisoft'] as any,
      [NetworkKind.STREETFIGHTERID]: ['fab', 'streetfighter'] as any
    } as Partial<Record<NetworkKind, IconProp>>

    return (record[network!] as IconProp) || ['fal', 'sync']
  }, [])

  const getNetworkKindDisclaimer = useCallback((network: NetworkKind) => {
    const record = {
      [NetworkKind.IDME]: (
        <RcTrans i18nKey='networks.IDME-disclaimer'>
          before
          <Link href='https://help.id.me' target='_new' rel='noopener'>
            text
          </Link>
          after
        </RcTrans>
      )
    } as Partial<Record<NetworkKind, React.ReactNode>>
    return record[network]
  }, [])

  return {
    getNetworkString,
    getNetworkKindDisclaimer,
    getNetworkIcon
  }
}
