import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { isEmpty, last, take } from 'lodash-es'
import { useRef } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models'
import { isActiveCheckinIssue } from '../alerts/useCompetitionAlertsCheckin'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useOnScreen } from '@/core/hooks/useOnScreen'
import {
  CompetitionRoute,
  CompetitionSettingsRoute,
  RootRoute
} from '@/core/route-keys'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'
import { ErrorCode } from '@/core/error-code'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'

// t('competition:team.issues-found', { count })
interface TeamIssueTooltipProps {
  teamId: number
  skipLink?: boolean
  display?: React.ReactNode
  hideSuccess?: boolean
}

export const TeamIssueTooltip = ({
  teamId,
  skipLink,
  display,
  hideSuccess
}: TeamIssueTooltipProps) => {
  const ref = useRef<HTMLDivElement>()
  const scrolled = useOnScreen(ref, '50px')
  const shouldLoad = scrolled
  const { navTo } = useNavigation()
  const {
    team,
    read: { isValidating }
  } = useCompetitionTeam({
    paused: !shouldLoad,
    idOrKey: teamId,
    suspense: false,
    mutable: true,
    dedupingInterval: 15000
  })
  const { events } = useCompetitionEvents()
  const currentOrPreviousCheckins =
    events([EventKind.CHECKIN]).allCurrentOrPrevious?.map(it => it.id!) || []

  const mostRecentCheckin = last(currentOrPreviousCheckins)

  const filtered =
    team?._issues?.filter(
      it =>
        (it.code !== ErrorCode.MissingRequiredNetwork &&
          it.code !== ErrorCode.CheckInRequired) ||
        isActiveCheckinIssue(it, mostRecentCheckin)
    ) || []
  const missingNetwork =
    take(
      team?._issues?.filter(it => it.code === ErrorCode.MissingRequiredNetwork),
      1
    ) || []

  const combined = [...filtered, ...missingNetwork]

  if (hideSuccess && isEmpty(combined)) {
    return null
  }

  return (
    <Box>
      <Box ref={ref} />

      {!team || isValidating ? (
        <Skeleton variant='circular' width={16} height={16} />
      ) : isEmpty(combined) ? (
        <Tooltip title='Team has no issues' placement='top' enterTouchDelay={0}>
          <span>
            {display || <RcIcon icon={['fal', 'check']} color='success' />}
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          title={
            <PreventParentClick>
              <Box color='warning.main' p={2}>
                <Typography variant='body2' pb={1}>
                  <RcTrans
                    i18nKey='competition:team.issues-found'
                    tOptions={{ count: combined?.length }}
                  />
                  <ul style={{ marginBlock: 1 }}>
                    {combined?.map((it, idx) => {
                      const msg = it.message as any
                      return (
                        <li key={msg}>
                          {msg?.code || msg}
                          {it.context?.missingResponses ? (
                            <ul>
                              {(it.context?.missingResponses as any)?.map(
                                (it: any) => (
                                  <li key={it.competitionForm?.formName}>
                                    {it.competitionForm?.formName}
                                  </li>
                                )
                              )}
                            </ul>
                          ) : null}
                        </li>
                      )
                    })}
                  </ul>
                </Typography>
                {skipLink ? null : (
                  <Button
                    color='warning'
                    variant='outlined'
                    size='small'
                    onClick={() =>
                      navTo({
                        root: RootRoute.Competition,
                        rootId: team?.competition?.id,
                        subRoute: CompetitionRoute.Settings,
                        subId: CompetitionSettingsRoute.ManageTeams
                      })
                    }
                  >
                    Manage Teams
                  </Button>
                )}
              </Box>
            </PreventParentClick>
          }
          placement='top'
          enterTouchDelay={0}
        >
          <span>
            {display || (
              <RcIcon icon={['fal', 'triangle-exclamation']} color='warning' />
            )}
          </span>
        </Tooltip>
      )}
    </Box>
  )
}
