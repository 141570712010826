import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { SubmissionScoreBonusResource } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreBonusResource'
import { RcTrans } from '@/components/atoms/RcTrans'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'

export const SubmissionScoreBonusRow = ({
  bonus,
  onRemove
}: {
  bonus: SubmissionScoreBonusResource
  onRemove?: () => any
}) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant='subtitle2'>
          {bonus.min} → {bonus.max}
        </Typography>
      </TableCell>
      <TableCell align='right'>
        <Typography variant='subtitle2'>
          <RcTrans i18nKey='shared.points' tOptions={{ points: bonus.bonus }} />
        </Typography>
      </TableCell>
      {onRemove ? (
        <TableCell align='right'>
          <ConfirmingButton
            icon={['fal', 'trash']}
            onClick={() => onRemove()}
            message={<RcTrans i18nKey='error.confirmation.bonus-score' />}
            buttonName={<RcTrans i18nKey='shared.delete' />}
          />
        </TableCell>
      ) : null}
    </TableRow>
  )
}
