import { AccessGroupUserControllerApi } from '@rallycry/api-suite-typescript/dist/apis/AccessGroupUserControllerApi'
import {
  QueryUserAccessGroupsRequest,
  UserAccessGroupControllerApi
} from '@rallycry/api-suite-typescript/dist/apis/UserAccessGroupControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useUserAccessGroups = (
  options?: EntityOptions<QueryUserAccessGroupsRequest>
) => {
  const userId = useParsedParam('userId')
  const { ctrl } = useController(UserAccessGroupControllerApi)
  const { ctrl: accessCtrl } = useController(AccessGroupUserControllerApi)

  const entity = useQueryEntity({
    key: 'useUserAccessGroups',
    expand: 'content{accessGroup}',
    request: { userId, ...options?.request },
    ...options,
    read: req => ctrl().queryUserAccessGroups(req)
  })

  const addAccessGroupUser = async (groupId: number) => {
    await accessCtrl().saveAccessGroupUser({
      idOrKey: groupId.toString()!,
      userId,
      AccessGroupUserCommand: {}
    })
    await entity.query.mutate()
  }

  const removeAccessGroupUser = async (groupId: number) => {
    await accessCtrl().removeAccessGroupUser({
      groupId,
      userId
    })
    await entity.query.mutate()
  }

  return {
    ...entity,
    groups: entity.flat,
    addAccessGroupUser,
    removeAccessGroupUser
  }
}
