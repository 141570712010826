import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { MathSign } from '@rallycry/api-suite-typescript/dist/models/MathSign'
import { NumberType } from '@rallycry/api-suite-typescript/dist/models/NumberType'
import { SubmissionScoreBonusResource } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreBonusResource'
import { SubmissionScoreResource } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreResource'

import { some } from 'lodash-es'
import { SubmissionScoreBonusRow } from './SubmissionScoreBonusRow'
import { RcTrans } from '@/components/atoms/RcTrans'

export const SubmissionScoreDetails = ({
  score,
  bonuses
}: {
  score: SubmissionScoreResource
  bonuses?: SubmissionScoreBonusResource[]
}) => {
  const isAny = score?.mathSign === MathSign.ANY
  const allowZero =
    isAny ||
    score?.mathSign === MathSign.POSITIVEORZERO ||
    score?.mathSign === MathSign.NEGATIVEORZERO
  const isPositive =
    score?.mathSign === MathSign.POSITIVE ||
    score?.mathSign == MathSign.POSITIVEORZERO

  const actualMin = allowZero ? 0 : isPositive ? 1 : -1

  const bonusIds = score.bonuses?.map(it => it.id)
  const filteredBonuses = bonuses?.filter(it => bonusIds?.includes(it.id))

  return (
    <>
      {score.optional ? (
        <RcTrans i18nKey='shared.optional' />
      ) : (
        <RcTrans i18nKey='shared.required' />
      )}{' '}
      {
        {
          [NumberType.INT]: <RcTrans i18nKey='NumberType.INT' />,
          [NumberType.DOUBLE]: <RcTrans i18nKey='NumberType.DOUBLE' />
        }[score.numberType!]
      }
      {' ['}
      {score.min ? score.min : <>{isPositive && !isAny ? actualMin : '∞'}</>}
      {' → '}
      {score.max ? score.max : <>{isPositive || isAny ? '∞' : actualMin}</>}
      {'] '}
      {some(filteredBonuses) ? (
        <RcTrans i18nKey='competition:score.bonus' tOptions={score} />
      ) : (
        <RcTrans i18nKey='competition:score.multiplier' tOptions={score} />
      )}{' '}
      {some(filteredBonuses) ? (
        <Table>
          <TableBody>
            {filteredBonuses?.map(it => (
              <SubmissionScoreBonusRow key={it.id!} bonus={it} />
            ))}
          </TableBody>
        </Table>
      ) : null}
    </>
  )
}
