import { expandById, ExpansionType } from '@/core/expand'
import {
  CompetitionEventResource,
  Expanded,
  PollOptionResource,
  UserResource
} from '@rallycry/api-suite-typescript/dist/models'
import { orderBy } from 'lodash-es'
import moment from 'moment-timezone'

export const CHUNK_SIZE = 15

export type CompetitionEventWithVotes = CompetitionEventResource & {
  userKey?: string
  users?: UserResource[]
}

export const getEventsFromVotes = (
  options: PollOptionResource[],
  expanded?: Expanded,
  skipUser?: number,
  skipUsers?: boolean
) => {
  const events: CompetitionEventWithVotes[] = options.map((it, idx) => {
    const startDate = moment(it.text)
    const endDate = startDate.clone().add(CHUNK_SIZE, 'm')

    const users = skipUsers
      ? []
      : expanded?.pollVote
          ?.filter(
            that =>
              it?.id === that.option?.id &&
              !that.retracted &&
              that.user?.id !== skipUser
          )
          ?.map(
            it =>
              expandById<UserResource>(
                it.user?.id,
                expanded,
                ExpansionType.User
              )!
          )

    const sorted = orderBy(users, it => it.id)

    return {
      id: idx + 1,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      userKey: sorted?.map(it => it.id).join('-'),
      voteCount: it.voteCount,
      users: sorted
    }
  })

  const sorted = orderBy(events, it => it.startDate)

  // convert chunked events to continuous blocks
  const recombined = sorted.reduce((acc, it) => {
    const last = acc[acc.length - 1]
    if (
      last &&
      moment(last.endDate).isSame(it.startDate) &&
      last.userKey === it.userKey
    ) {
      last.endDate = it.endDate
    } else {
      acc.push(it)
    }
    return acc
  }, [] as CompetitionEventWithVotes[])

  return recombined
}
