import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { getAuth, signInAnonymously } from 'firebase/auth'
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from 'firebase/storage'
import moment from 'moment-timezone'
import { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { useTheme } from '@mui/material'
import { FormResponseEditProps } from '../FormResponseQuestionEdit'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { usePage } from '@/components/providers/site/PageProvider'
import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'
import { calcElevationString } from '@/style/palette'

function dataURItoBlob(dataURI: any) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  // eslint-disable-next-line no-var
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

export const FormResponseEditSignature = ({
  responseId,
  question
}: FormResponseEditProps) => {
  const theme = useTheme()
  const { open } = usePage()
  const canvas = useRef<SignatureCanvas>(null)
  const { answers, createFormAnswer, updateFormAnswer } = useFormResponse({
    idOrKey: responseId
  })

  const answer = answers?.find(it => it.question?.id === question?.id)

  const [saving, setSaving] = useState(false)
  const [hasSigned, setHasSigned] = useState(false)
  // lock value to initial state
  const [value, setValue] = useState(answer?.value)

  const { firebase, user } = useFirebase()

  useEffect(() => {
    if (!user) {
      signInAnonymously(getAuth(firebase))
    }
  }, [user, firebase])

  const uploadTask = useRef<ReturnType<typeof uploadBytesResumable> | null>(
    null
  )

  const onSubmit = async (data: any) => {
    if (saving) return
    setSaving(true)
    const s = getStorage(firebase)
    const r = ref(
      s,
      `/upload/${user?.uid}/response-${responseId}-${question?.id}-signature.png`
    )

    if (uploadTask.current) uploadTask.current?.cancel?.()
    uploadTask.current = uploadBytesResumable(r, dataURItoBlob(data))

    uploadTask.current?.on?.(
      'state_changed',
      () => {
        //snapshot update noop
      },
      error => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        open(null, error.code)
        console.log(JSON.stringify(error))
        setSaving(false)
      },
      async () => {
        // Upload completed successfully, now we can get the download URL
        const url = await getDownloadURL(r)

        answer
          ? await updateFormAnswer(answer?.id!, {
              value: url,
              complete: true
            })
          : await createFormAnswer({
              question: question.id!,
              value: url,
              complete: true
            })
        setHasSigned(false)
        setSaving(false)
      }
    )
  }

  const onClear = async () => {
    setSaving(false)
    setHasSigned(false)
  }

  const onSave = async () => {
    onSubmit(canvas.current?.toDataURL('image/png'))
  }

  const handleEnd = () => {
    const ctx = canvas.current?.getCanvas()?.getContext('2d')
    if (!ctx) return
    setHasSigned(true)
    ctx.fillStyle = '#000'
    ctx.clearRect(0, 138, 300, 150)
    ctx.fillText(`${moment().toDate()}`, 5, 147)
  }

  return (
    <Box
      position='relative'
      width='100%'
      height={150}
      sx={theme => ({
        backgroundColor: theme.palette.primary.main,
        backgroundImage: calcElevationString(theme.palette.mode, [20]),
        borderRadius: 2,
        overflow: 'hidden'
      })}
    >
      {value ? (
        <Tooltip
          followCursor
          title={
            answer?._links?.update ? (
              <RcTrans i18nKey='shared.form-already-signed' />
            ) : (
              ''
            )
          }
        >
          <ImageWithFallback
            src={value}
            alt='saved signature'
            width={444}
            height={150}
          />
        </Tooltip>
      ) : (
        <Box>
          <SignatureCanvas
            ref={canvas}
            penColor='#000'
            backgroundColor='#6a6a6a'
            canvasProps={{
              style: { width: '100%', height: 150 }
            }}
            onEnd={() => (canvas.current?.isEmpty() ? onClear() : handleEnd())}
          />
          {hasSigned ? (
            <Typography variant='subtitle2' color='text.secondary'>
              <RcTrans i18nKey='shared.form-signature-save-prompt'>
                You must <Link onClick={onSave}>save</Link> when done signing
              </RcTrans>
            </Typography>
          ) : null}
        </Box>
      )}

      <RcIconButton
        color='primary'
        disabled={saving}
        disableRipple
        sx={{
          position: 'absolute',
          top: 0,
          right: 24
        }}
        icon={['fal', 'recycle']}
        onClick={() => {
          onClear()
          setValue(undefined)
          canvas.current?.clear()
        }}
        TooltipProps={{ title: 'Reset', followCursor: true }}
      />

      <RcIconButton
        color='primary'
        sx={{ position: 'absolute', top: 0, right: 0 }}
        icon={['fal', 'save']}
        disabled={!hasSigned || saving}
        disableRipple
        onClick={onSave}
        TooltipProps={{ title: 'Save', followCursor: true }}
      />
    </Box>
  )
}
