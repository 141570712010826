import { useFormikContext } from 'formik'
import { MarkdownInput } from './MarkdownInput'

/* Enables binding a markdown input by name within a formik context */

export const MarkdownFormikField = ({ name }: { name: string }) => {
  const formik = useFormikContext<any>()

  const value = name?.split('.').reduce((acc, part) => acc[part], formik.values)

  return (
    <MarkdownInput
      value={value || ''}
      onChange={val => formik.setFieldValue(name, val)}
    />
  )
}
