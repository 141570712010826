import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'
import { Select, TextField } from 'formik-mui'
import {
  EscalationReason,
  MatchEscalationCreateCommand
} from '@rallycry/api-suite-typescript/dist/models'
import * as Yup from 'yup'
import Typography from '@mui/material/Typography'
import { useBracketMatchView } from '../../useBracketMatchView'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { useBracketMatchEscalation } from '@/entity/bracket-match/useBracketMatchEscalations'
import { useApiError } from '@/core/hooks/useApiError'
import { useBracketMatch } from '@/entity/bracket-match/useBracketMatch'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useCompetition } from '@/entity/competition/useCompetition'

export const MatchEscalationForm = ({
  onComplete
}: {
  onComplete: () => any
}) => {
  const { competition } = useCompetition()
  const { match } = useBracketMatch()
  const { participant } = useCompetitionParticipant()
  const { team1Result, team2Result } = useBracketMatchView({
    match,
    competition,
    participant
  })
  const { create } = useBracketMatchEscalation()
  const { handle } = useApiError()

  const teamId = team1Result.isParticipant
    ? team1Result.team?.id!
    : team2Result.team?.id!

  const handleSubmit = async (
    values: Partial<MatchEscalationCreateCommand>
  ) => {
    try {
      await create(match?.id!, {
        ...values,
        reason: values.reason || EscalationReason.OTHER,
        author: teamId
      })
      onComplete()
    } catch (e) {
      handle(e)
    }
  }

  const validation = Yup.object().shape({
    reason: Yup.string().required(),
    description: Yup.string().required()
  })

  // t('competition:EscalationReason.FOUL_PLAY')
  // t('competition:EscalationReason.STALLING')
  // t('competition:EscalationReason.UNRESPONSIVENESS')
  // t('competition:EscalationReason.OTHER')
  return (
    <Formik
      initialValues={{
        reason: EscalationReason.UNRESPONSIVENESS,
        description: ''
      }}
      validateSchema={validation}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form id='escalation-form'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LabeledField
                component={Select}
                name='reason'
                label='Reason'
                required
              >
                {Object.values(EscalationReason).map(reason => (
                  <MenuItem key={reason} value={reason}>
                    <RcTrans
                      i18nKey={`competition:EscalationReason.${reason}`}
                    />
                  </MenuItem>
                ))}
              </LabeledField>
            </Grid>
            <Grid item xs={12}>
              <LabeledField
                component={TextField}
                name='description'
                label='Details'
                multiline
                rows={4}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <ModalConfiguration
            title='Flag Admin'
            subtitle={
              <Typography color='text.secondary' align='center'>
                This will alert a competition administrator that you need help
                with your match. This report will be visible to all
                participants.
              </Typography>
            }
          >
            <RcButton
              type='submit'
              form='escalation-form'
              fullWidth
              variant='contained'
              color='primary'
            >
              <RcTrans i18nKey='shared.submit' />
            </RcButton>
          </ModalConfiguration>
        </Form>
      )}
    </Formik>
  )
}
