import { SubmissionScoreBonusResource } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreBonusResource'
import { SubmissionScoreResource } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreResource'
import { useBracket } from '../bracket/useBracket'
import { expandById, ExpansionType } from '@/core/expand'

export const useBracketScoreSettings = () => {
  const { bracket, bracketSettings } = useBracket()

  const scoreA = expandById<SubmissionScoreResource>(
    bracketSettings?.submissionScoreA?.id,
    bracket?._expanded,
    ExpansionType.SubmissionScore
  )
  const scoreABonuses = scoreA?.bonuses?.map(
    it =>
      expandById<SubmissionScoreBonusResource>(
        it.id,
        bracket?._expanded,
        ExpansionType.SubmissionScoreBonus
      )!
  )
  const scoreB = expandById<SubmissionScoreResource>(
    bracketSettings?.submissionScoreB?.id,
    bracket?._expanded,
    ExpansionType.SubmissionScore
  )
  const scoreBBonuses = scoreB?.bonuses?.map(
    it =>
      expandById<SubmissionScoreBonusResource>(
        it.id,
        bracket?._expanded,
        ExpansionType.SubmissionScoreBonus
      )!
  )
  const scoreC = expandById<SubmissionScoreResource>(
    bracketSettings?.submissionScoreC?.id,
    bracket?._expanded,
    ExpansionType.SubmissionScore
  )
  const scoreCBonuses = scoreC?.bonuses?.map(
    it =>
      expandById<SubmissionScoreBonusResource>(
        it.id,
        bracket?._expanded,
        ExpansionType.SubmissionScoreBonus
      )!
  )
  const scoreD = expandById<SubmissionScoreResource>(
    bracketSettings?.submissionScoreD?.id,
    bracket?._expanded,
    ExpansionType.SubmissionScore
  )
  const scoreDBonuses = scoreD?.bonuses?.map(
    it =>
      expandById<SubmissionScoreBonusResource>(
        it.id,
        bracket?._expanded,
        ExpansionType.SubmissionScoreBonus
      )!
  )
  const scoreE = expandById<SubmissionScoreResource>(
    bracketSettings?.submissionScoreE?.id,
    bracket?._expanded,
    ExpansionType.SubmissionScore
  )
  const scoreEBonuses = scoreE?.bonuses?.map(
    it =>
      expandById<SubmissionScoreBonusResource>(
        it.id,
        bracket?._expanded,
        ExpansionType.SubmissionScoreBonus
      )!
  )

  return {
    settings: {
      scoreA,
      scoreB,
      scoreC,
      scoreD,
      scoreE
    },
    bonuses: {
      scoreABonuses,
      scoreBBonuses,
      scoreCBonuses,
      scoreDBonuses,
      scoreEBonuses
    }
  }
}
