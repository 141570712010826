import { ModalTrigger } from '../modal/ModalTrigger'
import { ManageUserAccessGroups } from './ManageUserAccessGroups'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'

export const ManageUserAccessGroupsButton = ({
  userId
}: {
  userId: number
}) => {
  return (
    <ModalTrigger
      activation={handleOpen => (
        <RcIconButton
          icon={['fal', 'user-shield']}
          onClick={handleOpen}
          TooltipProps={{ title: 'User Access Groups' }}
        />
      )}
    >
      {() => <ManageUserAccessGroups userId={userId} />}
    </ModalTrigger>
  )
}
