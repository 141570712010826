import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import { orderBy, shuffle } from 'lodash-es'
import { useState } from 'react'
import { FormResponseEditProps } from '../FormResponseQuestionEdit'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'

export const FormResponseEditRadio = ({
  responseId,
  question
}: FormResponseEditProps) => {
  const { response, answers, createFormAnswer, updateFormAnswer } =
    useFormResponse({ idOrKey: responseId })

  const answer = answers?.find(it => it.question?.id === question?.id)
  const option = answer?.option?.id

  const formOptions = response?._expanded?.formOption?.filter(
    it => question.options?.map(o => o.id)?.includes(it.id)
  )

  const orderedOptions = question.shuffle
    ? orderBy(shuffle(formOptions), ['isOther'], ['desc'])
    : orderBy(formOptions, ['ordinal', 'isOther'], ['asc', 'desc'])

  const otherAnswer = answers?.find(
    it =>
      it.question?.id === question?.id &&
      formOptions?.find(that => that.id === it.option?.id)?.other
  )
  const [otherVal, setOtherVal] = useState(otherAnswer?.value)

  return (
    <RadioGroup
      value={option || ''}
      onChange={evt =>
        answer
          ? updateFormAnswer(answer?.id!, {
              option: Number(evt?.target.value),
              value: formOptions?.find(it => it.id === Number(evt.target.value))
                ?.other
                ? ''
                : 'checked'
            })
          : createFormAnswer({
              question: question.id!,
              option: Number(evt?.target.value),
              value: formOptions?.find(it => it.id === Number(evt.target.value))
                ?.other
                ? ''
                : 'checked'
            })
      }
    >
      {orderedOptions?.map(it => (
        <FormControlLabel
          key={it.id}
          value={it.id}
          label={
            it.other ? (
              <TextField
                disabled={answer?.option?.id !== it.id}
                placeholder={it.name}
                value={answer?.option?.id === it.id ? otherVal : ''}
                onChange={evt => setOtherVal(evt.target.value)}
                onBlur={() =>
                  updateFormAnswer(answer?.id!, {
                    value: otherVal
                  })
                }
                required
              />
            ) : (
              <>{it.name}</>
            )
          } // disable form answer while fake id -1 during creation
          control={<Radio disabled={answer?.id === -1} />}
        />
      ))}
    </RadioGroup>
  )
}
