import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import Typography from '@mui/material/Typography'
import { CommonDuration } from '@rallycry/api-suite-typescript/dist/models/CommonDuration'
import { useState } from 'react'
import { CompetitionEventReminderRow } from './CompetitionEventReminderRow'
import { useCompetitionEventReminders } from '@/entity/competition/useCompetitionEventReminders'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'

export const CompetitionEventReminders = ({
  eventId
}: {
  eventId?: number
}) => {
  const [duration, setDuration] = useState(CommonDuration.ONEHOUR)
  const [beforeEnding, setBeforeEnding] = useState(false)
  const { reminders, create } = useCompetitionEventReminders({
    idOrKey: eventId
  })

  enum beforeMenuItems {
    BEFORE_START = 'BEFORE_START',
    BEFORE_ENDING = 'BEFORE_ENDING'
  }

  function formatEnumValue(value: string): string {
    const parts = value
      .split('_')
      .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())

    return parts.join(' ')
  }

  return (
    <>
      <SectionHeader
        title={<RcTrans i18nKey='competition:settings.event-reminders' />}
      />
      <Stack
        direction='row'
        spacing={1}
        justifyContent='space-between'
        alignItems='center'
      >
        <Select
          value={duration}
          onChange={value => setDuration(value.target.value as CommonDuration)}
        >
          {Object.values(CommonDuration).map(value => {
            let name = formatEnumValue(value)
            if (name === 'Zero') {
              name = 'Immediately'
            }
            return (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            )
          })}
        </Select>
        <Select
          value={
            beforeEnding
              ? beforeMenuItems.BEFORE_ENDING
              : beforeMenuItems.BEFORE_START
          }
          onChange={value => {
            if (value.target.value === beforeMenuItems.BEFORE_START) {
              setBeforeEnding(false)
            } else {
              setBeforeEnding(true)
            }
          }}
        >
          <MenuItem value='BEFORE_START'>
            <Typography variant='subtitle1' color='text.secondary'>
              <RcTrans i18nKey='competition:settings.before-start' />
            </Typography>
          </MenuItem>
          <MenuItem value='BEFORE_ENDING'>
            <Typography variant='subtitle1' color='text.secondary'>
              <RcTrans i18nKey='competition:settings.before-ending' />
            </Typography>
          </MenuItem>
        </Select>
        <RcButton onClick={() => create({ duration: duration, beforeEnding })}>
          <RcTrans i18nKey='shared.add' />
        </RcButton>
      </Stack>
      <Table>
        {reminders?.map(it => (
          <CompetitionEventReminderRow
            key={it.id}
            eventId={eventId}
            reminder={it}
          />
        ))}
      </Table>
    </>
  )
}
