import { useEffect } from 'react'
import { PageActivity } from '@/components/organisms/page/PageActivity'
import { useCompetitionTeamInbox } from '@/entity/competition-team/useCompetitionTeamInbox'

export const TeamActivity = () => {
  const {
    inbox,
    query: { mutate }
  } = useCompetitionTeamInbox()

  useEffect(() => {
    mutate()
  }, [mutate])

  return <PageActivity messages={inbox} />
}
