import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { CompetitionEventReminderResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventReminderResource'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { useCompetitionEventReminders } from '@/entity/competition/useCompetitionEventReminders'

export const CompetitionEventReminderRow = ({
  eventId,
  reminder
}: {
  eventId?: number
  reminder: CompetitionEventReminderResource
}) => {
  const { remove } = useCompetitionEventReminders({
    idOrKey: eventId
  })

  const options: ActionMenuOption[] = [
    {
      key: 'remove',
      display: <RcTrans i18nKey='shared.delete' />,
      action: async () => remove(reminder?.id!),
      confirmation: <RcTrans i18nKey='shared.delete-confirmation' />
    }
  ]

  return (
    <TableRow>
      <TableCell>{reminder?.duration}</TableCell>
      <TableCell>
        {reminder?.beforeEnding ? (
          <RcTrans i18nKey='competition:settings.before-ending' />
        ) : (
          <RcTrans i18nKey='competition:settings.before-start' />
        )}
      </TableCell>
      <TableCell align='right'>
        <ActionMenu options={options} />
      </TableCell>
    </TableRow>
  )
}
