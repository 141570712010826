import { REACTIONS } from './REACTIONS'
import { MarkdownDisplay } from '@/components/molecules/input/MarkdownDisplay'
import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'

export const ChatMessageText = ({
  message,
  isTemp
}: {
  message: string
  isTemp: boolean
}) => {
  return (
    <MarkdownDisplay
      markdown={message}
      paragraph={false}
      typographyProps={{ color: isTemp ? 'text.secondary' : 'text.primary' }}
    />
  )
}

export const RenderReaction = ({
  id,
  size = 20
}: {
  id: string
  size?: number
}) => {
  const removed = id.replace(/:/g, '')
  const match = REACTIONS.find(it => it.id === removed)
  if (!match) return null
  return (
    <ImageWithFallback
      alt={id}
      src={match.display}
      height={size}
      width={size}
    />
  )
}
