import {
  QuerySubmissionScoreRequest,
  SubmissionScoreControllerApi
} from '@rallycry/api-suite-typescript/dist/apis/SubmissionScoreControllerApi'
import { SubmissionScoreCreateCommand } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreCreateCommand'
import { SubmissionScoreUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/SubmissionScoreUpdateCommand'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useSubmissionScores = (
  options?: EntityOptions<QuerySubmissionScoreRequest>
) => {
  const { ctrl } = useController(SubmissionScoreControllerApi)

  const entity = useQueryEntity({
    key: 'useSubmissionScores',
    expand: 'content{bonuses}',
    ...options,
    request: {},
    read: req => ctrl().querySubmissionScore(req),
    create: (cmd: SubmissionScoreCreateCommand) =>
      ctrl().createSubmissionScore({
        SubmissionScoreCreateCommand: cmd
      }),
    update: (id, cmd: SubmissionScoreUpdateCommand) =>
      ctrl().updateSubmissionScore({
        id,
        SubmissionScoreUpdateCommand: cmd
      }),
    remove: id => ctrl().removeSubmissionScore({ id })
  })

  return { ...entity, submissionScores: entity.flat }
}
