import { AlertProps } from '@mui/material/Alert'
import { take } from 'lodash-es'
import React from 'react'
import { useCompetitionAlertsAdmin } from '@/components/pages/Competition/components/alerts/useCompetitionAlertsAdmin'
import { useCompetitionAlertsCheckin } from '@/components/pages/Competition/components/alerts/useCompetitionAlertsCheckin'
import { useCompetitionAlertsConcluded } from '@/components/pages/Competition/components/alerts/useCompetitionAlertsConcluded'
import { useCompetitionAlertsTeamDropped } from '@/components/pages/Competition/components/alerts/useCompetitionAlertsEntryDropped'
import { useCompetitionAlertsMatch } from '@/components/pages/Competition/components/alerts/useCompetitionAlertsMatch'
import { useCompetitionAlertsMemberTickets } from '@/components/pages/Competition/components/alerts/useCompetitionAlertsMemberTickets'
import { useCompetitionAlertsRegistration } from '@/components/pages/Competition/components/alerts/useCompetitionAlertsRegistration'
import { useCompetitionAlertsTeam } from '@/components/pages/Competition/components/alerts/useCompetitionAlertsTeam'
import { useCompetitionAlertsUserForm } from '@/components/pages/Competition/components/alerts/useCompetitionAlertsUserForm'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { useCompetitionAlertsBrackets } from '@/components/pages/Competition/components/alerts/useCompetitionAlertsBrackets'

export type TutorialItem = AlertProps & {
  dismissable?: string
  type: TutorialType
  header?: React.ReactNode
  dismissed?: boolean
  description?: React.ReactNode
  buttonName?: React.ReactNode
}

export enum TutorialType {
  TASK = 'task',
  ALERT = 'alert',
  ADMINISTRATION = 'administration',
  DEPRECATED = 'deprecated'
}

export const useCompetitionTutorial = () => {
  const [dismissed, setDismissed] = useLocalStorage<string[]>({
    key: 'competition-tutorial-dismissed',
    defaultValue: []
  })

  const admin = useCompetitionAlertsAdmin()
  const tickets = useCompetitionAlertsMemberTickets()
  const registration = useCompetitionAlertsRegistration()
  const team = useCompetitionAlertsTeam()
  const checkin = useCompetitionAlertsCheckin()
  const form = useCompetitionAlertsUserForm()
  const match = useCompetitionAlertsMatch()
  const dropped = useCompetitionAlertsTeamDropped()
  const brackets = useCompetitionAlertsBrackets()
  const concluded = useCompetitionAlertsConcluded()
  const issues = [
    ...admin,
    ...tickets,
    ...registration,
    ...team,
    ...checkin,
    ...form,
    ...match,
    ...dropped,
    ...brackets,
    ...concluded
  ]

  issues.forEach(it => {
    if (it.dismissable && dismissed?.includes(it.dismissable)) {
      it.dismissed = dismissed?.includes(it.dismissable)!
    }
  })

  const dismiss = (id?: string) => {
    if (!id) return
    setDismissed(s => take([...s, id], 50))
  }

  return {
    dismissed,
    dismiss,
    issues,
    tasks: issues.filter(it => it.type === TutorialType.TASK),
    alerts: issues.filter(it => it.type === TutorialType.ALERT),
    administration: issues.filter(it => it.type === TutorialType.ADMINISTRATION)
  }
}
