import {
  BracketSubmissionControllerApi,
  ViewBracketLeaderboardRequest
} from '@rallycry/api-suite-typescript/dist/apis/BracketSubmissionControllerApi'
import { CompetitionEntryResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryResource'
import { first, orderBy, sumBy } from 'lodash-es'
import { useMemo } from 'react'
import { useBracket } from '../bracket/useBracket'
import { useBracketAssignments } from '../bracket/useBracketAssignments'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { expandById, ExpansionType } from '@/core/expand'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { sortValidSubmission } from '@/components/pages/Competition/components/bracket/Leaderboard/SubmissionsDisplayTable'

export const useBracketLeaderboard = (
  options?: EntityOptions<ViewBracketLeaderboardRequest>
) => {
  const bracketId = useParsedParam('bracketId')
  const { ctrl } = useController(BracketSubmissionControllerApi)

  const entity = useQueryEntity({
    key: 'useBracketLeaderboard',
    expand:
      'content{assignment{entry{leader,representing{additionalOrganizations}}}}',
    ...options,
    request: { bracketId, ...options?.request },
    read: req => ctrl({ skipCache: true }).viewBracketLeaderboard(req)
  })

  // clear manually because document based
  const resetLeaderboard = async () => {
    await entity.query.mutate(data => {
      const it = first(data)
      if (it) it.content = []
      return [...(data || [])]
    }, false)
  }

  const scores = entity.flat
  const { bracketSettings } = useBracket()
  const { assignments } = useBracketAssignments()

  const withScores = assignments?.map(
    it =>
      scores?.find(that => that.assignment?.id === it.id) || {
        assignment: { id: it.id },
        submissions: [],
        name: expandById<CompetitionEntryResource>(
          it.entry?.id,
          it._expanded,
          ExpansionType.CompetitionEntry
        )?.alternateName?.toLocaleLowerCase(),
        _expanded: { ...it._expanded, bracketAssignment: [it] }
      }
  )
  const sortedScores = withScores?.map(it => ({
    ...it,
    submissions: sortValidSubmission(
      it.submissions || [],
      bracketSettings || {}
    )
  }))
  const withPoints = sortedScores?.map(it => ({
    ...it,
    totalPoints: sumBy(
      it.submissions.filter(it => !it.isCutoff),
      it => it.points || 0
    )
  }))

  const sortedByTotal = orderBy(
    withPoints,
    ['totalPoints', 'name'],
    [bracketSettings?.submissionOrderAscending ? 'asc' : 'desc', 'asc']
  )

  const processed = sortedByTotal

  return {
    ...entity,
    resetLeaderboard,
    scores: processed
  }
}
