import Typography from '@mui/material/Typography'
import { RootRoute } from '@/core/route-keys'
import { DocumentSearch } from '@/components/pages/CMS/DocumentSearch'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'

export interface RecentProps {
  limit?: number
  omit?: string
  direction?: 'column' | 'row' | undefined
  slideCards?: boolean
  onTotalResults?: (total: number) => void
}

export const RecentNews: React.FC<RecentProps> = ({
  limit = 2,
  omit,
  direction = 'row',
  slideCards,
  onTotalResults
}) => {
  const { cfgPartnerBlog } = useFeatures()

  return (
    <DocumentSearch
      direction={direction}
      engine={cfgPartnerBlog?.toString()}
      limit={limit}
      omit={omit}
      rootRoute={RootRoute.Blogs}
      actions={doc => (
        <>
          <Typography variant='subtitle1' color='text.disabled'>
            {new Date(doc?.published?.raw)?.toLocaleDateString()}
          </Typography>

          <RcButton variant='contained' size='small' tabIndex={-1}>
            <Typography variant='body2'>
              <RcTrans i18nKey='shared.read-more' />
            </Typography>
          </RcButton>
        </>
      )}
      slideCards={slideCards}
      onTotalResults={onTotalResults}
    />
  )
}
