import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import React, { useState } from 'react'
import { RcButton } from '../interactive/RcButton'
import { MarkdownDisplay, MarkdownDisplayProps } from './MarkdownDisplay'
import { RcTrans } from '@/components/atoms/RcTrans'

export interface MarkdownProps {
  canEdit?: boolean
  DisplayProps?: MarkdownDisplayProps
  FormComponent?: React.ElementType
  formProps?: any
  source?: string
}

export const MarkdownEdit: React.FC<MarkdownProps> = ({
  canEdit,
  DisplayProps,
  FormComponent,
  formProps,
  source
}) => {
  const [isEdit, setIsEdit] = useState(false)

  if (!FormComponent) return null
  return (
    <>
      <Collapse in={isEdit}>
        <FormComponent onComplete={() => setIsEdit(false)} {...formProps} />
      </Collapse>
      {isEdit ? null : (
        <Box>
          <MarkdownDisplay markdown={source || ''} {...DisplayProps} />
          {canEdit ? (
            <RcButton
              onClick={() => setIsEdit(true)}
              variant='outlined'
              size='small'
            >
              <RcTrans i18nKey='shared.edit' />
            </RcButton>
          ) : null}
        </Box>
      )}
    </>
  )
}
