import {
  FormResponseControllerApi,
  ViewFormResponsesByUserRequest
} from '@rallycry/api-suite-typescript/dist/apis/FormResponseControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useFormResponsesByUser = (
  options?: EntityOptions<ViewFormResponsesByUserRequest>
) => {
  const formId = useParsedParam('formId')
  const { ctrl } = useController(FormResponseControllerApi)

  const idOrKey = Number(options?.request?.user || formId)
  const entity = useQueryEntity({
    key: 'useFormResponsesByUser',
    ...options,
    request: { ...options?.request, user: idOrKey },
    paused: !idOrKey,
    expand: 'content{form},totalElements,size',
    read: req => ctrl().viewFormResponsesByUser(req),
    remove: id => ctrl().removeFormResponse({ id })
  })

  return { ...entity, responses: entity.flat }
}
