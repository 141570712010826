import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { StatusTextField } from '@/components/molecules/input/StatusTextField'
import { useMyContactAccounts } from '@/entity/user/useMyContactAccounts'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { useApiError } from '@/core/hooks/useApiError'

interface Props {
  network: any
}

interface FormModel {
  id: number
  network: string
  handle: string
}

export const ManualHandleForm: React.FC<Props> = ({ network }) => {
  const [saving, setSaving] = useState(false)
  const {
    handles,
    create: saveHandle,
    remove: removeHandle
  } = useMyContactAccounts()
  const { handle: handleError } = useApiError()
  const { getNetworkIcon, getNetworkString } = useNetworkKinds()

  const handle = handles.find(handle => handle.network === network)

  const initial = {
    id: handle?.id || 0,
    network: network,
    handle: handle?.handle || ''
  }

  const validation = Yup.object<FormModel>({
    id: Yup.number(),
    network: Yup.mixed(),
    handle: Yup.string()
  })

  const submit = async (
    values: FormModel,
    helpers: FormikHelpers<FormModel>
  ) => {
    try {
      setSaving(true)
      const { id, handle, network } = values

      if (handle) {
        await saveHandle({ handle, network: network as any })
      } else {
        await removeHandle(id)
      }
    } catch (error) {
      handleError(error, { values, helpers })
    } finally {
      setSaving(false)
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initial}
      validationSchema={validation}
      onSubmit={submit}
    >
      {({ submitForm, values, dirty, getFieldMeta, resetForm }) => (
        <Form id='form'>
          <FormGroup>
            <FormControl>
              <StatusTextField
                name='handle'
                type='text'
                variant='outlined'
                label={getNetworkString(network)}
                value={values.handle}
                dirty={dirty}
                saving={saving}
                error={getFieldMeta('handle').error}
                inputPropBlur={() => {
                  submitForm()
                }}
                onCancel={resetForm}
                startIcon={getNetworkIcon(network)}
                fullWidth
              />
            </FormControl>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}
