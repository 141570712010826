import { CreateEditDependentFlow } from './_CreateDependentFlow'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { RcTrans } from '@/components/atoms/RcTrans'

export const CreateDependentButton = () => {
  return (
    <ModalTrigger
      activation={handleOpen => (
        <RcButton
          fullWidth
          variant='contained'
          color='primary'
          onClick={handleOpen}
        >
          <RcTrans i18nKey='home:create-child-flow.create-button' />
        </RcButton>
      )}
    >
      {({ handleClose }) => (
        <CreateEditDependentFlow onComplete={handleClose} />
      )}
    </ModalTrigger>
  )
}
