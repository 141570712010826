import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'

export const TeamCountDisplay = ({
  current = 0,
  min,
  max,
  inline,
  fancy
}: {
  current?: number
  min?: number
  max?: number
  inline?: boolean
  fancy?: boolean
}) => {
  if (!min || !max) return null

  const adjustedCurrent = Math.min(current, min)
  const underCount = current < min
  const overCount = current - min
  const color = fancy ? (underCount ? 'error.main' : 'success.main') : undefined

  return (
    <>
      <Stack direction='row' alignItems='center' spacing={1} color={color}>
        <RcIcon
          icon={
            fancy
              ? underCount
                ? ['fal', 'times']
                : ['fal', 'check']
              : ['fal', 'users']
          }
          size='sm'
          inline={inline}
        />

        <Typography variant='subtitle1' noWrap>
          {adjustedCurrent}/{min}
        </Typography>
      </Stack>
      {overCount > 0 ? (
        <Typography variant='caption' noWrap color={color}>
          {/* t('shared.team-count-subs', {count}) */}
          <RcTrans
            i18nKey='shared.team-count-subs'
            tOptions={{ count: overCount }}
          />
        </Typography>
      ) : null}
    </>
  )
}
