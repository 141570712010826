import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'
import {
  MatchCheckInControllerApi,
  QueryMatchCheckInsRequest
} from '@rallycry/api-suite-typescript'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useBracketMatchMeta } from './useBracketMatchMeta'

export const useBracketMatchCheckin = (
  options?: EntityOptions<QueryMatchCheckInsRequest>
) => {
  const matchId = useParsedParam('matchId')
  const { read: meta } = useBracketMatchMeta(options)

  const { ctrl } = useController(MatchCheckInControllerApi)

  const entity = useQueryEntity({
    key: 'useBracketMatchCheckins',
    request: { matchId },
    metas: [meta],
    ...options,
    read: (req, { checkIns }) =>
      ctrl({ metas: { checkIns } }).queryMatchCheckIns(req)
  })

  const checkin = async (assignmentId: number) => {
    await ctrl().saveMatchCheckIn({ assignmentId, matchId })
    await meta.mutate()
  }

  return {
    ...entity,
    checkins: entity.flat,
    checkin
  }
}
