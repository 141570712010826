'use client'

import { useEffect, useState } from 'react'
import { ModalTrigger } from '../organisms/modal/ModalTrigger'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { useQuery } from '@/core/hooks/useQuery'
import { JoinFlow } from '@/flows/Competition/JoinFlowXstate/_JoinFlow'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'

export const TeamInviteCode = () => {
  const [open, setOpen] = useState(false)
  const [code, setCode] = useLocalStorage<{ teamId?: number; code: string }>({
    key: 'team-invite-code'
  })

  const q = useQuery()
  const urlCode = q?.get('code')
  const { participant } = useCompetitionParticipant()
  const { team } = useCompetitionTeam()
  const isOnTeam = participant?.entry?.id === team?.id

  useEffect(() => {
    if (urlCode) {
      setCode({ teamId: team?.id, code: urlCode })
    }
    // remove everything after ? in the url
    window.history.replaceState({}, '', window.location.pathname)
  }, [team?.id, urlCode, setCode])

  // open join flow if there's a code and user is not on the team
  useEffect(() => {
    if (!code?.code) return

    if (code.teamId === team?.id && !isOnTeam) {
      setOpen(true)
    }
  }, [code, team?.id, isOnTeam])

  // clear code once user joins team
  useEffect(() => {
    if (code?.code && isOnTeam) {
      setCode({ code: '' })
    }
  }, [code, isOnTeam, setCode])

  return (
    <ModalTrigger
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      activation={() => null}
    >
      {({ handleClose }) => (
        <JoinFlow
          code={code?.code}
          selectedTeam={team?.id}
          onComplete={handleClose}
        />
      )}
    </ModalTrigger>
  )
}
