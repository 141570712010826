import Typography from '@mui/material/Typography'
import { CompetitionBracketControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionBracketControllerApi'
import { BracketKind } from '@rallycry/api-suite-typescript/dist/models/BracketKind'

import { BracketChallonge } from '../../Competition/components/bracket/Challonge/BracketChallonge'
import { LeaderboardTable } from '../../Competition/components/bracket/Leaderboard/LeaderboardTable'
import { ComponentDynamicCompetitionBracket } from '../../../../../models/types'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RouteParamOverrideProvider } from '@/components/providers/site/RouteParamOverrideProvider'
import { useBracket } from '@/entity/bracket/useBracket'
import { useReadEntity } from '@/entity/useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const CompetitionBracket = (
  props: ComponentDynamicCompetitionBracket
) => {
  const { ctrl } = useController(CompetitionBracketControllerApi)

  const entity = useReadEntity({
    key: 'bracket-CompetitionBracket',
    idOrKey: props.bracketId,
    read: req => ctrl().readCompetitionBracket(req)
  })

  return (
    <RouteParamOverrideProvider
      context={{
        competitionId: entity?.read?.data?.competition?.id?.toString(),
        bracketId: props.bracketId?.toString()
      }}
    >
      <RcSuspense skipLoader>
        <BracketHeader />
        <BracketKindPicker />
      </RcSuspense>
    </RouteParamOverrideProvider>
  )
}

const BracketHeader = () => {
  const { bracket } = useBracket()
  return (
    <Typography variant='h2' textAlign='center' mb={15}>
      {bracket?.name}
    </Typography>
  )
}

const BracketKindPicker = () => {
  const { bracketSettings } = useBracket()

  return bracketSettings?.kind === BracketKind.LEADERBOARDSUBMISSION ? (
    <LeaderboardTable />
  ) : (
    <BracketChallonge />
  )
}
