import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { GrantAchievement } from '@/components/pages/Community/GrantCommunityAchievement'
import { useUserAchievements } from '@/entity/user/useUserAchievements'

export const BulkAchievementAssignModal = ({
  participants
}: {
  participants: number[]
}) => {
  return (
    <ModalTrigger
      activation={handleOpen => (
        <RcIconButton
          icon={['fal', 'award']}
          size='small'
          sx={{ mr: -1 }}
          TooltipProps={{
            title: 'Grant Users Achievement'
          }}
          onClick={handleOpen}
        />
      )}
    >
      {({ handleClose }) => (
        <BulkAchievementAssignFlow
          users={participants}
          onComplete={handleClose}
        />
      )}
    </ModalTrigger>
  )
}

export const BulkAchievementAssignFlow = ({
  users,
  onComplete
}: {
  users: number[]
  onComplete: () => void
}) => {
  const { grantAchievement } = useUserAchievements({
    paused: true
  })

  const handleGranting = (values: { selectedAchievement: number }) => {
    users.forEach(participant => {
      grantAchievement({
        userId: participant,
        achievedId: values.selectedAchievement,
        UserAchievedSaveCommand: { dateAchieved: new Date() }
      })
    })
  }

  return (
    <GrantAchievement
      onGrantAchievement={handleGranting}
      onComplete={onComplete}
    />
  )
}
