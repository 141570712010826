'use client'

import { useCallback, useEffect, useRef } from 'react'

export const useTimeoutWindow = (
  path: string,
  onSuccess?: () => Promise<any> | undefined,
  onError?: (e: any) => Promise<any> | undefined
) => {
  const ref = useRef<NodeJS.Timeout | null>(null)

  const handleOpen = async () => {
    const win = window.open(path, '_new', 'width=500,height=700')

    clearTimer()
    ref.current = setInterval(async () => {
      try {
        if (win == null || win.closed) {
          clearTimer()
          await onSuccess?.()
        }
      } catch (e) {
        onError?.(e)
      }
    }, 100)
  }

  const clearTimer = useCallback(() => {
    if (ref.current) {
      clearInterval(ref.current)
      ref.current = null
    }
  }, [])

  useEffect(() => {
    return () => clearTimer()
  }, [clearTimer])

  return handleOpen
}
