import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

export const BracketChallongeZoom = ({
  handleZoom,
  handleZoomOut
}: {
  handleZoom: () => any
  handleZoomOut: () => any
}) => {
  const { t } = useRcTranslation()

  return (
    <>
      <RcIconButton
        size='small'
        onClick={handleZoom}
        icon={['fal', 'search-plus']}
        TooltipProps={{
          title: t('shared.zoom-increase')
        }}
      />
      <RcIconButton
        size='small'
        onClick={handleZoomOut}
        icon={['fal', 'search-minus']}
        TooltipProps={{
          title: t('shared.zoom-decrease')
        }}
      />
    </>
  )
}
