import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Color from 'color'
import { makeStyles } from '@mui/styles'
import { HandleFormProps } from './HandleForm'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useMyContactAccounts } from '@/entity/user/useMyContactAccounts'
import { useConfig } from '@/entity/site/useConfig'
import { useTimeoutWindow } from '@/core/hooks/useTimeoutWindow'
import { useLocalGatekeeper } from '@/core/hooks/useLocalGatekeeper'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { useUserMeta } from '@/entity/user/useUserMeta'

/* Hard Coded ID.Me Style
 * https://developers.id.me/brand-assets#idme-logo
 */
const useIdMeStyle = makeStyles(() => ({
  root: {
    backgroundColor: '#08833d',
    '&:hover': {
      backgroundColor: Color('#08833d').lighten(0.1).hex()
    }
  },
  iconPrefix: {
    borderRight: '1px solid',
    //borderColor: theme?.styleGuide?.text[1],
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: 64,
    left: 0,
    top: 0,
    fontSize: 24,
    paddingTop: 7,
    paddingLeft: 16
  },
  iconSuffix: {}
}))

export const IdMeHandleForm: React.FC<HandleFormProps> = ({
  audience,
  network,
  onSuccess,
  onError
}) => {
  const {
    read: { mutate }
  } = useUserMeta()
  const { handles } = useMyContactAccounts()
  const config = useConfig()
  const { getNetworkKindDisclaimer, getNetworkIcon } = useNetworkKinds()
  const classes = useIdMeStyle()
  const { localGatekeeper } = useLocalGatekeeper()

  const icon = getNetworkIcon(network)
  const tickIcon = ['fab', 'id-me-tick'] as any
  const disclaimer = getNetworkKindDisclaimer(network)
  const handle = handles.find(handle => handle.network === network)
  const options = audience || localGatekeeper?.options

  const showLink = useTimeoutWindow(
    config.api + `/oauth2/authorization/${network.toLowerCase()}-${options}`,
    async () => {
      await mutate()
      onSuccess?.()
    },
    onError
  )

  return (
    <>
      <RcButton
        fullWidth
        disabled={!!handle && !handle.expired}
        variant='contained'
        className={classes.root}
        onClick={showLink}
      >
        <Box className={classes.iconPrefix}>
          <RcIcon color={handle ? 'primary' : undefined} icon={tickIcon} />
        </Box>
        <strong>
          {/* provider is '' because we are using ID.me icon */}
          {handle ? (
            <RcTrans
              i18nKey='profile:verify-success'
              tOptions={{ provider: '' }}
            />
          ) : (
            <RcTrans
              i18nKey='profile:verify-with'
              tOptions={{ provider: '' }}
            />
          )}
        </strong>
        <RcIcon className={classes.iconSuffix} icon={icon} ml={2} />
      </RcButton>
      {disclaimer ? (
        <Box mt={2}>
          <Typography variant='body2' align='center'>
            {disclaimer}
          </Typography>
        </Box>
      ) : null}
    </>
  )
}
