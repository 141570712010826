import Box from '@mui/material/Box'
import Portal from '@mui/material/Portal'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { convertToLocalTime, convertToTimeZone } from 'date-fns-timezone'
import { isArray } from 'lodash-es'
import moment from 'moment-timezone'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { elevateColor } from '@/style/palette'
import { TranslationNamespace } from '@/core/translation'
import { isDarkMode } from '@/core/utils'
import { LabeledFieldHeader } from '@/components/organisms/form/LabeledField'
import { useModal } from '@/components/organisms/modal/ModalProvider'
import { useTime } from '@/core/hooks/useTime'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

export const useDatepickerStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      '& > div:first-of-type': {
        height: '100%'
      },
      /* General Style & Wrapper */
      '& .react-datepicker': {
        backgroundColor: elevateColor(theme.palette.background.paper, 3),
        border: 'none',
        overflow: 'none',
        color: theme.palette.text.primary,
        width: '100%',
        height: '100%',
        position: 'relative',
        top: '15px',
        left: '15px',
        display: 'flex',
        flexDirection: 'row'
      },
      '& .react-datepicker-wrapper': {
        width: '100%'
      },
      '& .react-datepicker__input-container input': {
        backgroundColor: elevateColor(theme.palette.background.paper, 5),
        borderRadius: theme.shape.borderRadius * 5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'transparent',
        color: theme?.palette.text.primary,
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
        width: '100%',
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        '&:hover': {
          borderColor: isDarkMode(theme)
            ? theme.palette.common.white
            : theme.palette.common.black
        },
        '&:focus': {
          border: 0,
          outline: `solid 2px ${theme.palette.background.paper}`
        }
      },
      '& .react-datepicker__close-icon::after': {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        height: 32,
        width: 32,
        fontSize: 20
      },
      '& .react-datepicker__navigation--next': {
        marginTop: theme.spacing(2),
        borderLeftColor: theme.palette.secondary.contrastText
      },
      '& .react-datepicker__navigation--previous': {
        marginTop: theme.spacing(2),
        borderRightColor: theme.palette.secondary.contrastText
      },
      /* Month & Day Headers */
      '& .react-datepicker__month-container': {
        flexGrow: 1,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      },
      '& .react-datepicker__header': {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxHeight: '55px',
        width: '100%',
        backgroundColor: elevateColor(theme.palette.background.paper, 5),
        border: 'none',
        '& .react-datepicker__current-month, .react-datepicker-time__header': {
          height: 50,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: 1,
          color: theme.palette.secondary.contrastText
        }
      },
      '& .react-datepicker__current-month': {
        padding: theme.spacing(3)
      },
      '& .react-datepicker__day-names, .react-datepicker__week': {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
        ...theme.typography.body2
      },
      '& .react-datepicker__day-names': {},

      '& .react-datepicker__day-name, .react-datepicker__day': {
        marginTop: theme.spacing(2),
        width: '100%',
        minWidth: 32,
        fontWeight: 'bold',
        color: theme.palette.text.primary
      },
      '& .react-datepicker-time__header': {
        color: theme.palette.text.primary
      },
      '& .react-datepicker__month': {
        paddingTop: theme.spacing(3)
      },

      /* Calendar Days */
      '& .react-datepicker__day': {
        color: theme.palette.text.primary,
        fontWeight: 500,
        '&:hover': {
          color: theme.palette.text.primary,
          backgroundColor: theme?.palette.grey[500],
          opacity: 0.6
        }
      },
      '& .react-datepicker__day--today': {
        fontWeight: 'bold'
      },
      '& .react-datepicker__day--disabled': {
        color: theme?.palette.text.disabled,
        cursor: 'not-allowed'
      },
      '& .react-datepicker__day--keyboard-selected': {
        backgroundColor: elevateColor(theme.palette.background.paper, 5),
        textDecoration: 'underline',
        textUnderlineOffset: '3px'
      },
      '& .react-datepicker__day--selected': {
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
        textDecorationColor: theme.palette.text.primary,
        color: theme.palette.text.primary,
        backgroundColor: elevateColor(theme.palette.background.paper, 5)
      },
      '& .react-datepicker__triangle': {
        display: 'none'
      },
      /* Time Column */
      '& .react-datepicker__time-container': {
        border: 'none',
        '& .react-datepicker__time': {
          '& .react-datepicker__time-box': {
            '& .react-datepicker__time-list': {
              backgroundColor: theme?.palette.background.default,

              '& .react-datepicker__time-list-item': {
                height: 'auto',
                marginLeft: -3,
                ...theme.typography.body2,
                '&:hover': {
                  backgroundColor: theme?.palette.grey[500],
                  opacity: 0.6
                }
              },
              '& .react-datepicker__time-list-item--selected': {
                color: theme.palette.text.primary,
                backgroundColor: elevateColor(theme.palette.background.paper, 5)
              },
              '& .react-datepicker__time-list-item--disabled': {
                color: theme?.palette.text.disabled
              }
            }
          }
        }
      }
    }
  }
})

export const RcDateTimePicker: React.FC<
  ReactDatePickerProps & {
    placeholderText?: string
    label?: React.ReactNode
    UTC?: boolean
  }
> = ({
  label,
  selected,
  onChange,
  placeholderText,
  showTimeSelect = true,
  UTC = false,
  ...props
}) => {
  const { isInModalContext } = useModal()
  const { tz, feat24hTime } = useTime()
  const { t, i18n } = useRcTranslation([TranslationNamespace.Competition]) // ph
  const classes = useDatepickerStyles()
  const datePickerStyle = clsx('chromatic-ignore', classes.root)

  const timeZone = UTC ? 'UTC' : tz

  return (
    <Box className={datePickerStyle}>
      <LabeledFieldHeader label={label} />
      <DatePicker
        useWeekdaysShort
        autoComplete='off'
        popperContainer={isInModalContext ? undefined : CalendarContainer}
        selected={
          selected && timeZone
            ? convertToTimeZone(selected || '', { timeZone })
            : selected
        }
        onChange={(
          date: Date | [Date, Date] | null,
          event: React.SyntheticEvent<any, Event> | undefined
        ) => {
          if (timeZone && date instanceof Date) {
            date = convertToLocalTime(date, { timeZone })
          } else if (timeZone && isArray(date)) {
            date = [
              convertToLocalTime(date[0], { timeZone }),
              convertToLocalTime(date[1], { timeZone })
            ]
          }
          onChange(date as Date, event)
        }}
        showTimeSelect={showTimeSelect}
        filterTime={time => {
          // only supports min-datetime filtering for now. update to handle max-datetime if ever needed....
          if (!props.minDate) return true
          const minDate = moment(props.minDate)
          const selectedDate = moment(selected)
          const selectedTime = moment(time)
          return (
            !minDate.isSame(selectedDate, 'day') ||
            selectedTime.isAfter(minDate)
          )
        }}
        popperPlacement='top-start'
        placeholderText={placeholderText}
        timeIntervals={15}
        timeCaption={t('shared.time-label')}
        locale={i18n.language}
        dateFormat={
          feat24hTime
            ? showTimeSelect
              ? 'MMM dd, yyyy HH:mm'
              : 'MMM dd, yyyy'
            : showTimeSelect
              ? 'MMM dd, yyyy h:mm a'
              : 'MMM dd, yyyy'
        }
        timeFormat={feat24hTime ? 'HH:mm' : 'h:mm a'}
        {...props}
      />
    </Box>
  )
}

const CalendarContainer = ({ children }: { children: React.ReactNode }) => {
  const el = document.getElementById('calendar-portal')
  const classes = useDatepickerStyles()
  const datePickerStyle = clsx('chromatic-ignore', classes.root)
  return (
    <>
      <Portal container={el}>
        <Box className={datePickerStyle}>{children}</Box>
      </Portal>
    </>
  )
}

export const RcDatePicker: React.FC<
  ReactDatePickerProps & {
    placeholderText?: string
    label?: React.ReactNode
  }
> = ({ label, selected, onChange, placeholderText, ...props }) => {
  const { tz, feat24hTime } = useTime()
  const { i18n } = useRcTranslation([TranslationNamespace.Competition]) // ph
  const classes = useDatepickerStyles()
  const datePickerStyle = clsx('chromatic-ignore', classes.root)

  return (
    <div className={datePickerStyle} style={{ height: '100%' }}>
      <LabeledFieldHeader label={label} />
      <DatePicker
        useWeekdaysShort
        inline
        autoComplete='off'
        selected={
          selected && tz
            ? convertToTimeZone(selected, { timeZone: tz })
            : selected
        }
        onChange={(
          date: Date | [Date, Date] | null,
          event: React.SyntheticEvent<any, Event> | undefined
        ) => {
          if (tz && date instanceof Date) {
            date = convertToLocalTime(date, { timeZone: tz })
          } else if (tz && isArray(date)) {
            date = [
              convertToLocalTime(date[0], { timeZone: tz }),
              convertToLocalTime(date[1], { timeZone: tz })
            ]
          }
          onChange(date as Date, event)
        }}
        placeholderText={placeholderText}
        locale={i18n.language}
        dateFormat={feat24hTime ? 'MMM dd, yyyy HH:mm' : 'MMM dd, yyyy h:mm a'}
        timeFormat={feat24hTime ? 'HH:mm' : 'h:mm a'}
        {...props}
      />
    </div>
  )
}
