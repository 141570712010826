import {
  CompetitionParticipantDocumentControllerApi,
  SearchCompetitionParticipantsRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionParticipantDocumentControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionParticipantDocument = (
  options: EntityOptions<SearchCompetitionParticipantsRequest>
) => {
  const competition = useParsedParam('competitionId')
  const { ctrl } = useController(CompetitionParticipantDocumentControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionParticipantDocument',
    paused: !options.request,
    ...options,
    request: { competition, ...options.request },
    read: req => ctrl().searchCompetitionParticipants(req)
  })

  return { ...entity, participants: entity.flat }
}
