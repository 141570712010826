import Grid from '@mui/material/Grid'
import { EmailParticipantsCommand } from '@rallycry/api-suite-typescript/dist/models/EmailParticipantsCommand'
import { Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'
import { LabeledField, LabeledFieldHeader } from '../form/LabeledField'
import { ModalConfiguration } from '../modal/ModalConfiguration'
import { PageForm } from '../page/PageForm'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { useApiError } from '@/core/hooks/useApiError'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcToggle } from '@/components/molecules/input/RcToggle'
import { MarkdownFormikField } from '@/components/molecules/input/MarkdownFormikField'
import { RcTrans } from '@/components/atoms/RcTrans'

interface FormModel {
  customReply: boolean
  name: string
  email: string
  subject: string
  content: string
}

interface ContactFormProps {
  participants?: number[]
  onEmail: (model: EmailParticipantsCommand) => Promise<void>
  onComplete?: () => void
  title: React.ReactNode
  subtitle: React.ReactNode
}

export const EmailParticipantForm: React.FC<ContactFormProps> = ({
  participants = [],
  onEmail,
  onComplete,
  title,
  subtitle
}) => {
  const [replyTo, setReplyTo] = useLocalStorage({
    key: 'reply-to',
    defaultValue: {
      name: '',
      email: ''
    }
  })
  const { handle } = useApiError()

  const submit = async (model: FormModel) => {
    try {
      await onEmail({
        participants,
        replyTo: model.customReply ? model : undefined,
        ...model
      })
      model.customReply && setReplyTo({ name: model.name, email: model.email })
      onComplete?.()
    } catch (error) {
      await handle(error)
    }

    return Promise.resolve()
  }

  const validation = Yup.object<FormModel>({
    customReply: Yup.boolean(),
    name: Yup.string().when('customReply', {
      is: customReply => !!customReply,
      then: Yup.string().required()
    }),
    email: Yup.string().when('customReply', {
      is: customReply => !!customReply,
      then: Yup.string().email().required()
    }),
    subject: Yup.string(),
    content: Yup.string().required()
  })

  return (
    <Formik
      initialValues={{
        customReply: false,
        ...replyTo!,
        subject: '',
        content: ''
      }}
      validationSchema={validation}
      onSubmit={submit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form id='contact-form'>
          <Grid container direction='row' spacing={3}>
            <Grid item xs={12}>
              <PageForm
                title={<RcTrans i18nKey={`shared.reply-to-toggle`} />}
                sideChild
              >
                <RcToggle
                  property={'customReply'}
                  source={values}
                  update={async val =>
                    setFieldValue('customReply', val.customReply)
                  }
                />
              </PageForm>
            </Grid>

            {values.customReply ? (
              <>
                <Grid item xs={12} sm={6}>
                  <LabeledField
                    component={TextField}
                    name='email'
                    variant='outlined'
                    fullWidth
                    label={<RcTrans i18nKey='shared.reply-to-email' />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LabeledField
                    component={TextField}
                    name='name'
                    variant='outlined'
                    fullWidth
                    label={<RcTrans i18nKey='shared.reply-to-name' />}
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <LabeledField
                component={TextField}
                name='subject'
                variant='outlined'
                fullWidth
                label={<RcTrans i18nKey='shared.subject' />}
              />
            </Grid>

            <Grid item xs={12}>
              <LabeledFieldHeader
                label={<RcTrans i18nKey='shared.content' />}
              />
              <MarkdownFormikField name='content' />
            </Grid>

            <ModalConfiguration title={title} subtitle={subtitle}>
              <RcButton
                type='submit'
                disabled={isSubmitting}
                form='contact-form'
                variant='contained'
                fullWidth
              >
                <RcTrans i18nKey='shared.submit' />
              </RcButton>
            </ModalConfiguration>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
