import {
  BracketMatchControllerApi,
  QueryBracketMatchesRequest
} from '@rallycry/api-suite-typescript/dist/apis/BracketMatchControllerApi'
import { BracketMatchUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/BracketMatchUpdateCommand'
import { BracketMatchCreateCommand } from '@rallycry/api-suite-typescript/dist/models/BracketMatchCreateCommand'
import {
  MatchControllerApi,
  MatchCreateCommand
} from '@rallycry/social-api-typescript'
import { MatchmTicketControllerApi } from '@rallycry/api-suite-typescript/dist/apis'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController, useSocialController } from '@/core/hooks/useSWRApi'

export const useBracketMatchesByRound = (
  options?: EntityOptions<QueryBracketMatchesRequest> & {
    includeGames?: boolean
  }
) => {
  const parsedId = useParsedParam('bracketId')
  const bracketId = Number(options?.idOrKey) || parsedId
  const { ctrl } = useController(BracketMatchControllerApi)
  const { ctrl: ticketCtrl } = useController(MatchmTicketControllerApi)
  const { ctrl: socialCtrl } = useSocialController(MatchControllerApi)

  const entity = useQueryEntity({
    key: 'useBracketMatches',
    expand: `_links,content{bracket{settings},assignments{entry{leader,representing{additionalOrganizations}}},event${
      options?.includeGames ? ',games' : ''
    }}`,
    ...options,
    request: { bracketId, ...options?.request },
    read: req => ctrl().queryBracketMatches(req),
    remove: id => ctrl().removeBracketMatch({ id })
  })
  const create = async (cmd: BracketMatchCreateCommand) => {
    await ctrl().createBracketMatch({
      bracketId,
      BracketMatchCreateCommand: cmd
    })
    await entity.query.mutate()
  }

  const createLadderMatch = async (
    matchmId: string,
    cmd: MatchCreateCommand
  ) => {
    const ticket = await ticketCtrl().obtainMatchmModTicket({
      bracketId
    })
    await socialCtrl({ ticket: ticket?.ticket }).createMatch({
      matchmId,
      matchCreateCommand: cmd
    })
    await entity.query.mutate()
  }

  const updateMatchesByBracket = async (
    bracket: number,
    round: number[],
    cmd: BracketMatchUpdateCommand
  ) => {
    await ctrl().updateMatchesByBracket({
      bracketId: bracket,
      BracketMatchUpdateByQuery: {
        query: {
          round
        },
        update: {
          ...cmd,
          winCondition:
            (cmd.winCondition as any) === 'UNSET'
              ? undefined
              : cmd.winCondition,
          winConditionAmount:
            (cmd.winCondition as any) === 'UNSET'
              ? undefined
              : cmd.winConditionAmount,
          event: cmd.event! >= 0 ? cmd.event : undefined,
          clearEvent: cmd.event! <= 0
        }
      }
    })
  }

  const reset = async () => {
    await ctrl().resetBracketMatch({
      id: bracketId,
      BracketMatchResetCommand: {}
    })
    await entity.query.mutate()
  }

  return {
    ...entity,
    matches: entity.flat,
    create,
    createLadderMatch,
    updateMatchesByBracket,
    reset
  }
}
