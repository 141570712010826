import { UserControllerApi } from '@rallycry/api-suite-typescript/dist/apis/UserControllerApi'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserProfile = (options?: EntityOptions) => {
  const userId = useParsedParam('userId')
  const { ctrl } = useController(UserControllerApi)
  const id = Number(userId || options?.idOrKey)

  const entity = useReadEntity({
    key: 'useUserProfile',
    expand: 'organization',
    ...options,
    idOrKey: id,
    read: req => ctrl().readUser(req)
  })

  const archive = async () => {
    await ctrl().archiveUser({ id })
    await entity.read.mutate()
  }

  return { ...entity, user: entity.read.data, archive }
}
