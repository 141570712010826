import { CommunityResource } from '@rallycry/api-suite-typescript/dist/models/CommunityResource'
import { useOrganization } from '../../entity/organization/useOrganization'
import { useUserAccount } from '../../entity/user/useUserAccount'
import { useLocalStorage } from './useLocalStorage'
import {
  ChallengeType,
  CommunityJoinStep
} from '@/flows/Community/JoinFlow/_JoinFlow'

export const useLocalGatekeeper = () => {
  const { account } = useUserAccount()
  const { orgKey } = useOrganization()

  const [localGatekeeper, setLocalGatekeeper] = useLocalStorage<{
    community?: CommunityResource
    domains?: string[]
    selectedDisambiguating?: string
    stepHistory?: CommunityJoinStep[]
    selectedChallenge?: ChallengeType
    options?: string
  }>({ key: `gatekeeper_v2_${orgKey}_${account?.id}`, defaultValue: {} })

  return {
    localGatekeeper,
    setLocalGatekeeper
  }
}
