import {
  BracketMatchControllerApi,
  ViewBracketMatchAccountsRequest
} from '@rallycry/api-suite-typescript/dist/apis/BracketMatchControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useBracketMatchContactAccounts = (
  options?: EntityOptions<ViewBracketMatchAccountsRequest>
) => {
  const parsedId = useParsedParam('matchId')
  const { ctrl } = useController(BracketMatchControllerApi)

  const id = options?.request?.id || parsedId
  const entity = useQueryEntity({
    key: 'useBracketMatchContactAccounts',
    expand: 'content{_links}',
    paused: !id,
    ...options,
    request: { ...options?.request, id },
    read: req =>
      ctrl({
        expectedStatuses: [403],
        fallbackResponse: {}
      }).viewBracketMatchAccounts(req)
  })

  return { ...entity, accounts: entity.flat }
}
