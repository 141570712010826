import {
  AuditDocumentControllerApi,
  SearchAuditDocumentsRequest
} from '@rallycry/api-suite-typescript'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useAuditDocument = (
  options?: EntityOptions<SearchAuditDocumentsRequest>
) => {
  const { ctrl } = useController(AuditDocumentControllerApi)
  const entity = useQueryEntity({
    key: 'useAuditDocument',
    paused: !options?.request,
    persist: true,
    ...options,
    request: options?.request,
    read: req =>
      ctrl({
        expectedStatuses: [403, 404, 418],
        fallbackResponse: []
      }).searchAuditDocuments(req)
  })

  return { ...entity, audits: entity.flat }
}
