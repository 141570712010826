import { BracketMatchControllerApi } from '@rallycry/api-suite-typescript/dist/apis/BracketMatchControllerApi'
import { BracketMatchUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/BracketMatchUpdateCommand'
import { EntityOptions, useQueryEntity, useReadEntity } from '../useEntity'
import { useBracketMatchMeta } from './useBracketMatchMeta'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'
import {
  MatchProposalControllerApi,
  QueryMatchProposalsRequest
} from '@rallycry/api-suite-typescript'

export const useBracketMatch = (options?: EntityOptions) => {
  const id = useParsedParam('matchId')
  const { read: meta } = useBracketMatchMeta(options)
  const { ctrl } = useController(BracketMatchControllerApi)

  const matchId = Number(options?.idOrKey) || id
  const entity = useReadEntity({
    key: 'useBracketMatch',
    expand:
      '_links,activeChannel,bracket{settings,competition},assignments{entry{leader,_links,representing{additionalOrganizations,profile}}},event,games,channel',
    ...options,
    idOrKey: matchId,
    metas: [meta],
    read: (req, { root }) => ctrl({ metas: { root } }).readBracketMatch(req),
    update: (id, cmd: BracketMatchUpdateCommand) =>
      ctrl().updateBracketMatch({
        id,
        AdvancedMatchUpdateCommand: {
          ...cmd,
          winCondition:
            (cmd.winCondition as any) === 'UNSET'
              ? undefined
              : cmd.winCondition,
          winConditionAmount:
            (cmd.winCondition as any) === 'UNSET'
              ? undefined
              : cmd.winConditionAmount,
          event: cmd.event! >= 0 ? cmd.event : undefined,
          clearEvent: cmd.event! <= 0
        }
      })
  })

  const setWinner = async (winner?: number) => {
    await ctrl().updateBracketMatch({
      id: matchId,
      AdvancedMatchUpdateCommand: { winner: winner || (null as any) }
    })
    await meta.mutate()
  }

  const reset = async () => {
    await ctrl().resetBracketMatch({
      id: matchId,
      BracketMatchResetCommand: {}
    })
    await meta.mutate()
  }

  const remove = async () => {
    await ctrl().removeBracketMatch({ id: matchId })
    await meta.mutate()
  }

  const setTime = async (value?: Date) => {
    await ctrl().updateBracketMatch({
      id: matchId,
      AdvancedMatchUpdateCommand: { startDate: value, clearStartDate: !value }
    })
    await meta.mutate()
  }

  return {
    ...entity,
    match: entity.read.data,
    setWinner,
    reset,
    remove,
    setTime
  }
}

export const useBracketMatchProposals = (
  options?: EntityOptions<QueryMatchProposalsRequest>
) => {
  const id = useParsedParam('matchId')
  const { read: meta } = useBracketMatchMeta(options)
  const { ctrl } = useController(MatchProposalControllerApi)
  const matchId = Number(options?.idOrKey) || id

  const entity = useQueryEntity({
    key: 'useBracketMatchProposals',
    ...options,
    metas: [meta],
    request: { matchId },
    read: (req, { proposals }) =>
      ctrl({ metas: { proposals } }).queryMatchProposals(req)
  })

  const proposeTime = async (value: Date, assignmentId: number) => {
    ctrl().saveMatchProposal({
      matchId,
      assignmentId,
      MatchProposalSaveCommand: { startDate: value }
    })
    await entity.query.mutate()
  }

  return { ...entity, proposals: entity.flat, proposeTime }
}
