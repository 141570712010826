'use client'

import Portal from '@mui/material/Portal'
import { useState } from 'react'
import Confetti from 'react-confetti'
import { useTheme } from '@mui/material'
import { usePage } from '@/components/providers/site/PageProvider'

export const RcConfetti = () => {
  const { confettiContainer } = usePage()
  const [show, setShow] = useState(true)
  const theme = useTheme()
  const zIndex = theme.zIndex.snackbar
  return (
    <Portal container={confettiContainer?.current}>
      <Confetti
        style={{ zIndex: zIndex }}
        numberOfPieces={show ? 500 : 0}
        recycle={false}
        onConfettiComplete={confetti => {
          setShow(false)
          confetti?.reset?.()
        }}
      />
    </Portal>
  )
}
