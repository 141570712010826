import {
  CompetitionEntryControllerApi,
  ViewCompetitionEntryContactAccountsRequest
} from '@rallycry/api-suite-typescript/dist/apis'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useCompetitionTeamContactAccounts = (
  options?: EntityOptions<ViewCompetitionEntryContactAccountsRequest>
) => {
  const { user } = useUserAccount()
  const teamId = useParsedParam('teamId')
  const { ctrl } = useController(CompetitionEntryControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionTeamContactAccounts',
    expand: 'content{_links}',
    ...options,
    paused: !user,
    request: teamId ? { id: teamId } : undefined,
    read: req =>
      ctrl({
        expectedStatuses: [403, 404],
        fallbackResponse: { content: [] }
      }).viewCompetitionEntryContactAccounts(req)
  })

  return { ...entity, contacts: entity.flat }
}
