import Stack from '@mui/material/Stack'
import { RcTrans } from '@/components/atoms/RcTrans'
import { ErrorCode } from '@/core/error-code'
import { useCompetitionIssues } from '@/entity/competition/useCompetitionIssues'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'

export const useCompetitionAlertsTeamDropped = () => {
  const { issues } = useCompetitionIssues()

  const teamDropped = issues?.find(it => it.code === ErrorCode.DroppedEntry)

  const items: TutorialItem[] = []

  // team is dropped, show alert
  if (teamDropped) {
    items.push({
      type: TutorialType.ALERT,
      severity: 'error',
      id: 'competition-team-dropped',
      children: (
        <Stack direction='column' spacing={3}>
          <RcTrans i18nKey='competition:issues.dropped' />
        </Stack>
      )
    })
  }

  return items
}
