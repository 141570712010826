import Stack from '@mui/material/Stack'
import { ComponentDynamicHeroBox } from '../../../../../models/types'
import { ActionLink } from './ActionLink'
import { PageHeroBox } from '@/components/organisms/page/PageHeroBox'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { ImageSize, useCMSFile } from '@/core/hooks/useCMSFile'
import { useIsMainLayout } from '@/core/hooks/useLayoutQueries'
import { useIsMobile } from '@/core/hooks/useMediaQueries'

export const HeroBox = ({
  title,
  titleImage,
  subtitle,
  backgroundImage,
  backgroundVideo,
  actions,
  colorMode,
  multi
}: ComponentDynamicHeroBox & { multi?: boolean }) => {
  const { getImagePath } = useCMSFile()
  const backgroundPath = getImagePath(
    backgroundVideo?.data || backgroundImage?.data || undefined,
    ImageSize.Large
  )
  const titlePath = getImagePath(titleImage?.data || undefined, ImageSize.Large)

  const { featHeroFade } = useFeatures()
  const isMainLayout = useIsMainLayout()
  const isMobile = useIsMobile()

  const darkMode = !colorMode || ['dynamic', 'dark'].includes(colorMode)

  return (
    <PageHeroBox
      darkMode={darkMode}
      title={title}
      titlePath={titlePath}
      fadeOut={!!featHeroFade}
      filePath={backgroundPath}
      subtitle={subtitle}
      video={!!backgroundVideo?.data}
      multi={multi}
    >
      <Stack direction='row' spacing={3} justifyContent={'center'}>
        {actions?.map(it => (
          <ActionLink
            key={it?.path}
            action={it!}
            ButtonProps={{
              size: isMainLayout || isMobile ? 'medium' : 'large'
            }}
          />
        ))}
      </Stack>
    </PageHeroBox>
  )
}
