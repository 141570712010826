import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { BracketAssignmentResource } from '@rallycry/api-suite-typescript/dist/models/BracketAssignmentResource'
import { MatchGameResultCommand } from '@rallycry/api-suite-typescript/dist/models/MatchGameResultCommand'
import Button from '@mui/material/Button'
import { isUndefined } from 'lodash-es'
import { useEffect } from 'react'
import { WinCondition } from '@rallycry/api-suite-typescript'
import { MatchResultsMachine } from './match-results-machine'
import { MatchResultFlowActions } from './MatchResultFlowActions'
import { MatchCard } from '@/components/pages/Competition/components/match/card/MatchCard'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'

const isWinner = (
  it: MatchGameResultCommand,
  assignment?: BracketAssignmentResource
) => it.winner === assignment?.id

const getScore = (
  scoreSubmissions?: MatchGameResultCommand[],
  assignment?: BracketAssignmentResource
) => scoreSubmissions?.filter(it => it.winner === assignment?.id).length

export const ReportScores = ({ machine }: { machine: MatchResultsMachine }) => {
  const [state, send] = machine
  const { match, bracketAssignment1, bracketAssignment2 } = state.context

  useEffect(() => {
    const scoreReport = document.querySelector('.score-row:last-child')
    scoreReport?.scrollIntoView({ behavior: 'smooth' })
  }, [state.context.scoreSubmissions])

  const scoreCheck = (
    score: MatchGameResultCommand & { isLocked?: boolean },
    assignment?: BracketAssignmentResource
  ) => (
    <RcButton
      disabled={score.isLocked}
      onClick={() => {
        send('ADD_SCORE', {
          ordinal: score.ordinal,
          winner: assignment?.id
        })
      }}
      variant={isWinner(score, assignment) ? 'contained' : 'outlined'}
      fullWidth
    >
      {isWinner(score, assignment) ? (
        <RcIcon icon={['fal', 'check']} size='xs' mr={2} />
      ) : null}{' '}
      <RcTrans i18nKey='competition:match.win' />
    </RcButton>
  )

  const scoreNumeric = (
    score: MatchGameResultCommand & { isLocked?: boolean },
    assignment?: BracketAssignmentResource
  ) => {
    const value =
      (assignment === bracketAssignment1
        ? score.scores?.[0]
        : score.scores?.[1]) || 0
    return (
      <TextField
        disabled={score.isLocked}
        type='number'
        variant='outlined'
        value={value}
        onChange={event => {
          send('ADD_SCORE', {
            ordinal: score.ordinal,
            score1:
              assignment === bracketAssignment1
                ? Number(event.target.value)
                : undefined,
            score2:
              assignment === bracketAssignment2
                ? Number(event.target.value)
                : undefined
          })
        }}
      />
    )
  }

  const collection = state.context.scoreSubmissions

  // display score for the currently active collection
  const score1 = getScore(collection, bracketAssignment1)
  const score2 = getScore(collection, bracketAssignment2)

  return (
    <Stack direction='column' spacing={3}>
      {state.context.bracketSettings?.numericGameScore ? (
        <Typography variant='body2' align='center'>
          Enter your <strong>in-game</strong> scores to <strong>begin </strong>
          finalizing the match
        </Typography>
      ) : null}

      {collection?.map(it => (
        <Card key={it.ordinal} className='score-row'>
          <CardContent>
            <Grid
              key={it.ordinal}
              container
              direction='row'
              alignItems='flex-end'
            >
              <Grid item xs>
                {state.context.bracketSettings?.numericGameScore
                  ? scoreNumeric(it, bracketAssignment1)
                  : scoreCheck(it, bracketAssignment1)}
              </Grid>
              <Grid item xs={6}>
                <Stack direction='column' spacing={1} alignItems='center'>
                  <Typography variant='subtitle1' align='center'>
                    <RcTrans
                      i18nKey='competition:match.game-number'
                      tOptions={{
                        ordinal: it.ordinal
                      }}
                    />
                  </Typography>
                  {state.context.bracketSettings?.winCondition ===
                  WinCondition.BESTOF ? (
                    <Button
                      variant={
                        isUndefined(it.winner) ? 'contained' : 'outlined'
                      }
                      disabled={it.isLocked}
                      onClick={() =>
                        send('ADD_SCORE', {
                          ordinal: it.ordinal,
                          winner: undefined,
                          score1: undefined,
                          score2: undefined
                        })
                      }
                    >
                      <RcTrans i18nKey='competition:match.TIE' />
                    </Button>
                  ) : null}
                </Stack>
              </Grid>
              <Grid item xs style={{ textAlign: 'right' }}>
                {state.context.bracketSettings?.numericGameScore
                  ? scoreNumeric(it, bracketAssignment2)
                  : scoreCheck(it, bracketAssignment2)}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      <MatchResultFlowActions
        machine={machine}
        title={<RcTrans i18nKey='competition:report-scores' />}
        topContent={
          <Box sx={{ p: 7 }}>
            <MatchCard
              match={match}
              competition={state.context.competition}
              participant={state.context.participant}
              score1={score1}
              score2={score2}
            />
          </Box>
        }
      />
    </Stack>
  )
}
