import {
  CompetitionEntryAssistantControllerApi,
  CompetitionParticipantControllerApi
} from '@rallycry/api-suite-typescript/dist/apis'
import {
  EntryMemberControllerApi,
  QueryEntryMembersRequest
} from '@rallycry/api-suite-typescript/dist/apis/EntryMemberControllerApi'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models'
import { uniqBy } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useCompetitionTeam } from './useCompetitionTeam'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { ExpansionType, expandById } from '@/core/expand'

export const useCompetitionTeamMembers = (
  options?: EntityOptions<QueryEntryMembersRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const parsedId = useParsedParam('teamId')

  const { read: competitionMeta } = useCompetitionMeta({
    idOrKey: competitionId
  })

  const { ctrl: assistantCtrl } = useController(
    CompetitionEntryAssistantControllerApi
  )
  const { ctrl: participantCtrl } = useController(
    CompetitionParticipantControllerApi
  )

  const { ctrl } = useController(EntryMemberControllerApi)

  const teamId = Number(options?.idOrKey) || parsedId
  const entity = useQueryEntity({
    key: 'useCompetitionTeamMembers',
    expand: 'content{representing,participant,_links}',
    paused: !teamId,
    ...options,
    request: {
      entryId: teamId,
      ...options?.request
    },
    read: (req, { root }) => ctrl({ metas: { root } }).queryEntryMembers(req)
  })

  const {
    team,
    update,
    read: { mutate: mutateEntry }
  } = useCompetitionTeam({ idOrKey: teamId })

  const roster = useMemo(() => {
    const members = entity.flat.map(m => {
      const user = expandById(
        m?.participant?.id!,
        m?._expanded,
        ExpansionType.User
      ) as UserResource
      return { ...user, rank: TeamRank.Member }
    })

    const leader = expandById(
      team?.leader?.id!,
      team?._expanded,
      ExpansionType.User
    ) as UserResource & { rank: TeamRank }
    if (leader && members.find(it => it.id === leader.id)) {
      leader.rank = TeamRank.HeadCaptain
    } else if (leader) {
      leader.rank = TeamRank.HeadCoach
    }

    const assistants = (team?.assistants || []).map(a => {
      const user = expandById(
        a.id!,
        team?._expanded,
        ExpansionType.User
      ) as UserResource
      return {
        ...user,
        rank: members.find(it => it.id === user?.id)
          ? TeamRank.AssistantCaptain
          : TeamRank.AssistantCoach
      }
    })

    return uniqBy([leader, ...assistants, ...members], 'id').filter(it => !!it)
  }, [entity.flat, team])

  const addMember = useCallback(
    async (participantId: number) => {
      await participantCtrl().saveCompetitionParticipant({
        competitionId,
        participantId,
        CompetitionParticipantSaveCommand: {
          entry: teamId,
          skipValidateRequiredNetworks: true
        }
      })
      await Promise.allSettled([
        competitionMeta.mutate(),
        mutateEntry(),
        entity.query.mutate()
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [competitionId, teamId, mutateEntry, entity.query, competitionMeta]
  )

  const removeMember = useCallback(
    async (participantId: number) => {
      await participantCtrl().removeCompetitionParticipant({
        competitionId,
        participantId
      })
      await Promise.allSettled([
        competitionMeta.mutate(),
        mutateEntry(),
        entity.query.mutate()
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [competitionId, teamId, mutateEntry, entity.query, competitionMeta]
  )

  const promoteMember = useCallback(
    async (participantId?: number) => {
      if (!participantId) return
      await assistantCtrl().saveCompetitionEntryAssistant({
        entryId: teamId,
        assistantId: participantId
      })

      await Promise.allSettled([
        competitionMeta.mutate(),
        mutateEntry(),
        entity.query.mutate()
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId, mutateEntry, mutateEntry, entity.query, competitionMeta]
  )

  const removeAssistant = useCallback(
    async (participantId?: number) => {
      if (!participantId) return
      await assistantCtrl().removeCompetitionEntryAssistant({
        entryId: teamId,
        assistantId: participantId
      })
      await mutateEntry()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId, mutateEntry]
  )

  const transferLeadership = useCallback(
    async (participantId: number) => {
      const currentLeader = team?.leader?.id!
      await assistantCtrl().saveCompetitionEntryAssistant({
        entryId: teamId,
        assistantId: currentLeader
      })
      await update(teamId, { leader: participantId })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [team, teamId, update]
  )

  return {
    ...entity,
    roster,
    addMember,
    removeMember,
    promoteMember,
    removeAssistant,
    transferLeadership
  }
}

// keep as number based enum for ez sorting
export enum TeamRank {
  HeadCoach = 3,
  HeadCaptain = 0,
  AssistantCoach = 4,
  AssistantCaptain = 1,
  Member = 2,
  Invited = 5
}
