import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { IdMeHandleForm } from './IdMeHandleForm'
import { ManualHandleForm } from './ManualHandleForm'
import { OauthHandleForm } from './OauthHandleForm'

export interface HandleFormProps {
  network: any
  audience?: string
  onSuccess?: () => Promise<any>
  onError?: () => Promise<any>
  display?: React.ReactNode
  canRefresh?: boolean
  fullWidth?: boolean
}
export const HandleForm: React.FC<HandleFormProps> = (
  props: HandleFormProps
) => {
  switch (props.network) {
    case NetworkKind.IDME:
      return <IdMeHandleForm {...props} />
    case NetworkKind.DISCORD:
    case NetworkKind.BATTLENET:
    case NetworkKind.RIOTVALORANTAMERICAS:
    case NetworkKind.TWITCH:
      return <OauthHandleForm {...props} />
    default:
      return <ManualHandleForm {...props} />
  }
}
