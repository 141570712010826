import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

export const BracketChallongeSeed = ({
  hideSeed,
  handleToggle
}: {
  hideSeed: boolean
  handleToggle: () => void
}) => {
  const { t } = useRcTranslation()
  const icon: IconProp = hideSeed
    ? ['fal', 'binary-circle-check']
    : ['fal', 'binary-slash']

  return (
    <RcIconButton
      size='small'
      onClick={handleToggle}
      icon={icon}
      TooltipProps={{
        title: hideSeed
          ? t('competition:bracket.seed-show')
          : t('competition:bracket.seed-hide')
      }}
    />
  )
}
