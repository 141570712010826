import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { HandleFormProps } from './HandleForm'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useTimeoutWindow } from '@/core/hooks/useTimeoutWindow'
import { TranslationNamespace } from '@/core/translation'
import { useConfig } from '@/entity/site/useConfig'
import { useMyContactAccounts } from '@/entity/user/useMyContactAccounts'
import { useUserMeta } from '@/entity/user/useUserMeta'
import { useOrganization } from '@/entity/organization/useOrganization'

export const OauthHandleForm: React.FC<HandleFormProps> = ({
  network,
  audience,
  onSuccess,
  onError,
  display,
  canRefresh,
  fullWidth
}) => {
  const [refreshTooltip, setRefreshTooltip] = useState<string>()

  const {
    read: { mutate }
  } = useUserMeta()
  const { handles, remove: removeHandle, refresh } = useMyContactAccounts()
  const config = useConfig()
  const { getNetworkKindDisclaimer, getNetworkString, getNetworkIcon } =
    useNetworkKinds()
  const { t } = useRcTranslation([TranslationNamespace.Profile])
  const { isOrgModerator } = useOrganization()
  const localizedNetworkString = getNetworkString(network)
  const icon = getNetworkIcon(network)
  const disclaimer = getNetworkKindDisclaimer(network)
  const handle = handles.find(handle => handle.network === network)
  const append = audience ? `-${audience}` : ''

  const showLink = useTimeoutWindow(
    config.api + `/oauth2/authorization/${network.toLowerCase()}${append}`,
    () => mutate(),
    onError
  )

  useEffect(() => {
    if (handles?.find(handle => handle.network === network)) {
      onSuccess?.()
    }
  }, [handles, onSuccess, network])

  const handleRefresh = async (id: number) => {
    try {
      setRefreshTooltip(`...`)
      await refresh(id)
      setRefreshTooltip(`${t('profile:provider-refreshed')}!`)
      setTimeout(() => setRefreshTooltip(''), 4000)
    } catch (e) {
      console.log(e)
      setRefreshTooltip(t('shared.error'))
    }
  }

  const deleteTooltip = t('profile:unlink-provider', {
    network: localizedNetworkString
  })

  const isLocked = !handle?._links?._delete

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      {fullWidth ? null : (
        <Tooltip title={localizedNetworkString}>
          <div>
            <RcIcon icon={icon} mr={2} />
          </div>
        </Tooltip>
      )}
      {handle ? (
        <>
          <Typography variant='body0' sx={{ flexGrow: 1 }}>
            {handle?.handle}
          </Typography>
          {!handle.verified ? (
            <RcButton
              color='warning'
              variant='contained'
              fullWidth={fullWidth}
              size={fullWidth ? 'medium' : 'small'}
              onClick={showLink}
            >
              <RcTrans i18nKey='profile:verify-oauth-account' />
            </RcButton>
          ) : null}
          {canRefresh ? (
            <RcIconButton
              size='small'
              icon={['fal', 'refresh']}
              TooltipProps={{
                title: refreshTooltip || (
                  <RcTrans i18nKey='profile:refresh-provider' />
                )
              }}
              onClick={() => handleRefresh(handle?.id!)}
            />
          ) : null}
          {isOrgModerator ? (
            <ConfirmingButton
              disabled={isLocked}
              size='small'
              sx={theme => ({ color: theme.palette.error.main })}
              icon={['fal', 'trash']}
              onClick={() => removeHandle(handle?.id!)}
              message={
                <RcTrans
                  i18nKey='profile:unlink-provider-confirmation'
                  tOptions={{
                    provider: localizedNetworkString
                  }}
                />
              }
              buttonName={<RcTrans i18nKey='shared.remove' />}
            >
              {deleteTooltip}
            </ConfirmingButton>
          ) : null}
        </>
      ) : (
        <>
          <RcButton
            color='secondary'
            variant='contained'
            fullWidth={fullWidth}
            size={fullWidth ? 'medium' : 'small'}
            onClick={showLink}
          >
            {display ? (
              display
            ) : (
              <>
                <RcTrans
                  i18nKey='profile:link-oauth-account'
                  tOptions={{ network: localizedNetworkString }}
                />
              </>
            )}
          </RcButton>
          {disclaimer ? (
            <Box mt={2}>
              <Typography variant='body2' align='center'>
                {disclaimer}
              </Typography>
            </Box>
          ) : null}
        </>
      )}
    </Stack>
  )
}
