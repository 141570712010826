import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { useEffect, useRef } from 'react'
import { last } from 'lodash-es'
import { trimTrailingSlash } from '@/core/utils'

const useTwitterApi = () => {
  return { twttr: (window as any)?.twttr }
}

export const TwitterTimelineEmbed = ({ url }: { url?: string }) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const { twttr } = useTwitterApi()

  useEffect(() => {
    if (!twttr || !ref.current || !url) return
    ref.current.innerHTML = ''
    twttr.widgets?.createTimeline?.(
      {
        sourceType: 'profile',
        screenName: last(trimTrailingSlash(url).split('/'))
      },
      ref.current,
      {
        theme: theme.palette.mode,
        tweetLimit: 3,
        chrome: 'transparent noscrollbar noheader nofooter noborders'
      }
    )
  }, [twttr, theme.palette.mode, ref, url])

  return (
    <>
      <div ref={ref} />
    </>
  )
}

export const TwitterTweetEmbed = ({ tweetId }: { tweetId?: string }) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const { twttr } = useTwitterApi()

  useEffect(() => {
    if (!twttr || !ref.current) return
    ref.current.innerHTML = ''
    twttr.widgets?.createTweet?.(tweetId, ref.current, {
      theme: theme.palette.mode,
      conversation: 'none'
    })
  }, [twttr, theme.palette.mode, ref, tweetId])

  return (
    <Box
      sx={{
        '& .twitter-tweet': {
          height: 400,
          overflowY: 'auto',
          overflowX: 'hidden'
        }
      }}
    >
      <div ref={ref} />
    </Box>
  )
}
