import { AlertProps } from '@mui/material/Alert'
import { some } from 'lodash-es'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import {
  CompetitionRoute,
  CompetitionSettingsRoute,
  RootRoute
} from '@/core/route-keys'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionTickets } from '@/entity/competition/useCompetitionTickets'
import { useNavigation } from '@/core/hooks/useNavigation'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'

export const useCompetitionAlertsMemberTickets = () => {
  const { navTo } = useNavigation()
  const { competition, isCompetitionModerator } = useCompetition()
  const { tickets } = useCompetitionTickets({
    paused: !isCompetitionModerator
  })

  const issues: TutorialItem[] = []
  if (some(tickets)) {
    issues.push({
      type: TutorialType.DEPRECATED,
      severity: 'warning',
      children: <RcTrans i18nKey='competition:issues.tickets-pending' />,
      action: (
        <RcButton size='medium' variant='contained'>
          <RcTrans i18nKey='shared.review' />
        </RcButton>
      ),
      onClick: () =>
        navTo({
          root: RootRoute.Competition,
          rootId: competition?.id,
          subRoute: CompetitionRoute.Settings,
          subId: CompetitionSettingsRoute.TicketQueue,
          query: 'scrollTo=true'
        })
    })
  }

  return issues
}
