import Box from '@mui/material/Box'

import { RulesForm } from './RulesForm'
import { MarkdownEdit } from '@/components/molecules/input/MarkdownEdit'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { useCompetitionRules } from '@/entity/competition/useCompetitionRules'

export interface RuleProps {
  rule: 'general' | 'critical' | 'match' | 'registration' | 'format' | 'prizing'
  title: React.ReactNode
  hint?: React.ReactNode
}

export const Rule = ({ rule, title, hint }: RuleProps) => {
  const { rules } = useCompetitionRules()

  const canEdit = !!rules?._links?.update

  if (!rules) return null
  if (!canEdit && !rules[rule]) return null

  return (
    <Box>
      <SectionHeader title={title} tooltips={[{ content: hint }]} />

      <MarkdownEdit
        canEdit={canEdit}
        FormComponent={RulesForm}
        formProps={{ rule: rule }}
        source={rules[rule]}
      />
    </Box>
  )
}
