import { CompetitionControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionControllerApi'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useCompetitionMeta } from './useCompetitionMeta'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useCompetitionInvolvement = (options?: EntityOptions) => {
  const parsedId = useParsedParam('competitionId')
  const { read: meta } = useCompetitionMeta(options)
  const { ctrl } = useController(CompetitionControllerApi)

  const competitionId = Number(options?.idOrKey) || parsedId
  const entity = useReadEntity({
    key: 'useCompetitionInvolvement',
    expand: '_viewInvolvement{participantOf{entry}}',
    ...options,
    idOrKey: competitionId,
    metas: [meta],
    read: (req, { root, entrant }) =>
      ctrl({
        metas: { root, entrant }
      }).readCompetition(req)
  })

  const competition = entity.read.data

  return {
    ...entity,
    involvement: competition?._viewInvolvement
  }
}
