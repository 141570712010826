import {
  RcTable,
  RcTableBody,
  RcTableContainer
} from '@/components/molecules/text/RcTable'
import { expand, ExpansionType } from '@/core/expand'
import { useQuery } from '@/core/hooks/useQuery'
import { scrollIntoViewWithOffset } from '@/core/utils'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetitionTeamInvitations } from '@/entity/competition-team/useCompetitionTeamInvitations'
import {
  TeamRank,
  useCompetitionTeamMembers
} from '@/entity/competition-team/useCompetitionTeamMembers'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { UserResource } from '@rallycry/api-suite-typescript'
import { orderBy, uniqBy } from 'lodash-es'
import { useEffect, useRef } from 'react'
import { TeamRosterRow } from './TeamRosterRow'
import { TeamRosterSearch } from './TeamRosterSearch'

export const TeamRoster = ({ condensed }: { condensed?: boolean }) => {
  const ref = useRef<HTMLElement>()

  const { isSoloCompetition, isCompetitionModerator } = useCompetition()
  const { isRosterLocked } = useCompetitionEvents()

  const q = useQuery()
  const isScrollTo = !!q?.get('scrollTo')
  useEffect(() => {
    if (!isScrollTo) return
    scrollIntoViewWithOffset(ref.current)
  }, [ref, isScrollTo])

  const { team } = useCompetitionTeam()
  const teamMembers = useCompetitionTeamMembers()
  const { invitations } = useCompetitionTeamInvitations()

  const canEdit = !!team?._links?.update
  const canPromote = !!team?._links?.update

  const invited = invitations.map(
    it =>
      expand<UserResource & { rank: TeamRank }>(
        it,
        it._expanded,
        ExpansionType.Recipient
      )!
  )

  const sorted = uniqBy(
    orderBy([...teamMembers.roster, ...invited], it => it.rank, 'asc'),
    it => it.id
  )

  return (
    <Stack direction='column' spacing={3} pb={3}>
      <Box ref={ref} />

      {!isSoloCompetition &&
      (isCompetitionModerator || (canEdit && !isRosterLocked)) ? (
        <TeamRosterSearch condensed={condensed} existing={sorted} />
      ) : null}

      <RcTableContainer>
        <RcTable>
          <RcTableBody>
            {sorted.map(it => (
              <TeamRosterRow
                key={it.id}
                user={it}
                isSoloCompetition={isSoloCompetition}
                canPromote={canPromote}
                canEdit={canEdit}
                {...teamMembers}
              />
            ))}
          </RcTableBody>
        </RcTable>
      </RcTableContainer>
    </Stack>
  )
}
