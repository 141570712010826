import { FormResponseControllerApi } from '@rallycry/api-suite-typescript/dist/apis/FormResponseControllerApi'
import { CuratedFormResource } from '@rallycry/api-suite-typescript/dist/models/CuratedFormResource'
import { FormResponseResource } from '@rallycry/api-suite-typescript/dist/models/FormResponseResource'
import { useState } from 'react'
import { FormResponseMultiPage } from './FormResponseMultiPage'
import { SectionDisplay } from './FormResponseSinglePage'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { expander, ExpansionType } from '@/core/expand'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useController } from '@/core/hooks/useSWRApi'

export const FormResponseAdhoc = ({
  activation,
  onComplete,
  formId
}: {
  activation: (handleOpen: () => void) => React.ReactNode
  onComplete?: () => void
  formId: number
}) => {
  const [response, setResponse] = useState<FormResponseResource>()
  const { create } = useFormResponse()
  const { ctrl } = useController(FormResponseControllerApi)

  const handleOpen = async (openModal: () => any) => {
    const existing = await ctrl().viewMyFormResponses({ size: 10000 })

    const res =
      existing.content?.find(it => it.form?.id === formId) ||
      (await create({ formId, FormResponseCreateCommand: {} }))

    setResponse(res)

    openModal()
  }

  return (
    <ModalTrigger
      activation={openModal => activation(() => handleOpen(openModal))}
      onClose={onComplete}
    >
      {({ handleClose }) =>
        response?.dateCompleted ? (
          <>
            <FormResponseSingleModal responseId={response?.id!} />
          </>
        ) : (
          <FormResponseMultiPage
            responseId={response?.id!}
            onComplete={handleClose}
          />
        )
      }
    </ModalTrigger>
  )
}

const FormResponseSingleModal = ({ responseId }: { responseId: number }) => {
  const { response } = useFormResponse({
    idOrKey: responseId
  })

  const form = expander<CuratedFormResource>(
    response,
    ExpansionType.CuratedForm
  )

  return (
    <>
      <SectionDisplay responseId={response?.id!} isModal />
      <ModalConfiguration
        title={form?.name}
        subtitle={form?.disambiguatingDescription}
      />
    </>
  )
}
