import { ModalTrigger } from '../modal/ModalTrigger'
import { ManageEmailForm } from './ManageEmailForm'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { TranslationNamespace } from '@/core/translation'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

export const ManageEmailTrigger = ({ userId }: { userId: number }) => {
  const { t } = useRcTranslation([TranslationNamespace.Administration])

  return (
    <ModalTrigger
      activation={handleOpen => (
        <RcIconButton
          icon={['fal', 'at']}
          onClick={handleOpen}
          TooltipProps={{ title: t('administration:manage-emails-label') }}
        />
      )}
    >
      {() => <ManageEmailForm userId={userId} />}
    </ModalTrigger>
  )
}
