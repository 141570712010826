import Typography from '@mui/material/Typography'
import { GameResource } from '@rallycry/api-suite-typescript/dist/models/GameResource'

import {
  AvatarText,
  AvatarTextProps
} from '@/components/molecules/text/AvatarText'

interface GameAvatarProps extends AvatarTextProps {
  game?: GameResource
  onlyAvatar?: boolean
}

/*
 * Avatar for the Game Resource
 */
export const GameAvatar: React.FC<GameAvatarProps> = ({
  game,
  onlyAvatar,
  ...props
}) => {
  const avatarImage = game?.icon || game?.image || undefined

  return (
    <AvatarText src={avatarImage} variant='rounded' {...props}>
      {onlyAvatar ? null : (
        <Typography variant='body0'>{game?.name}</Typography>
      )}
    </AvatarText>
  )
}
