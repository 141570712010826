import { ReadSelfCommunitiesRequest } from '@rallycry/api-suite-typescript/dist/apis/UserControllerApi'
import { CommunityResource } from '@rallycry/api-suite-typescript/dist/models/CommunityResource'
import { useMemo } from 'react'
import { useOrganization } from '../../entity/organization/useOrganization'
import { EntityOptions } from '../useEntity'
import { useUserSelfCommunities } from './useUserSelfCommunities'
import { expandById, expander, ExpansionType } from '@/core/expand'

export const useUserSelfOrgCommunities = (
  options?: EntityOptions<ReadSelfCommunitiesRequest>
) => {
  const { organization } = useOrganization(options)
  const { communities } = useUserSelfCommunities(options)

  const orgCommunities = useMemo(
    () =>
      communities
        .map(it => ({
          ...expander<CommunityResource>(it, 'community'),
          _expanded: it._expanded
        }))
        .filter(
          it =>
            it?.organization?.id === organization?.id ||
            it?.additionalOrganizations?.find(
              that =>
                expandById(
                  that.id,
                  it._expanded,
                  ExpansionType.CommunityAdditionalOrganizations
                )?.organization?.id === organization?.id
            )
        ),
    [communities, organization?.id]
  )

  return { orgCommunities }
}
