import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { useParams } from 'next/navigation'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcDateTimePicker } from '@/components/molecules/input/RcDateTimePicker'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { AuditItem } from '@/components/pages/Administration/components/AuditItem'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { TranslationNamespace } from '@/core/translation'
import { useAuditDocument } from '@/entity/audit/useAuditDocument'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'

export const TeamAuditLog = ({ condensed }: { condensed?: boolean }) => {
  const { team } = useCompetitionTeam()
  const [actorName, setActorName] = useState<string | undefined>(undefined)
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [endDate, setEndDate] = useState<Date | undefined>()
  const [field, setField] = useState<string>('')
  const { t } = useRcTranslation([TranslationNamespace.Administration])

  const [debouncedActor] = useDebounce(actorName, 300)

  const { audits } = useAuditDocument({
    request: {
      actorName: debouncedActor ? debouncedActor : undefined,
      path: [`CompetitionEntry:${team?.id}`],
      dateGte: startDate,
      dateLt: endDate
    }
  })

  return (
    <>
      <Grid container display='flex' direction='row' spacing={2} p={2}>
        <Grid item xs={condensed ? 6 : 3}>
          <TextField
            placeholder={t('administration:audit.audit-log-search-usernames')}
            value={actorName}
            onChange={val => setActorName(f => val.target.value)}
            InputProps={{
              startAdornment: <RcIcon icon={['fal', 'search']} />,
              endAdornment: actorName ? (
                <RcIconButton
                  onClick={() => setActorName('')}
                  icon={['fal', 'times']}
                  IconProps={{ size: 'xs' }}
                  aria-label='Search'
                />
              ) : null
            }}
          />
        </Grid>
        <Grid item xs={condensed ? 6 : 3}>
          <TextField
            placeholder={t('administration:audit.audit-log-field')}
            value={field}
            onChange={val => setField(f => val.target.value)}
            InputProps={{
              startAdornment: <RcIcon icon={['fal', 'search']} />,
              endAdornment: field ? (
                <RcIconButton
                  onClick={() => setField('')}
                  icon={['fal', 'times']}
                  IconProps={{ size: 'xs' }}
                  aria-label='Search'
                />
              ) : null
            }}
          />
        </Grid>
        <Grid item xs={condensed ? 6 : 3}>
          <RcDateTimePicker
            placeholderText='Start Date'
            selected={startDate}
            onChange={date => setStartDate(date!)}
          />
        </Grid>

        <Grid item xs={condensed ? 6 : 3}>
          <RcDateTimePicker
            placeholderText='End Date'
            selected={endDate}
            onChange={date => setEndDate(date!)}
          />
        </Grid>
      </Grid>

      <Grid container display='flex' direction='row' spacing={1} mt={4}>
        {audits.map(audit => (
          <AuditItem key={audit.id} audit={audit} />
        ))}
      </Grid>
    </>
  )
}
