import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { TooltipProps } from '@mui/material'
import { RcIcon } from '@/components/atoms/RcIcon'

/**
 * Displays a label and content below and optional icon.
 */

interface LabeledContentProps {
  children?: ReactNode
  direction?: 'row' | 'column'
  icon?: IconProp
  label?: ReactNode
  rightAlign?: boolean
  spacing?: number
  tooltip?: string | ReactNode
  TooltipProps?: TooltipProps
}

export const LabeledContent = ({
  children,
  direction = 'row',
  icon,
  label,
  rightAlign,
  spacing,
  tooltip,
  TooltipProps
}: LabeledContentProps) => {
  const Content = (
    <Stack
      alignItems={rightAlign ? 'flex-end' : 'flex-start'}
      spacing={spacing ? spacing : 2}
      width='100%'
    >
      {label ? (
        <Typography variant='subtitle1' color='text.secondary'>
          {label}
        </Typography>
      ) : null}
      <Stack
        direction={direction}
        alignItems={direction === 'row' ? 'center' : 'flex-start'}
        width='100%'
      >
        {icon ? (
          <Box minWidth={25} color='text.secondary'>
            <RcIcon icon={icon} size='sm' />
          </Box>
        ) : null}
        <Box display='flex' width='100%'>
          {children}
        </Box>
      </Stack>
    </Stack>
  )

  return (
    <>
      {tooltip ? (
        <Tooltip
          placement='top-start'
          title={tooltip}
          arrow={false}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [-4, -8]
                }
              }
            ]
          }}
          {...TooltipProps}
        >
          {Content}
        </Tooltip>
      ) : (
        Content
      )}
    </>
  )
}
