import { BoxProps } from '@mui/material/Box'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { TEXT_PALETTE } from '@/style/palette'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useIsMobile, useIsTablet } from '@/core/hooks/useMediaQueries'
import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'

const useStyle = makeStyles(() => ({
  background: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 1
  },
  backgroundFade: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    '-webkit-mask-image':
      '-webkit-gradient(linear, left 50%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
    height: '100%',
    zIndex: 1
  },
  backgroundAnimation: {
    height: '100%'
  },
  foreground: {
    height: '100%',
    position: 'relative',
    zIndex: 3
  },
  videoSrc: {
    mixBlendMode: 'overlay'
  }
}))

export interface PageHeroBoxProps extends Omit<BoxProps, 'title'> {
  subtitle?: React.ReactNode
  darkMode?: boolean
  fadeOut?: boolean
  video?: boolean
  title?: React.ReactNode
  titlePath?: string
  filePath?: string
  multi?: boolean
}

export const PageHeroBox: React.FC<PageHeroBoxProps> = ({
  children,
  subtitle,
  darkMode = true,
  fadeOut,
  video,
  title,
  titlePath,
  filePath,
  multi,
  ...BoxProps
}) => {
  const [videoLoaded, setVideoLoaded] = useState(false)
  const classes = useStyle()
  const { featHeroOpacity, imgPageBanner } = useFeatures()
  const useOpacity = (featHeroOpacity as number) * 0.01
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const defaultBackgroundSrc = imgPageBanner as string
  const videoFallbackSrc = imgPageBanner as string

  const content = (
    <Box
      className={titlePath ? 'page-hero page-hero-title' : 'page-hero'}
      sx={{
        height: titlePath ? 600 : 300,
        position: 'relative',
        borderRadius: isTablet ? 0 : 1,
        overflow: 'hidden',
        color: darkMode
          ? TEXT_PALETTE.primary.dark
          : TEXT_PALETTE.primary.light,
        p: isMobile ? 4 : 6
      }}
      {...BoxProps}
    >
      <Container maxWidth='lg' sx={{ height: '100%' }}>
        <Stack
          position='relative'
          direction='column'
          zIndex={3}
          height='100%'
          alignItems='center'
          justifyContent={!title ? 'flex-end' : 'center'}
          spacing={isMobile ? 4 : 8}
        >
          {titlePath ? (
            <Box width={'100%'} height={200} position='relative'>
              <ImageWithFallback
                src={titlePath}
                alt={titlePath}
                blur={false}
                fill
                style={{ objectFit: 'contain' }}
              />
            </Box>
          ) : null}
          {title ? (
            <Box>
              <Typography align='center' variant='h1'>
                {title}
              </Typography>
            </Box>
          ) : null}
          {subtitle ? (
            <Box>
              <Typography
                align='center'
                color='text2'
                variant='body0'
                paragraph
              >
                {subtitle}
              </Typography>
            </Box>
          ) : null}
          {children ? <Box>{children}</Box> : null}
        </Stack>
      </Container>
      <Box
        className='page-item-background'
        sx={theme => ({
          backgroundColor: theme.palette.primary.main,
          '& video': {
            objectFit: 'cover',
            height: '100%',
            width: '100%',
            transition: 'opacity 0.2s',
            '&:-webkit-media-controls-overlay-play-button, :-internal-media-controls-cast-button':
              {
                display: 'none'
              }
          }
        })}
      >
        {video ? (
          <video
            className={fadeOut ? classes.backgroundFade : classes.background}
            preload='auto'
            onLoadedData={() => setVideoLoaded(true)}
            onLoad={() => setVideoLoaded(true)}
            autoPlay
            muted
            playsInline
            loop
            style={{ opacity: videoLoaded ? useOpacity : 0 }}
          >
            <source src={filePath} type='video/mp4' />
            <img src={videoFallbackSrc || ''} alt='' />
          </video>
        ) : null}
        <ImageWithFallback
          fill
          priority
          className={fadeOut ? classes.backgroundFade : classes.background}
          src={
            video
              ? videoFallbackSrc || ''
              : filePath
              ? filePath
              : defaultBackgroundSrc || ''
          }
          alt={'Header Image'}
          style={{
            objectFit: 'cover',
            visibility: videoLoaded ? 'hidden' : 'visible',
            opacity: useOpacity
          }}
        />
      </Box>
    </Box>
  )

  return multi ? content : <Box className='hero-boxes'>{content}</Box>
}
