import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import Color from 'color'
import { ComponentDynamicFaq } from '../../../../../models/types'
import { MarkdownDisplay } from '@/components/molecules/input/MarkdownDisplay'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { isDarkMode } from '@/core/utils'

export const FrequentlyAskedQuestions = (
  props: ComponentDynamicFaq & { isFlipped?: boolean }
) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <>
      <Typography variant='h2' textAlign='center' sx={{ mb: 10 }}>
        {props.title}
      </Typography>
      {props.content?.map(it => (
        <Accordion
          key={it?.id}
          expanded={expanded === it?.id}
          onChange={handleChange(it?.id!)}
          sx={theme => ({
            transition: theme.transitions.duration.standard,
            backgroundColor:
              expanded === it?.id
                ? Color(theme?.palette.background.paper)
                    .darken(isDarkMode(theme) ? 0.6 : 0.15)
                    .hex()
                : props.isFlipped
                ? theme.palette.background.default
                : theme.palette.background.paper,
            mt: 2,
            borderRadius: 1
          })}
        >
          <AccordionSummary
            expandIcon={<RcIconButton icon={['fal', 'chevron-down']} />}
            sx={{ padding: 7 }}
          >
            <Typography variant='h4'>{it?.title}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 7, marginTop: -10 }}>
            <MarkdownDisplay markdown={it?.description || ''} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}
