import {
  CommunityDocumentControllerApi,
  SearchCommunitiesRequest
} from '@rallycry/api-suite-typescript/dist/apis/CommunityDocumentControllerApi'
import { useOrganization } from '../../entity/organization/useOrganization'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useCommunityDocument = (
  options: EntityOptions<SearchCommunitiesRequest>
) => {
  const { organization } = useOrganization()
  const { ctrl } = useController(CommunityDocumentControllerApi)

  const entity = useQueryEntity({
    key: 'useCommunityDocument',
    paused: !options.request,
    persist: true,
    ...options,
    request: {
      organizationId: organization?.id,
      ...options.request,
      q: options?.request?.q || undefined
    },
    read: req => ctrl().searchCommunities(req)
  })

  return { ...entity, communities: entity.flat }
}
