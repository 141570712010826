'use client'

import Typography from '@mui/material/Typography'
import { PageItem } from '@/components/organisms/page/PageItem'
import { RcTrans } from '@/components/atoms/RcTrans'

export const NotFound = () => {
  return (
    <PageItem
      sx={theme => ({
        p: theme.spacing(8)
      })}
    >
      <Typography variant='h2' color='text2' align='center'>
        <RcTrans i18nKey='site.missing-page-title' />
      </Typography>
      <Typography variant='body0' color='text2' align='center'>
        <RcTrans i18nKey='site.missing-page-content' />
      </Typography>
    </PageItem>
  )
}
