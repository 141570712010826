import {
  SaveUserAchievedRequest,
  UnPinUserAchievedRequest,
  UserAchievedControllerApi
} from '@rallycry/api-suite-typescript/dist/apis/UserAchievedControllerApi'
import { UserAchievedSaveCommand } from '@rallycry/api-suite-typescript/dist/models/UserAchievedSaveCommand'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserAchievements = (options?: EntityOptions) => {
  const { ctrl } = useController(UserAchievedControllerApi)
  const entity = useQueryEntity({
    key: 'useUserAchievements',
    expand: 'content{achieved}',
    paused: !options?.request,
    ...options,
    read: req => ctrl().queryByUser(req)
  })

  const pinAchievement = async (req: UnPinUserAchievedRequest) => {
    await ctrl().pinUserAchieved(req)
    await entity.query.mutate()
  }
  const unPinAchievement = async (req: UnPinUserAchievedRequest) => {
    await ctrl().unPinUserAchieved(req)
    await entity.query.mutate()
  }

  const grantAchievement = async (req: SaveUserAchievedRequest) => {
    const res = await ctrl().saveUserAchieved(req)
    await entity.query.mutate()
    return res
  }

  const remove = async (id: number) => {
    await ctrl().removeUserAchieved({ id })
    await entity.query.mutate()
  }

  return {
    ...entity,
    achievements: entity.flat,
    grantAchievement,
    pinAchievement,
    unPinAchievement,
    remove
  }
}
