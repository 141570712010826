import { useTheme } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {
  ActionType,
  AuditDocument,
  UserResource
} from '@rallycry/api-suite-typescript'
import { calcElevationString } from '@/style/palette'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { usePage } from '@/components/providers/site/PageProvider'
import { useTime } from '@/core/hooks/useTime'

// t('administration:AUDIT-DOCUMENT-ACTION.CREATED')
// t('administration:AUDIT-DOCUMENT-ACTION.DELETE')
// t('administration:AUDIT-DOCUMENT-ACTION.UPDATE')

export const AuditItem = ({ audit }: { audit: AuditDocument }) => {
  const { t } = useRcTranslation()
  const theme = useTheme()
  const { open } = usePage()
  const { displayDateTime } = useTime()
  const date = displayDateTime(audit.date)

  function copyText(audit: AuditDocument): void {
    const actor = audit.actor
    const type0 = audit.path?.[0]?.type
    const path0 = audit.path?.[0]?.id
    const type1 = audit.path?.[1]?.type
    const path1 = audit.path?.[1]?.id

    let final = `${actor?.name} (${
      actor?.id || '?'
    }) ${audit.action?.toLocaleLowerCase()} a ${type0} (${path0})`
    if (type1) {
      final += ` in ${type1} (${path1})`
    }

    navigator.clipboard.writeText(final)

    open(null, <RcTrans i18nKey='shared.copied-to-clipboard' />, 3000)
  }

  return (
    <Grid item xs={12} key={audit.id!}>
      <Accordion>
        <AccordionSummary
          sx={{
            backgroundColor: theme.palette.primary.dark,
            backgroundImage: calcElevationString(theme.palette.mode, [8]),
            borderRadius: '8px 8px 0px 0px'
          }}
          expandIcon={<RcIcon icon={['fal', 'chevron-down']} />}
        >
          <Grid
            container
            spacing={1}
            direction='row'
            alignItems='center'
            justifyContent={'space-between'}
            width='100%'
            px={3}
          >
            <Grid item>
              <Typography color='text.primary' variant='body1'>
                {audit.path?.[1] ? (
                  <RcTrans
                    i18nKey='administration:audit.display-line'
                    tOptions={{
                      action: t(
                        `administration:AUDIT-DOCUMENT-ACTION.${audit.action}`
                      ),
                      type0: audit.path?.[0]?.type,
                      path0: audit.path?.[0]?.id || '?',
                      type1: audit.path?.[1]?.type,
                      path1: audit.path?.[1]?.id || '?'
                    }}
                  >
                    <UserAvatar
                      disableClick
                      user={audit.actor as UserResource}
                      rcVariant='text'
                    />
                    <strong />
                    <strong />
                    <strong />
                  </RcTrans>
                ) : (
                  <RcTrans
                    i18nKey='administration:audit.display-line-single'
                    tOptions={{
                      action: t(
                        `administration:AUDIT-DOCUMENT-ACTION.${audit.action}`
                      ),
                      type0: audit.path?.[0]?.type,
                      path0: audit.path?.[0]?.id || '?'
                    }}
                  >
                    <UserAvatar
                      disableClick
                      user={audit.actor as UserResource}
                      rcVariant='text'
                    />
                    <strong />
                  </RcTrans>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Stack direction='row' spacing={2} alignItems='center'>
                <RcIconButton
                  icon={['fal', 'copy']}
                  size='small'
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return copyText(audit)
                  }}
                />
                <Tooltip title={audit.date?.toDateString()}>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    pr={2}
                    sx={{ flexGrow: 1 }}
                  >
                    {date}
                  </Typography>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: theme.palette.primary.dark,
            backgroundImage: calcElevationString(theme.palette.mode, [5]),
            borderRadius: '0px 0px 8px 8px'
          }}
        >
          {audit.action === ActionType.CREATED ||
          audit.action === ActionType.DELETE
            ? audit.state?.map((it, idx) => (
                <Stack
                  display='flex'
                  direction='row'
                  spacing={2}
                  key={idx * Math.random()}
                >
                  <Typography color={theme.palette.secondary.light} noWrap>
                    {idx} -
                  </Typography>
                  <Typography color='text.primary' variant='body2'>
                    <strong>{it.field}</strong> :{' '}
                    <strong>{it.to || it.from}</strong>
                  </Typography>
                </Stack>
              ))
            : audit.action === ActionType.UPDATE
            ? audit.state?.map((it, idx) => (
                <Stack
                  display='flex'
                  direction='row'
                  spacing={2}
                  key={idx * Math.random()}
                >
                  <Typography color={theme.palette.secondary.light} noWrap>
                    {idx} -
                  </Typography>
                  <Typography color='text.primary' variant='body2' noWrap>
                    <strong>{it.field}</strong> from{' '}
                    {it.from ? it.from : 'empty'} to {it.to}
                  </Typography>
                </Stack>
              ))
            : null}
          {/* /* TODO: handle INVOKE / READ ? */}
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
